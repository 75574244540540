import PageHeader from "../components/common/PageHeader"
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { Modal } from "react-bootstrap";
import { useEffect, useState, useCallback } from "react";
import ApiService from "../api/ApiService";

const GaudiaPage = () => {
    const [acharya1, setAcharya1] = useState(false)
    const [acharya2, setAcharya2] = useState(false)
    const [acharya3, setAcharya3] = useState(false)
    const [acharya4, setAcharya4] = useState(false)
    const [acharya5, setAcharya5] = useState(false)
    const [goswami1, setGoswami1] = useState(false)
    const [goswami2, setGoswami2] = useState(false)
    const [goswami3, setGoswami3] = useState(false)
    const [goswami4, setGoswami4] = useState(false)
    const [goswami5, setGoswami5] = useState(false)
    const [goswami6, setGoswami6] = useState(false)

    const [meta, setMeta] = useState(null)
    const [top, setTop] = useState(0);

    const fetchMeta = async (pagename) => {
        try {
            let res = await ApiService.fetchMetaData(pagename)
            setMeta(res)
        } catch (error) {
            console.log(error)
        }
    }
    const festRef = useCallback((node) => {
        if (node) {
            setTop(node.offsetTop - 45);
        }
    }, []);

    useEffect(() => {
        fetchMeta('gaudiya-vaishnavism')
    }, [])


    return (
        <>
            <MetaData title="Gaudiya Vaishnavism - ISKCON of Bhiwandi" meta={meta} />
            <PageHeader title="GAUDIYA VAISHNAVISM" imgSrc="/images/page-header/18.webp" />
            <section className="gaudia-heading2" ref={festRef}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">
                                    Gaudiya Vaishanav Goswamis
                                </h2>
                                <p className="b-line">Gaudiya Vaishnavism is a sect of Hinduism based on the Vedas and especially drawing it’s theological basis from the Bhagavad Gita and the Srimad Bhagavatam. The movement was inspired by Lord Chaitanya Mahaprabhu and the defining element of this sampradaya is that Krishna is the Supreme Lord and in turn the focus on Bhakti, or devotion, to Krishna. Bhakti that is greater than Mukti (liberation), Bhakti that is sweet, almost mellifluous and all-encompassing.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-lg-5 mt-4">
                            <figure className="mb-0 vaishnavism-banner">
                                <img className="w-100" src="/images/gaudiya-vaishnavism/goswamis.jpg" alt="" />

                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="goswamis-sec">
                <div className="container">
                    <div className="goswamis-card-wrap">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="goswami-card">
                                    <div className="book-top">
                                        <figure> {/* onClick={() => setGoswami1(true)} */}
                                            <img src="/images/gaudiya-vaishnavism/Rupa_Goswami.webp" alt="" />
                                            {/* <button className="custom-btn-cls box-hover">Read More</button> */}
                                        </figure>
                                        <h5>Sri Rupa Goswami</h5>
                                        <p>
                                            Sri Rupa Goswami wrote practical yet profound transcendental books. He established Sri-Sri Radha-Govindaji, the beloved Deities of the entire Gaudiya sampradaya. Through extensive Vedic research Sri Rupa Goswami proved that Sri Chaitanya's teachings present the highest philosophy and the perfection of religion.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6  mb-5">
                                <div className="goswami-card">
                                    <div className="book-top">
                                        <figure > {/* onClick={() => setGoswami2(true)} */}
                                            <img src="/images/gaudiya-vaishnavism/Prabhupada-Srila-Raghunath-Bhata-Goswami.webp" alt="" />
                                            {/* <button className="custom-btn-cls box-hover">Read More</button> */}
                                        </figure>
                                        <h5>Sri Raghunatha Goswami</h5>
                                        <p>
                                            By the order of Sri Chaitanya Mahaprabhu Raghunatha Bhatta Goswami remained a lifelong brahmachari. His service was constant chanting of Hare Krishna and reading Srimad Bhagavatam. In Jagannatha Puri, Raghunatha satisifed Lord Gauranga by his exceptional cooking and kirtana performances, which won him the title, kirtana acharya.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="goswami-card">
                                    <div className="book-top">
                                        <figure > {/*onClick={() => setGoswami3(true)} */}
                                            <img
                                                src="/images/gaudiya-vaishnavism/Srila-Santana-Goswami.webp"
                                                alt=""
                                            />
                                            {/* <button className="custom-btn-cls box-hover">Read More</button> */}
                                        </figure>
                                        <h5>Sri Sanatana Goswami</h5>
                                        <p>
                                            Sri Sanatana Goswami appeared in 1488, five years before Sri Rupa Goswami, in a Sarasvata brahmana family in Bengal. Sanatana and his two brothers, Rupa and Anupama, were always absorbed in bhava bhakti from early childhood. Remembering Vrindavana, they named the forests in which they played after Vra.ja's twelve forests.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="goswami-card">
                                    <div className="book-top">
                                        <figure > {/* onClick={() => setGoswami4(true)} */}
                                            <img
                                                src="/images/gaudiya-vaishnavism/Raghunatha-Dasa-Goswami.webp"
                                                alt=""
                                            />
                                            {/* <button className="custom-btn-cls box-hover">Read More</button> */}
                                        </figure>
                                        <h5>Sri Raghunatha Dasa Goswami</h5>
                                        <p>
                                            Raghunath Das (1495-1586) was the first of the six Goswamis to meet Chaitanya Mahaprabhu. Raghunath Das was instructed in his boyhood by Balaram Acharya, a great scholar and an advanced Devotee of the Lord. He introduced Raghunath Das to Haridas Thakur, the great Namacharya Devotee of Chaitanya Mahaprabhu, who imparted a deep devotional spirit to Raghunath Das.                            </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="goswami-card">
                                    <div className="book-top">
                                        <figure > {/* onClick={() => setGoswami5(true)} */}
                                            <img
                                                src="/images/gaudiya-vaishnavism/Srila-Jiva-Goswami.webp"
                                                alt=""
                                            />
                                            {/* <button className="custom-btn-cls box-hover">Read More</button> */}
                                        </figure>
                                        <h5>Sri Jiva Goswami </h5>
                                        <p>
                                            Sri Jiva Goswami was the youngest but most prolific writer among the Goswamis. He wrote an astounding half million Sanskrit verses (about 25 books). His books prove that Sri Chaitanya's philosophy gives the essence of Vedic wisdom and the perfection of religion. Gopala Champu, Sat Sandarbhas, and Hari Nama-vyakarana are three of his most famous works. The Sandarbhas firmly establish the transcendental truths of Srimad Bhagavatam.                                   </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="goswami-card">
                                    <div className="book-top">
                                        <figure > {/* onClick={() => setGoswami6(true)} */}
                                            <img
                                                src="/images/gaudiya-vaishnavism/Gopala-Bhatta-Goswami.webp"
                                                alt=""
                                            />
                                            {/* <button className="custom-btn-cls box-hover">Read More</button> */}
                                        </figure>
                                        <h5>Sri Gopal Bhatta Goswami  </h5>
                                        <p>
                                            Sri Gopal Bhatt Goswami, one of the six Goswamis of Vrindavan, was born in 1503 on the 13th day of the dark moon in the month of Pausha. Srila Gopal Bhatt Goswami lived for 75 years and left this world to enter into the eternal pastimes of the Lord in 1578.
                                            Sri Gopal Bhatt Goswami was the son of Vyenkatta Bhatt, a brahmana resident of Sri Rangam in South India, who was initiated in the Sri Sampradaya tradition, in the disciplic succession of Sri Ramanujacharya.
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="gaudia-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">
                                    Gaudiya Vaishanav Acharyas
                                </h2>
                                <p>The Acharyas appear to this material world on the instructions of the Supreme lord in order to attract the conditioned soul. Achar means – Behavior, Practices, Lifestyle and Acharya means – One who teaches through his behavior, his practice, his lifestyle. An Acharya practices what he preaches and preaches what he practices there is no difference between either. Acharya means one who knows the scriptural injunctions and follows them practically in life, and teaches them to his disciples, although he acts a servant of the lord non the less, he must always be considered as authorized representative of the lord. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-lg-5 mt-4">
                            <figure className="mb-0 vaishnavism-banner">
                                <img className="w-100" src="/images/gaudiya-vaishnavism/acharyas.jpg" alt="" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="acharyas-sec">
                <div className="container">
                    <div className="acharyas-card-wrap">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="acharya-card">
                                    <div className="book-top">
                                        <figure>
                                            <img src="/images/gaudiya-vaishnavism/Image00001.webp" alt="" />
                                            {/* <button className="custom-btn-cls box-hover">Read More</button> */}
                                        </figure>
                                        <h5>A.C. Bhaktivedanta Swami</h5>
                                        <p>
                                            His Divine Grace A.C. Bhaktivedanta Swami Prabhupada (1896-1977), the founder-acharya of the International Society for Krishna Consciousness (ISKCON), was the foremost proponent and teacher of Krishna consciousness—devotional service to the Supreme Person, Krishna—of the late twentieth century. Srila Prabhupada also constantly traveled the world, initiating thousands of disciples and managing a global spiritual movement which continues to grow today.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="acharya-card">
                                    <div className="book-top">
                                        <figure>
                                            <img src="/images/gaudiya-vaishnavism/Image00003.webp" alt="" />
                                            {/* <button className='custom-btn-cls box-hover' data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                Read More </button> */}
                                        </figure>
                                        <h5>Srila Bhaktisiddhanta Sarasvati Thakur</h5>
                                        <p>
                                            Srila Bhaktisiddhanta Saravati Thakur is one the most prominent acharyas of the Brahma Madhva Gaudiya Vaishnav Sampradaya, he appeared on 6th February 1874 in Jagannath Puri as a 3rd son of Srila Sacidananda Bhakti Vinoda Tahkur (Gaudiya Acharya) & Smt. Bhagavati Devi. He is also the spiritual master of ISKCON founder acharya A. C. Bhaktivedanta Swami Prabhupad. Srila Bhaktisiddhanta Sarawati Thakur in his early years of life was know as Bimala Prasad Dutt.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="acharya-card">
                                    <div className="book-top">
                                        <figure>
                                            <img src="/images/gaudiya-vaishnavism/Image00002.webp" alt="" />
                                            {/* <button className="custom-btn-cls box-hover">Read More</button> */}
                                        </figure>
                                        <h5>Srila Gaura Kishora Dasa Babaji Maharaj</h5>
                                        <p>
                                            Srilal Gaura Kishora Dasa Babaji maharaj one of the most prominent Acharya of Brahma Madhva Gaudiya Sampradaya. He appeared in this world in the year  1838 and on 17th November in a well to do family in the village of Bagjana at the banks of Padma river in the district of Faridpur a part of modern-day Bangladesh. Vamsi das was the name given to him. He is also the Diksha Guru of Srila Bhaktisiddhanta Sarasvati Thakur.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="acharya-card">
                                    <div className="book-top">
                                        <figure>
                                            <img src="/images/gaudiya-vaishnavism/Image00004.webp" alt="" />
                                            {/* <button className="custom-btn-cls box-hover">Read More</button> */}
                                        </figure>
                                        <h5>Srila Bhaktivinoda Thakur</h5>
                                        <p>
                                            Srila Bhaktivinoda Thakur is a prominent Acharya in the Brahma Madhva Gaudiya Sampradaya. On 2nd September 1838 in Biranagara (Ulagrama) in the Nadia district of Bengal as the 7th son of Raja Krishnananda Datta a great devotee of Lord Nityananda. He also known as the 7th Goswami. Bhaktivinoda Thakura discovered and excavated the birthplace of Lord Chaitanya. Along with his devoted wife, Bhagavati Devi, he raised ten children, including the illustrious Bhaktisiddhanta Sarasvati Thakura, who would become a great spiritual leader in his own time and the spiritual master of ISKCON's founder-Acharya, His Divine Grace A.C. Bhaktivedanta Swami Prabhupad.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="acharya-card">
                                    <div className="book-top">
                                        <figure>
                                            <img src="/images/gaudiya-vaishnavism/Image00005.webp" alt="" />
                                            {/* <button className="custom-btn-cls box-hover">Read More</button> */}
                                        </figure>
                                        <h5>Srila Jagannatah Dasa Babaji Maharaj</h5>
                                        <p>
                                            Srila Jagannatha Dasa Babaji Maharaj a prominent Acharya in the Brahma Gaudiya Vaishvan Sampradaya. He appeared in the year 1750 A.D in a remote village of Tangail now known as Bangladesh. He lived approximately 144 years. He took Babaji initiation form Madhusudana Dasa Babaji Maharaj. He did bhajana for many years in at Surya Kunda in Vrindavana. His practice was generally to live 6-months in Vraja dham and 6-months in SriNabadwip Dham.                                        </p>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="gaudia-banner">
            </section> */}



            <Modal centered show={acharya1} onHide={() => setAcharya1(false)}>
                <div className="modal-wrap-read">
                    <div className="pop-up-form read">
                        <div className="pop-up-top">
                            <div className="heading">

                                {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                <h2 className="head-2">Srila Bhaktisiddhanta Sarasvati Thakur</h2>
                            </div>
                            <button className="btn close-btn" onClick={() => setAcharya1(false)}>
                                <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                            </button>
                            <figure className="hare-krishna-logo-fig">
                                <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                            </figure>
                        </div>
                        <p>
                            Srila Bhaktisiddhanta Saravati Thakur is one the most prominent acharyas of the Brahma Madhva Gaudiya Vaishnav Sampradaya, he appeared on 6th February 1874 in Jagannath Puri as a 3rd son of Srila Sacidananda Bhakti Vinoda Tahkur (Gaudiya Acharya) & Smt. Bhagavati Devi. He is also the spiritual master of ISKCON founder acharya A. C. Bhaktivedanta Swami Prabhupad. Srila Bhaktisiddhanta Sarawati Thakur in his early years of life was know as Bimala Prasad Dutt.

                        </p>

                    </div>
                </div>
            </Modal>
            <Modal centered show={acharya2} onHide={() => setAcharya2(false)}>
                <div className="modal-wrap-read">
                    <div className="pop-up-form read">
                        <div className="pop-up-top">
                            <div className="heading">

                                {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                <h2 className="head-2">Srila Gaura Kishora Dasa Babaji Maharaj</h2>
                            </div>
                            <button className="btn close-btn" onClick={() => setAcharya2(false)}>
                                <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                            </button>
                            <figure className="hare-krishna-logo-fig">
                                <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                            </figure>
                        </div>
                        <p>
                            Srilal Gaura Kishora Dasa Babaji maharaj one of the most prominent Acharya of Brahma Madhva Gaudiya Sampradaya. He appeared in this world in the year 1838 and on 17th November in a well to do family in the village of Bagjana at the banks of Padma river in the district of Faridpur a part of modern-day Bangladesh. Vamsi das was the name given to him. He is also the Diksha Guru of Srila Bhaktisiddhanta Sarasvati Thakur.

                        </p>

                    </div>
                </div>
            </Modal>
            <Modal centered show={acharya3} onHide={() => setAcharya3(false)}>
                <div className="modal-wrap-read">
                    <div className="pop-up-form read">
                        <div className="pop-up-top">
                            <div className="heading">

                                {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                <h2 className="head-2">Srila Bhaktivinoda Thakur</h2>
                            </div>
                            <button className="btn close-btn" onClick={() => setAcharya3(false)}>
                                <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                            </button>
                            <figure className="hare-krishna-logo-fig">
                                <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                            </figure>
                        </div>
                        <p>
                            Srila Bhaktivinoda Thakur is a prominent Acharya in the Brahma Madhva Gaudiya Sampradaya. On 2nd September 1838 in Biranagara (Ulagrama) in the Nadia district of Bengal as the 7th son of Raja Krishnananda Datta a great devotee of Lord Nityananda. He also known as the 7th Goswami. Bhaktivinoda Thakura discovered and excavated the birthplace of Lord Chaitanya. Along with his devoted wife, Bhagavati Devi, he raised ten children, including the illustrious Bhaktisiddhanta Sarasvati Thakura, who would become a great spiritual leader in his own time and the spiritual master of ISKCON's founder-Acharya, His Divine Grace A.C. Bhaktivedanta Swami Prabhupad.

                        </p>

                    </div>
                </div>
            </Modal>
            <Modal centered show={acharya4} onHide={() => setAcharya4(false)}>
                <div className="modal-wrap-read">
                    <div className="pop-up-form read">
                        <div className="pop-up-top">
                            <div className="heading">

                                {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                <h2 className="head-2">Srila Jagannatah Dasa Babaji Maharaj</h2>
                            </div>
                            <button className="btn close-btn" onClick={() => setAcharya4(false)}>
                                <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                            </button>
                            <figure className="hare-krishna-logo-fig">
                                <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                            </figure>
                        </div>
                        <p>
                            Srila Jagannatha Dasa Babaji Maharaj a prominent Acharya in the Brahma Gaudiya Vaishvan Sampradaya. He appeared in the year 1750 A.D in a remote village of Tangail now known as Bangladesh. He lived approximately 144 years. He took Babaji initiation form Madhusudana Dasa Babaji Maharaj. He did bhajana for many years in at Surya Kunda in Vrindavana. His practice was generally to live 6-months in Vraja dham and 6-months in Sri Nabadwip Dham.

                        </p>

                    </div>
                </div>
            </Modal>
            <Modal centered show={acharya5} onHide={() => setAcharya5(false)}>
                <div className="modal-wrap-read">
                    <div className="pop-up-form read">
                        <div className="pop-up-top">
                            <div className="heading">

                                {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                <h2 className="head-2">A.C. Bhaktivedanta Swami</h2>
                            </div>
                            <button className="btn close-btn" onClick={() => setAcharya5(false)}>
                                <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                            </button>
                            <figure className="hare-krishna-logo-fig">
                                <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                            </figure>
                        </div>
                        <p>
                            His Divine Grace A.C. Bhaktivedanta Swami Prabhupada (1896-1977), the founder-acharya of the International Society for Krishna Consciousness (ISKCON), was the foremost proponent and teacher of Krishna consciousness—devotional service to the Supreme Person, Krishna—of the late twentieth century. Srila Prabhupada also constantly traveled the world, initiating thousands of disciples and managing a global spiritual movement which continues to grow today.

                        </p>

                    </div>
                </div>
            </Modal>
            <Modal centered show={goswami1} onHide={() => setGoswami1(false)}>
                <div className="modal-wrap-read">
                    <div className="pop-up-form read">
                        <div className="pop-up-top">
                            <div className="heading">

                                {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                <h2 className="head-2">Sri Rupa Goswami</h2>
                            </div>
                            <button className="btn close-btn" onClick={() => setGoswami1(false)}>
                                <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                            </button>
                            <figure className="hare-krishna-logo-fig">
                                <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                            </figure>
                        </div>
                        <p>
                            Sri Rupa Goswami wrote practical yet profound transcendental books. He established Sri-Sri Radha-Govindaji, the beloved Deities of the entire Gaudiya sampradaya. Through extensive Vedic research Sri Rupa Goswami proved that Sri Chaitanya's teachings present the highest philosophy and the perfection of religion.

                        </p>

                    </div>
                </div>
            </Modal>
            <Modal centered show={goswami2} onHide={() => setGoswami2(false)}>
                <div className="modal-wrap-read">
                    <div className="pop-up-form read">
                        <div className="pop-up-top">
                            <div className="heading">

                                {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                <h2 className="head-2">Sri Raghunatha Goswami</h2>
                            </div>
                            <button className="btn close-btn" onClick={() => setGoswami2(false)}>
                                <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                            </button>
                            <figure className="hare-krishna-logo-fig">
                                <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                            </figure>
                        </div>
                        <p>
                            By the order of Sri Chaitanya Mahaprabhu Raghunatha Bhatta Goswami remained a lifelong brahmachari. His service was constant chanting of Hare Krishna and reading Srimad Bhagavatam. In Jagannatha Puri, Raghunatha satisifed Lord Gauranga by his exceptional cooking and kirtana performances, which won him the title, kirtana acharya.

                        </p>

                    </div>
                </div>
            </Modal>
            <Modal centered show={goswami3} onHide={() => setGoswami3(false)}>
                <div className="modal-wrap-read">
                    <div className="pop-up-form read">
                        <div className="pop-up-top">
                            <div className="heading">

                                {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                <h2 className="head-2">Sri Sanatana Goswami</h2>
                            </div>
                            <button className="btn close-btn" onClick={() => setGoswami3(false)}>
                                <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                            </button>
                            <figure className="hare-krishna-logo-fig">
                                <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                            </figure>
                        </div>
                        <p>
                            Sri Sanatana Goswami appeared in 1488, five years before Sri Rupa Goswami, in a Sarasvata brahmana family in Bengal. Sanatana and his two brothers, Rupa and Anupama, were always absorbed in bhava bhakti from early childhood. Remembering Vrindavana, they named the forests in which they played after Vra.ja's twelve forests.

                        </p>

                    </div>
                </div>
            </Modal>
            <Modal centered show={goswami4} onHide={() => setGoswami4(false)}>
                <div className="modal-wrap-read">
                    <div className="pop-up-form read">
                        <div className="pop-up-top">
                            <div className="heading">

                                {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                <h2 className="head-2">Sri Raghunatha Dasa Goswami</h2>
                            </div>
                            <button className="btn close-btn" onClick={() => setGoswami4(false)}>
                                <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                            </button>
                            <figure className="hare-krishna-logo-fig">
                                <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                            </figure>
                        </div>
                        <p>
                            Raghunath Das (1495-1586) was the first of the six Goswamis to meet Chaitanya Mahaprabhu. Raghunath Das was instructed in his boyhood by Balaram Acharya, a great scholar and an advanced Devotee of the Lord. He introduced Raghunath Das to Haridas Thakur, the great Namacharya Devotee of Chaitanya Mahaprabhu, who imparted a deep devotional spirit to Raghunath Das.

                        </p>

                    </div>
                </div>
            </Modal>
            <Modal centered show={goswami5} onHide={() => setGoswami5(false)}>
                <div className="modal-wrap-read">
                    <div className="pop-up-form read">
                        <div className="pop-up-top">
                            <div className="heading">

                                {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                <h2 className="head-2">Sri Jiva Goswami</h2>
                            </div>
                            <button className="btn close-btn" onClick={() => setGoswami5(false)}>
                                <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                            </button>
                            <figure className="hare-krishna-logo-fig">
                                <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                            </figure>
                        </div>
                        <p>
                            Sri Jiva Goswami was the youngest but most prolific writer among the Goswamis. He wrote an astounding half million Sanskrit verses (about 25 books). His books prove that Sri Chaitanya's philosophy gives the essence of Vedic wisdom and the perfection of religion. Gopala Champu, Sat Sandarbhas, and Hari Nama-vyakarana are three of his most famous works. The Sandarbhas firmly establish the transcendental truths of Srimad Bhagavatam.

                        </p>

                    </div>
                </div>
            </Modal>
            <Modal centered show={goswami6} onHide={() => setGoswami6(false)}>
                <div className="modal-wrap-read">
                    <div className="pop-up-form read">
                        <div className="pop-up-top">
                            <div className="heading">

                                {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                <h2 className="head-2">Sri Gopal Bhatta Goswami</h2>
                            </div>
                            <button className="btn close-btn" onClick={() => setGoswami6(false)}>
                                <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                            </button>
                            <figure className="hare-krishna-logo-fig">
                                <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                            </figure>
                        </div>
                        <p>
                            Sri Gopal Bhatt Goswami, one of the six Goswamis of Vrindavan, was born in 1503 on the 13th day of the dark moon in the month of Pausha. Srila Gopal Bhatt Goswami lived for 75 years and left this world to enter into the eternal pastimes of the Lord in 1578. Sri Gopal Bhatt Goswami was the son of Vyenkatta Bhatt, a brahmana resident of Sri Rangam in South India, who was initiated in the Sri Sampradaya tradition, in the disciplic succession of Sri Ramanujacharya.

                        </p>

                    </div>
                </div>
            </Modal>

            <GoToTop top={top} />
        </>
    );
}

export default GaudiaPage;