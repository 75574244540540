import React, { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../components/common/PageHeader";
import AppContext from "../context/AppContext";
import MetaData from "../helpers/MetaData";
import { HiOutlineDownload } from "react-icons/hi";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import { useState } from "react";
import moment from "moment";
import fileDownload from "js-file-download";
import GoToTop from "../helpers/GoToTop";
import axios from "axios";
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import Tooltip from '@mui/material/Tooltip';

const AllTransactions = () => {
  const { setUser, setToken, user } = useContext(AppContext);
  const navigate = useNavigate();
  const [donations, setDonations] = useState(null);
  const [donationsnitya, setDonationsNitya] = useState(null);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [lifeMembershipDetails, setLifeMembershipDetails] = useState(null)
  const [purchases, setPurchases] = useState(null)
  const [ics_data, setICSdata] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogViewOpen, setIsDialogViewOpen] = useState(false);

  const [showFYModal, setShowFYModal] = useState(false)
  const [download_id, setDownload_id] = useState([])
  const [sub_subid, setSubid] = useState(null)

  const [scheme_list, setICSscheme] = useState([])
  const [scheme_ld, setSubIcsid] = useState(null)

  const [scheme_start, setSubStart] = useState(null)
  const [scheme_end, setSubIcsEnd] = useState(null)
  const [scheme_period, setSubIcsWeekly] = useState(null)
  const [scheme_status, setSubIcsStatus] = useState(null)


  const startIndex = (page - 1) * 12;
  const endIndex = Math.min(startIndex + 12, lastPage);
  const totalPages = Math.ceil(lastPage / 12);
  //console.log(totalPages)

  const [loading, setLoading] = useState(false);


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const logOut = () => {
    localStorage.removeItem("token");
    setUser(null);
    setToken(null);
    navigate("/");
  };

  const closeDialog = () => {

    setIsDialogOpen(false);
  };

  const closeDialogView = () => {

    setIsDialogViewOpen(false);
  };

  const fetchUserDonation = async () => {
    try {
      const res = await ApiService.fetchUserDonation(page);
      // console.log(res.donations);
      //  setLastPage(res.totalPages);
      setDonations(res.donations);
    } catch (err) {
      console.log(err);
      notification("error", err.response.data.message);
    }
  };


  const fetchUserDonationNitya = async () => {
    try {
      const res = await ApiService.fetchUserDonationNitya(page);
      //console.log(res.donations);
      // setLastPage(res.totalPages);
      setDonationsNitya(res.donations);
    } catch (err) {
      console.log(err);
      notification("error", err.response.data.message);
    }
  };

  const handleFileDownload = async (id) => {
    try {

      const responseData = ics_data.filter(dat => dat.rno === id);
      // console.log(responseData);

      const receipt = responseData[0].rno;

      let AllData = {
        donor_id: '',
        checkEightyG: responseData[0].taxBenefit,
        receiptDate: responseData[0].receiptDate,
        rbno: responseData[0].rbno,
        rno: responseData[0].rno,
        amount: responseData[0].amount,
        mode: responseData[0].mode,
        bankName: responseData[0].bankName,
        bankBranch: responseData[0].bankBranch,
        instrumentNo: responseData[0].instrument_no,
        instrumentDate: responseData[0].instrument_date,
        donorName: responseData[0].name,
        donorAddress: responseData[0].address.addr,
        donorCity: responseData[0].address.city,
        donorState: responseData[0].address.state,
        donorCountry: responseData[0].address.country,
        donorPin: responseData[0].address.pincode,
        donorContact: responseData[0].phone,
        donorEmail: responseData[0].email,
        donorPAN: responseData[0].pan
      }

      setDownload_id(responseData[0].rno)

      const ress = await ApiService.downloadNon80g(AllData)
      if (ress.message === 'Non 80G Generated successfully!') {
        //handleFileDownloadd(id);
        setTimeout(async () => {
          // Update the counter
          const res = await ApiService.download80g(`${receipt}.pdf`)
          fileDownload(res, `${'Reciept-' + responseData[0].rno}.pdf`)
        }, 2000);


      }



      // console.log(id)
      //const res = await ApiService.download80g(`${id}.pdf`);
      //const res = await ApiService.download80g(`${id}.pdf`);
      // console.log(res)
      // fileDownload(res, "80G-certificate.pdf");
    } catch (error) {
      console.log(error);

      notification(
        "error",
        "Unable to download certificate, try again after some time!"
      );
    }
  };


  //console.log(donations[0].pan)

  // donations.map((relative, i) => (
  //   ics_data.filter(dat => dat.rno === relative.receiptNo).map((relativ, i) => (
  //     console.log(relativ._id)
  // ))
  // ))


  const handleFileDownloadd = async (id) => {
    try {

      const responseData = ics_data.filter(dat => dat.rno === id);
      // console.log(responseData[0].name);

      const receipt = responseData[0].rno;

      const ress = await ApiService.download80g(`${receipt}.pdf`)
      fileDownload(ress, `${'Reciept-' + receipt}.pdf`)
      setShowFYModal(false)
    } catch (error) {

      setTimeout(() => {
        setShowFYModal(true)
      }, 3500); // 20 seconds in milliseconds


      console.log(error);
      notification(
        "success",
        "Validating Details and Generating 80G"
      );
    }
  };






  const handleImgDownload = async (id) => {
    try {
      const res = await ApiService.downloadImg(`${id}.jpg`);
      fileDownload(res, "Annadaan-certificate.jpg");
    } catch (err) {
      console.log(err);
      notification(
        "error",
        "Unable to download certificate, try again after some time!"
      );
    }
  };

  const fetchLifeMembership = async () => {
    try {

      const lifeMembership = await ApiService.fetchLifeMembership()

      setLifeMembershipDetails(lifeMembership)

    } catch (err) {
      console.log(err)
    }
  }

  const fetchUserPurchases = async (req, res) => {
    try {

      const { purchases } = await ApiService.fetchUserPurchases();
      setPurchases(purchases)

    } catch (err) {
      console.log(err)
    }
  }

  // const fetchFailedCampaignDetails = async () => {
  //   try {

  //     const res = await ApiService.fetchFailedCampaignDetails(id)
  //     // console.log(res)
  //     setCampaignDetails(res)

  //   }catch(err) {
  //     console.log(err)
  //   }
  // }

  // useEffect(() => {
  //     fetchFailedCampaignDetails()

  // },[])

  useEffect(() => {
    fetchUserDonation();
    // fetchUserDonationNitya();
    fetchLifeMembership();
    fetchUserPurchases()
  }, [page, user]);

  const user_mob = localStorage.getItem('ics_number');

  const fetchFromIcs = async () => {

    const response = await ApiService.getIcsDonation(user_mob)

    //console.log(response.data);
    setLastPage(response.data.length);

    setICSdata(response.data);
  }

  const icss_id = localStorage.getItem('ics_id');

  const fetchFromIcsScheme = async () => {

    const response = await ApiService.getIcsScheme(icss_id)
   // console.log(response)
    // response.rows.map((item, index) => (

    //     console.log(item.cell[5])

    // ))


    setICSscheme([...response.rows].reverse());
  }

  //  console.log(ics_data);

  const handleNityaSeva = async (data) => {
    //console.log(data.cancelReason)
    const response = await ApiService.nityaSevaCancel({
      cancelReason: data.cancelReason,
      sub_id: sub_subid,
      sub_ics_id: scheme_ld,
    })
    //console.log(response.status)
    if (response.status === 'cancelled') {
      fetchFromIcsScheme();
      notification('success', 'your subscriptions has been cancelled')
    }
    reset()
    setIsDialogOpen(false);
  }


  const handleModal = (subid) => {

    const valuesArray = subid.split(',');
    // console.log(valuesArray[0],valuesArray[1])
    setSubid(valuesArray[0]);
    setSubIcsid(valuesArray[1]);
    setIsDialogOpen(true);
  }


  const handleModalView = async (subid) => {

    const valuesArray = subid.split(',');
    // console.log(valuesArray[0],valuesArray[1])
    setSubid(valuesArray[0]);
    setSubIcsid(valuesArray[1]);
    setSubStart(valuesArray[2]);
    setSubIcsEnd(valuesArray[3]);
    setSubIcsWeekly(valuesArray[4]);
    setSubIcsStatus(valuesArray[5]);
    setIsDialogViewOpen(true);
  }

  const handleNityaSevaPause = async (id) => {
    const valuesArray = id.split(',');
    // var result = confirm("Are you sure?");
    // if (result) {
    // //Logic to delete the item

    const response = await ApiService.nityaSevaPause({
      //cancelReason: data.cancelReason,
      type: valuesArray[1],
      sub_id: valuesArray[0],
      sub_ics_id: scheme_ld,
    })
    // console.log(response.status)
    if (response.status === 'paused') {
      fetchFromIcsScheme();
      notification('success', 'your subscriptions has been paused')
    }
    if (response.status === 'active') {
      fetchFromIcsScheme();
      notification('success', 'your subscriptions has been resume')
    }
    reset()
    setIsDialogViewOpen(false);
    //  }

  }








  useEffect(() => {
    fetchFromIcs();
    fetchFromIcsScheme();
  }, []);



  const handleDownload = async (phone, instrument_no) => {
    //return alert(instrument_no)
    setLoading({[instrument_no]: true});

    const data = {
      phone: phone,
      instrument_no: instrument_no,
    }
    const response = await ApiService.downloadReciept(data);
    let urll = response.data[0]?.pdfLink;
    let fileNamee = `Reciept-` + response.data[0]?.rno;
    
    //console.log('---------------------------',response.data[0].pdfLink)
    fetch(urll)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileNamee;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading({['']: false});
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };


  return (
    <>
      <GoToTop />
      <MetaData title="Dashboard - ISKCON of Bhiwandi" />
      <section className="dashboard d-none">
        <div className="container">

          <div className="user-details-wrap">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="user-details">
                  <ul className="mb-0 list-unstyled">
                    <li className="text-capitalize">Hi {user?.name}</li>
                    {user?.email && (
                      <li>
                        <strong>Email:</strong> {user?.email}
                      </li>
                    )}
                    <li>
                      <strong>Phone:</strong> {user?.phone}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex justify-content-end">
                  <button
                    className="custom-btn-cls box-hover text-uppercase"
                    onClick={() => navigate("/update-profile")}
                  >
                    Edit Profile
                  </button>
                  <button
                    className="custom-btn-cls box-hover text-uppercase"
                    onClick={logOut}
                  >
                    Log out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>

      </div>

      <section className="dashboard-table">
        <div className="container">
          {/* <div className="top-head pb-3 pb-lg-0 heading">
            <h2 className="head-1">All Transactions</h2>
          </div> */}
          <div className="top-head pb-3 pb-lg-0 heading">
            <h2 className="head-1"></h2>
            <div className="d-flex justify-content-center flex-wrap">
            {/* <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/dms/service-requests")}
              >
                Service Requests
              </button>
              <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/dms/join-family")}
              >
                Join Family
              </button>
              <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/dms/all-transactions")}
              >
                All Transactions
              </button>
                <button
                  className="custom-btn-cls box-hover"
                  onClick={() => navigate("/dms/donation-form")}
                >
                  Donate more
              </button> */}
            <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/ics/Service-requests")}
              >
                Service Requests
              </button>
              <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/ics/join-family")}
              >
                Join Family
              </button>
              <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/ics/All-Transactions")}
              >
                All Transactions
              </button>
              <button
                className="custom-btn-cls box-hover"
                onClick={() => navigate("/ics/donation-form")}
              >
                Donate more
              </button>
              {/* <button
                    className="custom-btn-cls box-hover text-uppercase"
                    onClick={logOut}
                  >
                    Log out
                  </button> */}
            </div>
          </div>

          {
            ics_data?.length > 0 ?
              <div className="donation-table">
                <h5 className='table-title mt-lg-5 mt-3 mb-lg-3 mb-0'>Donations</h5>
                <div className="table-heading py-4 d-none d-lg-block">
                  <div className="row justify-content-evenly align-items-center">

                    <div className="col-lg-2">
                      <h5>Date</h5>
                    </div>
                    <div className="col-lg-2">
                      <h5>Status</h5>
                    </div>
                    <div className="col-lg-2">
                      <h5>Name</h5>
                    </div>
                    <div className="col-lg-2">
                      <h5>Amount</h5>
                    </div>

                    <div className="col-lg-2">
                      <h5>Download</h5>
                    </div>
                    <div className="col-lg-2">
                      <h5>Dispatch Details</h5>
                    </div>

                  </div>
                </div>
                <div className="table-content pt-2 pt-lg-0">
                  <div className="row">
                    {
                      ics_data?.filter(dat => dat.scheme !== 'Agarabatti Sales' && dat.scheme !== 'Ayodhya Ram Mandir Replica').slice(startIndex, endIndex).map((donation, i) => (

                        <div key={i} className="col-lg-12 col-md-6 ">
                          <div className="table-row py-4">
                            <div className="row align-items-center">

                              <div className="col-lg-2">
                                <div className="form-field">
                                  <h5 className="form-title">Date :</h5>
                                  <p id="donation-date">

                                    {moment(donation?.createdAt, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY")}
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-2">
                                <div className="form-field">
                                  <h5 className="form-title">Status :</h5>
                                  <p id="donation-status" className={`text-uppercase success`}>
                                    Success
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-2">
                                <div className="form-field">
                                  <h5 className="form-title">Name :</h5>
                                  <p id="donation-name">

                                    {donation?.name}
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-2">
                                <div className="form-field">
                                  <h5 className="form-title">Amount :</h5>
                                  <p id="donation-amount"> {donation?.totalAmount ? donation?.totalAmount.toLocaleString('en-IN', { maximumFractionDigits: 0, style: 'currency', currency: 'INR' }) : donation?.amount.toLocaleString('en-IN', { maximumFractionDigits: 0, style: 'currency', currency: 'INR' })}</p>
                                </div>
                              </div>

                              <div className="col-lg-2">
                                <div className="form-field flex-column d-inline-flex">

                                  <>
                                    <h5 className="form-title">Download :</h5>
                                    <div className="download-btns">
                                      {donation.taxBenefit ? (
                                        <>
                                          <button
                                            onClick={() => handleDownload(donation?.phone, donation?.instrument_no)}
                                            className=" ms-0 custom-btn-cls box-hover "
                                            disabled={loading[donation?.instrument_no] ? true : false}
                                          >
                                            80G  {loading[donation?.instrument_no] ? (
                                              <div className="d-flex align-items-center">
                                                <div
                                                  className="spinner-border text-light"
                                                  role="status"
                                                ></div>
                                              </div>
                                            ) : (
                                              <>
                                                <HiOutlineDownload />
                                              </>
                                            )}
                                            <span>Download Receipt</span>
                                          </button>

                                        </>
                                      ) : (
                                        <>

                                          <>
                                            {donation.taxBenefit ?
                                              <button
                                                onClick={() => handleDownload(donation?.phone, donation?.instrument_no)}
                                                className=" ms-0 custom-btn-cls box-hover "
                                                style={{ fontSize: '17px', marginTop: '12px' }}
                                                disabled={loading[donation?.instrument_no] ? true : false}
                                              >
                                                80G  {loading[donation?.instrument_no] ? (
                                                  <div className="d-flex align-items-center">
                                                    <div
                                                      className="spinner-border text-light"
                                                      role="status"
                                                    ></div>
                                                  </div>
                                                ) : (
                                                  <>
                                                    <HiOutlineDownload />
                                                  </>
                                                )}
                                                <span>Download Reciept</span>
                                              </button>
                                              :

                                              <button
                                                // onClick={() =>
                                                //   navigate(
                                                //     `/update-details?update-pan=${donations.find(dat => dat.eightyGNo == donation.rno)._id}&uid=${donation.icsid}&donation-type=donation`
                                                //   )
                                                // }
                                                onClick={() => {
                                                  const foundDonation = donations.find(dat => dat.eightyGNo == donation.rno);
                                                  const updatePanUrl = foundDonation && foundDonation._id !== undefined ?
                                                    `/update-details?update-pan=${foundDonation._id}&uid=${donation.icsid}&donation-type=donation` :
                                                    `/update-profile/${donation.icsid}`;

                                                  navigate(updatePanUrl);
                                                }}

                                                className=" ms-0 custom-btn-cls box-hover "
                                                style={{ fontSize: '17px', marginTop: '12px' }}
                                              >
                                                80G <HiOutlineDownload />
                                                <span>Download 80G certificate c</span>
                                              </button>
                                            }

                                            {donation.taxBenefit ?
                                              ''
                                              :
                                              <>
                                                <button
                                                  onClick={() => handleDownload(donation?.phone, donation?.instrument_no)}
                                                  className=" ms-0 custom-btn-cls box-hover "
                                                  style={{ fontSize: '17px', marginTop: '12px' }}
                                                  disabled={loading[donation?.instrument_no] ? true : false}
                                                >
                                                  Non  80G  {loading[donation?.instrument_no] ? (
                                                    <div className="d-flex align-items-center">
                                                      <div
                                                        className="spinner-border text-light"
                                                        role="status"
                                                      ></div>
                                                    </div>
                                                  ) : (
                                                    <>
                                                      <HiOutlineDownload />
                                                    </>
                                                  )}
                                                  <span>Download Reciept</span>
                                                </button>
                                              </>
                                            }
                                          </>

                                        </>
                                      )}
                                      {(donation?.donationType?.hasCertificate && donation?.donationType?.isFoodForLife) && (
                                        <button
                                          onClick={() => handleImgDownload(donation._id)}
                                          className="custom-btn-cls box-hover"
                                        >
                                          Annadaan <HiOutlineDownload />
                                          <span>Annadaan certificate</span>
                                        </button>
                                      )}
                                    </div>
                                  </>

                                  {
                                    donation?.paymentStatus === 'failed' &&
                                    <>
                                      {
                                        donation.model === 'campaign' &&
                                        <a href={`/campaign-donation/${donation.type.toLowerCase().split(' ').join('-')}/${donation.amount}?donationId=${donation._id}`} className='custom-btn-cls box-hover m-0 text-center'>
                                          Retry
                                        </a>
                                      }

                                      {
                                        donation.model === 'donationType' &&
                                        <a href={`/donation-form?donationId=${donation._id}`} className='custom-btn-cls box-hover m-0 text-center'>
                                          Retry
                                        </a>
                                      }
                                    </>
                                  }
                                  {
                                    donation?.paymentStatus === 'pending' && <span className="processing">Processing</span>
                                  }
                                </div>
                              </div>

                              <div className="col-lg-2">

                                <a href={donation?.dispatchDetails.split('##').find(part => part.startsWith('DS=')) ? donation?.dispatchDetails.split('##').find(part => part.startsWith('DS=')).substring(3) : ''} target="_blank" className=' ms-0 custom-btn-cls box-hover' style={{ fontSize: '14px' }}>
                                  <span > Track</span>
                                </a>

                              </div>

                              {/* {
                              donationsnitya?.filter(person => person.orderId === donation.instrument_no)[0]?.cancelReason ? '' :
                              donation?.instrument_no.startsWith('sub_') ?
                              
                              <div className="col-lg-1">
                                <Tooltip title="Cancellation of Nitya Seva Donation" placement="bottom-start">
                                 <button
                                    onClick={() =>  handleModal(donation?.instrument_no)}
                                    className="custom-btn-cls box-hover"
                                    style={{fontSize:'14px',marginTop:'12px'}}
                                        > 
                                    <span>Cancel</span> 
                                  </button>
                                  </Tooltip>
                              
                              </div>

                              : ''} */}

                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {/* <div className="table-content pt-2 pt-lg-0">
                <div className="row">
                  {donations?.filter(dat => dat.paymentStatus === 'pending' || dat.paymentStatus === 'failed').map((donation, i) => (
                    <div key={donation._id} className="col-lg-12 col-md-6 ">
                      <div className="table-row py-4">
                        <div className="row align-items-center">
                          <div className="col-lg-3">
                            <div className="form-field">
                              <h5 className="form-title">Date :</h5>
                              <p id="donation-date">
                                
                                {moment(donation?.createdAt).format("DD/MM/YYYY")}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Status :</h5>
                                  <p id="donation-status" className={`text-uppercase ${donation?.paymentStatus === 'success' ? 'success' : 'failed'}`}>
                                    {donation?.paymentStatus === 'failed' ? 'Unsuccessful' : donation?.paymentStatus}
                                  </p>                          
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Name :</h5>
                              <p id="donation-name">
                                
                                {donation?.name}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Amount :</h5>
                              <p id="donation-amount">₹ {donation?.totalAmount ? donation?.totalAmount : donation?.amount}</p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-field flex-column d-inline-flex">
                              {
                                donation?.paymentStatus === 'success' &&
                                <>
                                  <h5 className="form-title">Download :</h5>
                                  <div className="download-btns">
                                    {((donation.pan && donation.address && donation.pin && donation.eightyGNo && donation.donationVersion !== 'new') || (donation?.eightyGGenerated)) ? (
                                      <button
                                        onClick={() => handleFileDownload(donation._id)}
                                        className=" ms-0 custom-btn-cls box-hover "
                                      >
                                        80G <HiOutlineDownload />
                                        <span>Download 80G certificate</span>
                                      </button>
                                    ) : (
                                      <>
                                        {donation.model == "campaign" ? (
                                          <button
                                            onClick={() =>
                                              navigate(
                                                `/update-details?update-pan=${donation._id}&donation-type=campaign&redirect=/dashboard`
                                              )
                                            }
                                            className=" ms-0 custom-btn-cls box-hover "
                                          >
                                            80G <HiOutlineDownload />
                                            <span>Download 80G certificate</span>
                                          </button>
                                        ) : (
                                          <button
                                            onClick={() =>
                                              navigate(
                                                `/update-details?update-pan=${donation._id}&donation-type=donation&redirect=/dashboard`
                                              )
                                            }
                                            className=" ms-0 custom-btn-cls box-hover "
                                          >
                                            80G <HiOutlineDownload />
                                            <span>Download 80G certificate</span>
                                          </button>
                                        )}
                                      </>
                                    )}
                                    {(donation?.donationType?.hasCertificate && donation?.donationType?.isFoodForLife) && (
                                      <button
                                        onClick={() => handleImgDownload(donation._id)}
                                        className="custom-btn-cls box-hover"
                                      >
                                        Annadaan <HiOutlineDownload />
                                        <span>Annadaan certificate</span>
                                      </button>
                                    )}
                                  </div>
                                </>
                              }
                              {
                                donation?.paymentStatus === 'failed' &&
                                <>
                                      {
                                          donation.model === 'campaign' &&
                                          <a href={`/campaign-donation/${donation.type.toLowerCase().split(' ').join('-')}/${donation.amount}?donationId=${donation._id}`} className='custom-btn-cls box-hover m-0 text-center'>
                                            Retry
                                          </a>
                                        } 

                                        {
                                          donation.model === 'donationType' &&
                                          <a href={`/donation-form?donationId=${donation._id}`} className='custom-btn-cls box-hover m-0 text-center'>
                                            Retry
                                          </a>
                                        } 
                                </>
                              }
                              {
                                donation?.paymentStatus === 'pending' && <span className="processing">Processing</span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div> */}
                <nav className={`${lastPage <= 1 ? "d-none" : ""}`}>
                  <ul className="pagination">
                    <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                      <button
                        type="button"
                        className="page-link arrow"
                        onClick={() => setPage(page - 1)}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 256 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                        </svg>
                      </button>
                    </li>
                    <li className={`page-item ${page === 1 ? "d-none" : ""}`}>
                      <a className="page-link" onClick={() => setPage(page - 1)}>
                        {page - 1}
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link active">{page}</a>
                    </li>
                    <li className={`page-item ${page === totalPages ? "d-none" : ""}`}>
                      <a className="page-link" onClick={() => setPage(page + 1)}>
                        {page + 1}
                      </a>
                    </li>
                    <li
                      className={`page-item ${page === totalPages ? "disabled" : ""}`}
                    >
                      <button
                        type="button"
                        className="page-link arrow"
                        onClick={() => setPage(page + 1)}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 256 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>

              :

              <div className="donation-table">
                <h5 className='table-title mt-lg-5 mt-3 mb-lg-3 mb-0'>Donations</h5>
                <div className="table-heading py-4 d-none d-lg-block">
                  <div className="row justify-content-evenly align-items-center">

                    <div className="col-lg-3">
                      <h5>Date</h5>
                    </div>
                    <div className="col-lg-2">
                      <h5>Status</h5>
                    </div>
                    <div className="col-lg-2">
                      <h5>Name</h5>
                    </div>
                    <div className="col-lg-2">
                      <h5>Amount</h5>
                    </div>
                    <div className="col-lg-3">
                      <h5>Download</h5>
                    </div>

                  </div>
                </div>
                <div className="table-content pt-2 pt-lg-0">
                  <div className="row mt-4">
                    <center> <div
                      className="spinner-border text-dark"
                      role="status"
                    ></div></center>
                  </div>
                </div>
              </div>
          }


          <div className="life-membership-table">
            <h5 className='table-title mt-lg-5 mt-3 mb-lg-3 mb-0'>Nitya Seva</h5>
            <div className="table-heading py-lg-4 py-2 d-none d-lg-block">
              <div className="row justify-content-evenly align-items-center">
                <div className="col-lg-2">
                  <h5>Purpose</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Date</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Status</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Name</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Amount</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Action</h5>
                </div>
              </div>
            </div>
            <div className="table-content pt-2 pt-lg-0">
              <div className="row">
                {
                  scheme_list?.map((donation, i) => (

                    <div key={i--} className="col-lg-12 col-md-6 ">
                      <div className="table-row py-4">
                        <div className="row align-items-center">
                          <div className="col-lg-2">
                            <span>Nitya Seva</span>


                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Date :</h5>
                              <p id="donation-date">

                                <span>{moment(donation?.cell[26], 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY hh:mm A')}  </span>
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Status :</h5>
                              <p id="donation-status" className={`text-uppercase success`}>
                                {donation?.cell[13]}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Name :</h5>
                              <p id="donation-name">

                                <span>{donation?.cell[3]}</span>
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Amount :</h5>
                              <p id="donation-amount"> {donation?.cell[16].toLocaleString('en-IN', { maximumFractionDigits: 0, style: 'currency', currency: 'INR' })}</p>
                            </div>
                          </div>





                          <div className="col-lg-1">
                            <Tooltip title="view  details" placement="bottom-start">
                              <button
                                onClick={() => handleModalView(donation?.cell[1] + ',' + donation?.id + ',' + donation?.cell[19] + ',' + donation?.cell[20] + ',' + donation?.cell[17] + ',' + donation?.cell[13])}
                                className="bg-transparent p-1"
                                style={{ fontSize: '14px', marginTop: '12px', width: '62px' }}
                              >
                                <span>VIEW</span>
                              </button>
                            </Tooltip>

                          </div>

                          <div className="col-lg-1">
                            {donation?.cell[13] === 'INACTIVE' ? '' :
                              <Tooltip title="Cancellation of Nitya Seva Donation" placement="bottom-start">
                                <button
                                  onClick={() => handleModal(donation?.cell[1] + ',' + donation?.id)}
                                  className="bg-transparent p-1"
                                  style={{ fontSize: '14px', marginTop: '12px', width: '62px' }}
                                >
                                  <span>CANCEL</span>
                                </button>
                              </Tooltip>
                            }
                          </div>



                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>



          {
            ics_data?.filter(dat => dat.scheme === 'Life Membership')?.length > 0 &&
            <div className="life-membership-table">
              <h5 className='table-title mt-lg-5 mt-3 mb-lg-3 mb-0'>Life Membership</h5>
              <div className="table-heading py-lg-4 py-2 d-none d-lg-block">
                <div className="row justify-content-evenly align-items-center">
                  <div className="col-lg-2">
                    <h5>Purpose</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Date</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Status</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Name</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Amount</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Download</h5>
                  </div>
                </div>
              </div>
              <div className="table-content pt-2 pt-lg-0">
                <div className="row">
                  {
                    ics_data?.filter(dat => dat.scheme === 'Life Membership').map((donation, i) => (

                      <div key={i} className="col-lg-12 col-md-6 ">
                        <div className="table-row py-4">
                          <div className="row align-items-center">
                            <div className="col-lg-2">
                              <span>{donation?.scheme}</span>


                            </div>
                            <div className="col-lg-2">
                              <div className="form-field">
                                <h5 className="form-title">Date :</h5>
                                <p id="donation-date">

                                  {moment(donation?.createdAt, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY")}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <div className="form-field">
                                <h5 className="form-title">Status :</h5>
                                <p id="donation-status" className={`text-uppercase success`}>
                                  Success
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <div className="form-field">
                                <h5 className="form-title">Name :</h5>
                                <p id="donation-name">

                                  {donation?.name}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <div className="form-field">
                                <h5 className="form-title">Amount :</h5>
                                <p id="donation-amount"> {donation?.totalAmount ? donation?.totalAmount.toLocaleString('en-IN', { maximumFractionDigits: 0, style: 'currency', currency: 'INR' }) : donation?.amount.toLocaleString('en-IN', { maximumFractionDigits: 0, style: 'currency', currency: 'INR' })}</p>
                              </div>
                            </div>

                            <div className="col-lg-2">
                              <div className="form-field flex-column d-inline-flex">

                                <>
                                  <h5 className="form-title">Download :</h5>
                                  <div className="download-btns">
                                    {donation.taxBenefit ? (
                                      <>
                                        <button
                                          onClick={() => handleDownload(donation?.phone, donation?.instrument_no)}
                                          className=" ms-0 custom-btn-cls box-hover "
                                          disabled={loading[donation?.instrument_no] ? true : false}
                                        >
                                          80G  {loading[donation?.instrument_no] ? (
                                            <div className="d-flex align-items-center">
                                              <div
                                                className="spinner-border text-light"
                                                role="status"
                                              ></div>
                                            </div>
                                          ) : (
                                            <>
                                              <HiOutlineDownload />
                                            </>
                                          )}
                                          <span>Download Reciept</span>
                                        </button>

                                      </>
                                    ) : (
                                      <>

                                        <>
                                          {donation.taxBenefit ?
                                            <button
                                              onClick={() => handleDownload(donation?.phone, donation?.instrument_no)}
                                              className=" ms-0 custom-btn-cls box-hover "
                                              disabled={loading[donation?.instrument_no] ? true : false}
                                            >
                                              80G  {loading[donation?.instrument_no] ? (
                                                <div className="d-flex align-items-center">
                                                  <div
                                                    className="spinner-border text-light"
                                                    role="status"
                                                  ></div>
                                                </div>
                                              ) : (
                                                <>
                                                  <HiOutlineDownload />
                                                </>
                                              )}
                                              <span>Download Reciept</span>
                                            </button>
                                            :

                                            <button
                                              onClick={() =>
                                                navigate(
                                                  `/update-details?update-pan=${donations.find(dat => dat.eightyGNo == donation.rno)._id}&uid=${donation.icsid}&donation-type=donation`
                                                )
                                              }
                                              className=" ms-0 custom-btn-cls box-hover "
                                            >
                                              80G <HiOutlineDownload />
                                              <span>Download 80G certificate c</span>
                                            </button>
                                          }

                                          {donation.taxBenefit ?
                                            ''
                                            :
                                            <>
                                              <button
                                                onClick={() => handleDownload(donation?.phone, donation?.instrument_no)}
                                                className=" ms-0 custom-btn-cls box-hover "
                                                disabled={loading[donation?.instrument_no] ? true : false}
                                              >
                                                Non  80G  {loading[donation?.instrument_no] ? (
                                                  <div className="d-flex align-items-center">
                                                    <div
                                                      className="spinner-border text-light"
                                                      role="status"
                                                    ></div>
                                                  </div>
                                                ) : (
                                                  <>
                                                    <HiOutlineDownload />
                                                  </>
                                                )}
                                                <span>Download Reciept</span>
                                              </button>
                                            </>
                                          }
                                        </>

                                      </>
                                    )}
                                    {(donation?.donationType?.hasCertificate && donation?.donationType?.isFoodForLife) && (
                                      <button
                                        onClick={() => handleImgDownload(donation._id)}
                                        className="custom-btn-cls box-hover"
                                      >
                                        Annadaan <HiOutlineDownload />
                                        <span>Annadaan certificate</span>
                                      </button>
                                    )}
                                  </div>
                                </>

                                {
                                  donation?.paymentStatus === 'failed' &&
                                  <>
                                    {
                                      donation.model === 'campaign' &&
                                      <a href={`/campaign-donation/${donation.type.toLowerCase().split(' ').join('-')}/${donation.amount}?donationId=${donation._id}`} className='custom-btn-cls box-hover m-0 text-center'>
                                        Retry
                                      </a>
                                    //    <a href={`/dms/campaign-donation/${donation.type.toLowerCase().split(' ').join('-')}/${donation.amount}?donationId=${donation._id}`} className='custom-btn-cls box-hover m-0 text-center'>
                                    //    Retry
                                    //  </a>
                                    }

                                    {
                                      donation.model === 'donationType' &&
                                      <a href={`/donation-form?donationId=${donation._id}`} className='custom-btn-cls box-hover m-0 text-center'>
                                        Retry
                                      </a>
                                    //    <a href={`/dms/donation-form?donationId=${donation._id}`} className='custom-btn-cls box-hover m-0 text-center'>
                                    //    Retry
                                    //  </a>
                                    }
                                  </>
                                }
                                {
                                  donation?.paymentStatus === 'pending' && <span className="processing">Processing</span>
                                }
                              </div>
                            </div>



                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          }





          {/* <div className="life-membership-table">
              <h5 className='table-title mt-lg-5 mt-3 mb-lg-3 mb-0'>Nitya Seva</h5>
              <div className="table-heading py-lg-4 py-2 d-none d-lg-block">
                <div className="row justify-content-evenly align-items-center">
                  <div className="col-lg-2">
                    <h5>Purpose</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Date</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Status</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Name</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Amount</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Action</h5>
                  </div>
                </div>
              </div>
              <div className="table-content pt-2 pt-lg-0">
                <div className="row">
                {
                      scheme_list?.map((donation, i) => (

                        <div key={i--} className="col-lg-12 col-md-6 ">
                          <div className="table-row py-4">
                            <div className="row align-items-center">
                            <div className="col-lg-2">
                                  <span>Nitya Seva</span>
                             
                           
                            </div>
                              <div className="col-lg-2">
                                <div className="form-field">
                                  <h5 className="form-title">Date :</h5>
                                  <p id="donation-date">

                                  <span>{donation?.cell[26]}</span>
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-2">
                                <div className="form-field">
                                  <h5 className="form-title">Status :</h5>
                                  <p id="donation-status" className={`text-uppercase success`}>
                                  {donation?.cell[13]}
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-2">
                                <div className="form-field">
                                  <h5 className="form-title">Name :</h5>
                                  <p id="donation-name">

                                  <span>{donation?.cell[3]}</span>
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-2">
                                <div className="form-field">
                                  <h5 className="form-title">Amount :</h5>
                                  <p id="donation-amount"> {donation?.cell[16].toLocaleString('en-IN',{maximumFractionDigits: 0, style: 'currency', currency: 'INR'})}</p>
                                </div>
                              </div>

                              
                              <div className="col-lg-1">
                                <Tooltip title="Cancellation of Nitya Seva Donation" placement="bottom-start">
                                 <button
                                    onClick={() =>  handleModal(donation?.cell[1]+','+donation?.id)}
                                    className="custom-btn-cls box-hover"
                                    style={{fontSize:'14px',marginTop:'12px'}}
                                        > 
                                    <span>Cancel</span> 
                                  </button>
                                  </Tooltip>
                              
                              </div>

                              <div className="col-lg-1">
                                <Tooltip title="Cancellation of Nitya Seva Donation" placement="bottom-start">
                                 <button
                                    onClick={() =>  handleModalView(donation?.cell[1]+','+donation?.id+','+donation?.cell[19]+','+donation?.cell[20]+','+donation?.cell[17]+','+donation?.cell[13])}
                                    className="custom-btn-cls box-hover"
                                    style={{fontSize:'14px',marginTop:'12px'}}
                                        > 
                                    <span>view</span> 
                                  </button>
                                  </Tooltip>
                              
                              </div>

                             

                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div> */}





          {
            purchases?.length > 0 &&
            <div className="donation-table mt-5">
              <h5 className='table-title mt-lg-5 mt-3 mb-lg-3 mb-0'>Purchases</h5>
              <div className="table-heading py-4 d-none d-lg-block">
                <div className="row justify-content-evenly align-items-center">
                  <div className="col-lg-4">
                    <h5>Purpose</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Date</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Status</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Name</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Amount</h5>
                  </div>

                </div>
              </div>
              <div className="table-content pt-2 pt-lg-0">
                <div className="row">
                  {purchases?.map((purchase, i) => (
                    <div key={purchase._id} className="col-lg-12 col-md-6 ">
                      <div className="table-row py-4">
                        <div className="row align-items-center">
                          <div className="col-lg-4">
                            <div className="form-field">
                              <h5 className="form-title">Purpose :</h5>
                              <p id="donation-purpose">
                                {purchase?.productName}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Date :</h5>
                              <p id="donation-date">

                                {moment(purchase?.createdAt).format("DD/MM/YYYY")}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Status :</h5>
                              <p id="donation-status" className={`text-uppercase ${purchase?.paymentStatus === 'success' ? 'success' : 'failed'}`}>
                                {purchase?.paymentStatus === 'failed' ? 'Unsuccessful' : purchase?.paymentStatus}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Name :</h5>
                              <p id="donation-name">

                                {purchase?.name}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Amount :</h5>
                              <p id="donation-amount">{purchase?.totalAmount ? purchase?.totalAmount.toLocaleString('en-IN', { maximumFractionDigits: 0, style: 'currency', currency: 'INR' }) : purchase?.amount.toLocaleString('en-IN', { maximumFractionDigits: 0, style: 'currency', currency: 'INR' })}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <nav className={`${lastPage <= 1 ? "d-none" : ""}`}>
                  <ul className="pagination">
                    <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                      <button
                        type="button"
                        className="page-link arrow"
                        onClick={() => setPage(page - 1)}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 256 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                        </svg>
                      </button>
                    </li>
                    <li className={`page-item ${page === 1 ? "d-none" : ""}`}>
                      <a className="page-link" onClick={() => setPage(page - 1)}>
                        {page - 1}
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link active">{page}</a>
                    </li>
                    <li className={`page-item ${page === lastPage ? "d-none" : ""}`}>
                      <a className="page-link" onClick={() => setPage(page + 1)}>
                        {page + 1}
                      </a>
                    </li>
                    <li
                      className={`page-item ${page === lastPage ? "disabled" : ""}`}
                    >
                      <button
                        type="button"
                        className="page-link arrow"
                        onClick={() => setPage(page + 1)}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 256 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </nav> */}
            </div>
          }
        </div>
      </section>
      <Modal centered show={showFYModal} onHide={() => setShowFYModal(false)} className="eightyG-modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>As per Income Tax Department guidelines, for getting 80G certificate it is mandatory to provide complete Address and Name as on PAN card.</p>
          <p className="mb-0">Please Note: Any changes to your donations are allowed only till the 15th of April.</p>
          <p>Contact <a href="mailto:support@iskconbhiwandi.org">support@iskconbhiwandi.org</a> for any queries.</p>
          <p>80G certificate Generated.</p>

          <button onClick={() => handleFileDownload(download_id)} className='mb-0 custom-btn-cls box-hover px-4' >
            Download Certificate
          </button>
        </Modal.Body>
      </Modal>

      <Modal centered show={isDialogOpen}>
        <div className="modal-wrap">
          <button className="btn close-svg" onClick={closeDialog}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
              ></path>
            </svg>
          </button>
          <div className="row">

            <div className="col-md-12">
              <div className="pop-up-form">

                {/* <div className="pop-up-top">
                                    <figure className="hare-krishna-logo-fig">
                                        <img src={`/images/campaign/feedbackimg.jpeg`} alt="" />
                                    </figure>
                                    <h3></h3>

                                    <figure className="hare-krishna-logo-fig">
                                        <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                                    </figure>
                                </div> */}

                <form
                  action=""
                  className="mt-3 mt-sm-0"
                  onSubmit={handleSubmit(handleNityaSeva)}
                >

                  <input
                    type="hidden"
                    id="sub_id"
                    {...register("sub_id")}
                    value={sub_subid}
                  />

                  <div className="row">

                    <div className="col-md-12">
                      <div className="label-input-wrap">
                        <label htmlFor="cancelReason" className="imp">Kindly let us know, the reason for cancellation for Nitya Seva.</label>
                        <textarea type="text"
                          id="cancelReason"
                          {...register("cancelReason", { required: true })}
                          onKeyPress={(event) => {
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }} required />
                        {errors.donorname?.type === "required" && (
                          <span>  Name is required </span>
                        )}

                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="label-input-wrap">
                        <button className="box-hover custom-btn" type="submit">Submit</button>

                      </div>
                    </div>



                  </div>
                </form>



              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal centered show={isDialogViewOpen}>
        <div className="modal-wrap">
          <button className="btn close-svg" onClick={closeDialogView}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
              ></path>
            </svg>
          </button>
          <div className="row">

            <div className="col-md-12">
              <div className="pop-up-form">

                <div className="pop-up-top">
                  <figure className="hare-krishna-logo-fig">
                    <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                  </figure>
                  <h3></h3>

                  {/* <figure className="hare-krishna-logo-fig">
                                        <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                                    </figure> */}
                </div>

                <div className="container">
                  <div className="row">
                    <div className="col-4">
                      <b>Subscription Id:</b>
                    </div>
                    <div className="col-4">
                      <p>{sub_subid}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <b>Period:</b>
                    </div>
                    <div className="col-4">
                      <p>{scheme_period}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <b>Scheme Start:</b>
                    </div>
                    <div className="col-4">
                      <p>{scheme_start}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <b>Scheme End:</b>
                    </div>
                    <div className="col-4">
                      <p>{scheme_end}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 mt-2">
                      <b>Scheme Status:</b>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-3 mt-2">
                          <p style={{ color: scheme_status === 'ACTIVE' ? 'green' : 'red' }}>{scheme_status === 'INACTIVE' ? 'Cancelled' : scheme_status}</p>
                        </div>
                        <div className="col-3">
                          {scheme_status === 'ACTIVE' ?

                            <Tooltip title="Pause of Nitya Seva Donation" placement="bottom-start">
                              <button
                                onClick={() => handleNityaSevaPause(sub_subid + ',hold')}
                                className="custom-btn-cls box-hover text-white"
                                style={{ fontSize: '14px', marginTop: '12px' }}
                              >
                                Pause
                              </button>
                            </Tooltip>


                            : scheme_status === 'ON-HOLD' ?

                              <Tooltip title="Resume of Nitya Seva Donation" placement="bottom-start">
                                <button
                                  onClick={() => handleNityaSevaPause(sub_subid + ',start')}
                                  className="custom-btn-cls box-hover text-white"
                                  style={{ fontSize: '14px', marginTop: '12px' }}
                                >
                                  Resume
                                </button>
                              </Tooltip>
                              : ''
                          }
                        </div>
                      </div>


                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AllTransactions;