import React, { useEffect, useState } from "react";
import ApiService from "../api/ApiService";
import notification from "../helpers/notification";

const AppContext = React.createContext({});

export const AppContextProvider = ({ children }) => {
  const [loginPopup, setLoginPopup] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(null);
  const [showHeaderFooter, setShowHeaderFooter] = useState(true)
  const [donor, setDonor] = useState(null)
  const [profiles, setProfiles] = useState(null)

  
  const fetchUser = async () => {
    try {
      const res = await ApiService.fetchUser();
      // console.log(res)
     // setUser(Data);
      //setUser(res);
    } catch (err) {
      console.log(err);
      localStorage.removeItem("token");
      setUser(null);
      notification("error", "Something went wrong!");
    }
  };


  const fetchUserIcs = async () => {

    const response  = await ApiService.getIcsData(localStorage.getItem('ics_number'))


      // const url = process.env.REACT_APP_ICS_URL
      // // const url = 'http://localhost:8080/ics/api/actionHandler'
      // const response = await axios.get(url,{
      //     params:{
      //         cn:'donation',
      //         an:'getICSids', //or getDonations
      //         phone: localStorage.getItem('ics_number'),
      //         mode: 'DETAILED'    //DETAILED or DETAILED_WITH_DONATIONS or blank
      //     },
      //     withCredentials: true,
      //     auth: {
      //        username: process.env.REACT_APP_ICS_USERNAME,
      //        password: process.env.REACT_APP_ICS_PASSWORD
      //     }
      // });

      

     /// console.log(response.data.data[0].number);
  
      const responseData =response.data.filter(dat => dat.id == localStorage.getItem('ics_id'));
      // console.log(responseData);
      let email = ''
        if(responseData.length > 0){
      if(responseData[0].email){
        email = responseData[0].email.emailAddress;
        
      }
      
      let Data = {
        name:responseData[0].name.trim(),
        icsid:responseData[0].id,
        address:responseData[0].address.line1+' '+responseData[0].address.line2+' '+responseData[0].address.line3,
        pin:responseData[0].address.pincode.trim(),
        email:email,
        phone:responseData[0].number.trim(),
        pan:responseData[0].pan,
        relative:responseData[0].relatives,
        ...responseData
      }
      setUser(Data);
    }else{
      localStorage.removeItem("token");
      setUser(null);
      setToken(null);
      localStorage.removeItem('icsmob');
      localStorage.removeItem('ics_mob');
      localStorage.removeItem('ics_id');
      localStorage.removeItem('multiplesevatype');
      localStorage.removeItem('multiplesevaPrice');
      localStorage.removeItem('multiplesevaName');
      localStorage.removeItem('ics_number')
      
      localStorage.removeItem('ics_all_data');
    }
  };

  const fetchDonor = async() => {
      try {
       
        const data = {
          donor_id: localStorage.getItem('dms_id'),
        }
        const response = await ApiService.fetchDonor(data);
        if(response?.donor) {
          setDonor(response?.donor);
          setProfiles(response?.profiles);
        }

      } catch (err) {
        console.log(err);
        setUser(null);
      }
  }

  // useEffect(() => {
  //   if(token){
  //   fetchUserIcs();
  //   }else {
  //     setUser(null);
  //   }
  // },[token])


 
  useEffect(() => {
   
    if (token) {
      //fetchUser();
      fetchDonor()
    }else{
      setDonor('')
    }
  }, [token]);

  let values = {
    loginPopup,
    setLoginPopup,
    token,
    setToken,
    user,
    setUser,
    fetchUser,
    setShowHeaderFooter,
    showHeaderFooter,
    donor,
    setDonor,
    setProfiles,
    profiles,
    fetchDonor
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export default AppContext;
