import IskconStoryVideo from "../components/about/IskconStoryVideo";
import PageHeader from "../components/common/PageHeader"
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useEffect, useRef, useState, useCallback } from "react";
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import { Link } from 'react-router-dom';
import ApiService from "../api/ApiService";


const FoodForLife = () => {

    const foodSliderRef = useRef()

    const [meta, setMeta] = useState(null)
    const [top, setTop] = useState(0);
    const festRef = useCallback((node) => {
        if (node) {
            setTop(node.offsetTop - 45);
        }
    }, []);
    const fetchMeta = async (pagename) => {
        try {
            let res = await ApiService.fetchMetaData(pagename)
            setMeta(res)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchMeta('annadaan')
    }, [])

    return (
        <>
            <MetaData title="Food For Life - ISKCON of Bhiwandi" />
            <PageHeader title="FOOD FOR LIFE - ANNADAAN" imgSrc="/images/page-header/07.webp" />
            <section className="FoodForLife pb-6" ref={festRef}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">HELP CREATE A HUNGER-FREE WORLD</h2>
                                <p className="mb-1">Sri Sri Radha Madhava Temple ISKCON Bhiwandi started the Food for Life Annadaan movement in 2020 when migrant workers around the temple  needed one nutritious meal and we shared with them the Lord’s prasad.
                                </p>
                                <p className="mb-1 b-line">1000 Satvic meals cooked in pure cow ghee are prepared everyday in our temple kitchen, first offered to the Lord, and then taken to the hungry in and around the slums of Bhiwandi. Today there are 1000 less hungry people in Bhiwandi but there are 4000 more who urgently need that one wholesome meal, and your support could make the difference to their lives. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="foodforlife-video">
                <IskconStoryVideo
                    imgSrc="/images/food-for-life/food-for-life-thumbnail.jpg"
                    videoLink="https://www.youtube.com/watch?v=0DUpVpxk9nw"
                    smallText='WATCH OUR  DOCUMENTARY ON'
                    bigText="ANNADAAN"
                />
            </section>
            <section className="FoodForLife FoodForLife2 pt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="heading">

                                <p className="mb-1">All it takes is ₹30 to free someone of hunger. That’s the cost of one meal where the highest quality ingredients are sourced and cooked to the highest standards and distributed as prasad. Just thirty rupees from you can provide a wholesome meal to someone in need of sustenance...</p>
                                <p className="mb-1"> Srila Prabhupada encouraged free prasad distribution right from 1966 when he founded the Hare Krishna Movement. He would often say that by generous prasad and sankirtan distribution the whole world can attain peace and prosperity.</p>
                                <p className="mb-1"> Everything you offer on the altar becomes Prasad, in that it becomes spiritualised. The Lord enters into the offerings and the prasad is no different from Him. Lord Chaitanya Mahaprabhu said that remnants of food that Krishna has eaten surpass heavenly nectar and only persons who have the full mercy of Krishna can receive such remnants. Hare Krishna!</p>

                                <Link className="custom-btn-cls box-hover" to="/donation-form/food-for-life">Contribute Now</Link>


                            </div>

                        </div>
                    </div>
                    <div className="text-center">
                        <p>
                            <Link className="custom-btn-cls box-hover" to="/donation-form/food-for-life">It takes only ₹30 to free someone of hunger</Link>
                        </p>
                        <p className="my-1">Come be a part of the ISKCON Bhiwandi congregation.</p>
                        <p className="my-1">Come join the bliss of Krishna.</p>
                    </div>
                </div>
            </section>
            <section className="food-life-slider">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-10 mx-auto">
                            <div className="heading">
                                <span className="title-logo">
                                    <img src="/images/food-for-life/annadan-logo.svg" alt="" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <Splide
                        ref={foodSliderRef}
                        className="food-slider"
                        options={{
                            perPage: 3,
                            perMove: 1,
                            gap: 20,
                            arrows: false,
                            pagination: false,
                            autoplay: true,
                            speed: 1000,
                            interval: 8000,
                            breakpoints: {
                                576: {
                                    perPage: 1,
                                    gap: 20,
                                },
                                768: {
                                    perPage: 2,
                                    trimSpace: true,
                                },
                                992: {
                                    trimSpace: true,
                                    perPage: 2,
                                    gap: 20,
                                },
                                1024: {
                                    trimSpace: true,
                                    perPage: 3,
                                    gap: 20,
                                },
                                1200: {
                                    trimSpace: false,
                                    perPage: 3
                                },
                            }
                        }}
                    >
                        <SplideSlide>
                            <figure className="mb-0">
                                <img src="/images/food-for-life/food-for-life-1.webp" alt="" />
                            </figure>
                        </SplideSlide>
                        <SplideSlide>
                            <figure className="mb-0">
                                <img src="/images/food-for-life/food-for-life-2.webp" alt="" />
                            </figure>
                        </SplideSlide>
                        <SplideSlide>
                            <figure className="mb-0">
                                <img src="/images/food-for-life/food-for-life-3.webp" alt="" />
                            </figure>
                        </SplideSlide>
                        <SplideSlide>
                            <figure className="mb-0">
                                <img src="/images/food-for-life/food-for-life-4.webp" alt="" />
                            </figure>
                        </SplideSlide>
                        <SplideSlide>
                            <figure className="mb-0">
                                <img src="/images/food-for-life/food-for-life-5.webp" alt="" />
                            </figure>
                        </SplideSlide>
                        <SplideSlide>
                            <figure className="mb-0">
                                <img src="/images/food-for-life/food-for-life-6.webp" alt="" />
                            </figure>
                        </SplideSlide>
                    </Splide>
                    <div className="row mt-5">
                        <div className="col-lg-6 mx-auto text-center">
                            <Link className="custom-btn-cls box-hover" to="/donation-form/food-for-life">Donate now</Link>

                        </div>
                    </div>
                </div>
                <div className="arrow-wrap">
                    <button className="slider-btn prev box-hover" onClick={() => foodSliderRef.current.splide.go('-1')} ><FiChevronLeft /></button>
                    <button className="slider-btn next box-hover" onClick={() => foodSliderRef.current.splide.go('+1')}><FiChevronRight /></button>
                </div>
            </section>
            <GoToTop top={top} />
        </>
    );
}

export default FoodForLife;