import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import MetaData from '../helpers/MetaData'
import PageHeader from '../components/common/PageHeader'
import notification from '../helpers/notification'
import ApiService from '../api/ApiService'
import { Modal } from 'react-bootstrap'
import { useForm, Controller } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom'
import AppContext from '../context/AppContext'


const KartikDeepdaan = () => {

    const [selectedDates, setSelectedDates] = useState([])
    const [eventDates, setEventDates] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)
    const recaptchaRef = useRef();
    const [paymentModal, setPaymentModal] = useState(false)
    const navigate = useNavigate();
    const { fetchUser, user, setLoginPopup } = useContext(AppContext)
    const [showMemoryBox, setShowMemoryBox] = useState(false);



    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        reset,
        setValue,
        getValues,
        control,
        watch
    } = useForm({});

    useEffect(() => {
      const showBox = watch((value) => {  
        setShowMemoryBox(value.memoryOfSomeone)
      });
      return () => showBox.unsubscribe();
    }, [watch]);


    const fetchKartikDeepdaans = async () => {
        try {
            let res = await ApiService.fetchKartikDeepdaans()
            setEventDates(res.kartikDeepdaans)
        } catch (error) {
            console.log(error)
            notification("error", error);
        }
    }

    const loadScript = (src) => {
        return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = src;
          script.onload = () => {
            resolve(true);
          };
          script.onerror = () => {
            resolve(false);
          };
          document.body.appendChild(script);
        });
    };

    useEffect(() => {
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
        fetchKartikDeepdaans()
    }, [])

    useEffect(() => {
        setTotalAmount(selectedDates.reduce((a, b) => a + b.amount, 0))
    }, [selectedDates])

    const selectDates = (e) => {
        let value = JSON.parse(e.target.value) 
        let result = selectedDates.some(date => (date.date === value.date))
            
        if(!result) {
            setSelectedDates(s => [...s, JSON.parse(e.target.value)])
            // notification('success', 'Event Selected')
          } else {
            let newArr = selectedDates.filter(d => d.date !== value.date)
            setSelectedDates([...newArr])
            // notification('error', 'Event Removed')
        }
    }

    const handleDonate = async (data) => {
        try {
            let payload = {
                ...data,
                donationDates: selectedDates
            }
            let res = await ApiService.createKartikDeepdaanPayment(payload)
            
            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY,
                currency: res.currency,
                amount: res.amount,
                name: process.env.REACT_APP_PROJECT_NAME,
                description: "Help Us to Help the Society",
                image: `${process.env.REACT_APP_S3_URL}/assets/img/logo.png`,
                order_id: res.id,
                handler: function (response) {
                  //console.log(response)
                  navigate(`/thank-you/${res._id}/kartik-deepdaan`, { replace: true });
                  localStorage.setItem(`donation-${res._id}`, res._id);
                },
                prefill: {
                  name: res.name ? res.name : "",
                  email: res.email ? res.email : "",
                  contact: res.phone ? res.phone : "",
                },
                notes: {
                  type: "kartik-deepdaan",
                },
                theme: {
                  color: "#FFD370",
                },
                modal: {
                //   ondismiss: async function () {
                //     const cancelDonation = await ApiService.cancelDonation(res.id);
                //   },
                },
              };
        
              const paymentObject = new window.Razorpay(options);
              paymentObject.open();
        
              paymentObject.on("payment.failed", function (response) {
                //console.log(response)
                notification("error", 'Payment Failed, Date availability changed, Page will now refresh');
                setTimeout(() => {
                  window.location.reload();
                }, 3000)
              });
            
        } catch (error) {
            console.log(error)
            notification("error", error.response.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 3000)
        }
    }

  return (
    <>
      <MetaData />
      <PageHeader
        title={"Kartik Deepdaan"}
        imgSrc={
          "/images/campaign/kartik-deepdaan-banner.jpeg"
        }
      />
      <section className="py-5 kartik-deepdaan-calendar campaign-heading-sec">
        <form action="" method="post">
          <div className="container">
          <div className="row mb-5">
                <div className="col-lg-10 mx-auto">
                  <div className="heading mx-auto">
                    <h2 className="pb-md-4 pb-3">
                    Kartik is the month of Damodar, the most auspicious month for spiritual advancement and is best of all the months. Illuminate your life by offering DeepDaan seva in this moth and seek blessings of Their Lordships Sri Sri Radha Madhava.
                    <span className='d-block'>
                      As per Padma Purana, "One who offers a lamp to Lord Krishna during Kartik attains to the eternal spiritual world where there is no suffering"
                    </span>
                    </h2>
                  </div>
                  <figure className="mb-0">
                    <img
                      className="w-100"
                      src={`/images/campaign/kartik-deepdaan.jpeg`}
                      alt="Campaign image"
                    />
                  </figure>
                </div>
            </div>
            <div className="row">
              <div className="col-lg-10">
                <div className="grid-container-days d-none d-lg-grid">
                  <div className="col grid-item-days">Sunday</div>
                  <div className="col grid-item-days">Monday</div>
                  <div className="col grid-item-days">Tuesday</div>
                  <div className="col grid-item-days">Wedneday</div>
                  <div className="col grid-item-days">Thursday</div>
                  <div className="col grid-item-days">Friday</div>
                  <div className="col grid-item-days">Saturday</div>
                </div>
                <div className="calendar grid-container">
                  {eventDates.map((event, i) => (
                    <div key={i} className="col grid-item">
                      {(event.donationOccurences >= 1 && (moment(event.date).add(1, 'day') > moment()))  ? (
                        <>
                          <input
                            type="checkbox"
                            name={event.name}
                            onChange={selectDates}
                            value={JSON.stringify(event)}
                            id={event._id}
                          ></input>
                          <label
                            htmlFor={event._id}
                            className={`date-info-block`}
                          >
                            <span className="event-date d-flex align-items-center justify-content-between">
                              <span className='event-date month'>{moment(event.date).format("MMMM")}</span>
                              <span className='event-date day'>{moment(event.date).format("D")}<span className='d-inline-block d-lg-none'>, {moment(event.date).format("ddd")}</span></span>
                            </span>
                            <span className="event-title d-block">
                              {event.name}
                            </span>
                            <span className="event-amount d-block">
                              {event.amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "INR",
                                maximumFractionDigits: 0,
                              })}
                            </span>
                          </label>
                        </>
                      ) : (
                        <>
                          <div className="date-info-block booked">
                          <span className="event-date d-flex align-items-center justify-content-between">
                              <span className='event-date month'>{moment(event.date).format("MMMM")}</span>
                              <span className='event-date day'>{moment(event.date).format("D")}</span>
                            </span>
                            <span className="event-title d-block">
                              {event.name}
                            </span>
                            {
                              event.donationOccurences < 1
                              ?
                              <span className="event-amount d-block">Already Booked</span>
                              :
                              null
                            }
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-2 mt-4 mt-lg-0">
                <div className="summary">
                  <div className="h5">Selected Events</div>
                  <div className="selected-items">
                    {selectedDates.length ? (
                      <div className="selected-events-list">
                        {selectedDates.map((date, i) => (
                          <div className="mb-3" key={i}>
                            {i + 1}. {moment(date.date).format("Do MMM, yyyy")}{" "}
                            - {date.name} (
                            {date.amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "INR",
                              maximumFractionDigits: 0,
                            })}
                            )
                          </div>
                        ))}
                      </div>
                    ) : (
                      <span>No events selected</span>
                    )}
                  </div>
                  <div className="selected-items-pay">
                    {/* <div className="total-amount h6">
                      {totalAmount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "INR",
                        })}
                    </div> */}
                    {
                      totalAmount ?
                        <button
                          type="button"
                          className="box-hover custom-btn-cls m-0"
                          onClick={() => {
                            if(user) {
                              setValue('name', user.name)
                              setValue('phone', user.phone)
                              setValue('email', user.email)
                              setValue('pan', user.pan)
                              setValue('address', user.address)
                              setValue('pin', user.pin)
                              setPaymentModal(true)
                            } else {
                              setPaymentModal(true)
                            }
                          }}
                        >
                          Donate {totalAmount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "INR",
                          maximumFractionDigits: 0,
                        })}
                        </button>
                      :
                      null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      <Modal
        centered
        show={paymentModal}
        onHide={() => setPaymentModal(false)}
        className="kartik-deepdaan-modal"
      >
        <Modal.Header closeButton>Donate now</Modal.Header>
        <Modal.Body>
          <section className="donation-form-sec py-0">
            <form onSubmit={handleSubmit(handleDonate)}>
              <div className="row align-items-start">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 mb-3">
                        Donation for dates: { selectedDates.map(d => (<span key={d._id}>{ moment(d.date).format('Do MMM yyyy') }, </span>)) }
                    </div>
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">Name</label>
                        <input
                          maxLength={50}
                          type="text"
                          // onKeyUp={fetchNameFromIcs}
                          {...register("name", {
                            required: true,
                            maxLength: {
                              value: 50,
                              message:
                                "Name can't exceeds more than 50 characters",
                            },
                            pattern: {
                              value: /^[A-Za-z ]*$/,
                              message: "Special Characters not allowed !",
                            },
                          })}
                          onKeyPress={(event) => {
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Name"
                        />
                        {errors.name?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.name?.message && (
                          <small className="text-danger error">
                            {errors.name?.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">Mobile Number</label>
                        <input
                          type="tel"
                          placeholder="Phone Number"
                          // onKeyUp={getIcsData}
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        //   readOnly={user ? true : false}
                          // disabled=
                          {...register("phone", {
                            required: true,
                            pattern: {
                              value: /^[6-9]\d*$/,
                              message: "Please provide valid phone number",
                            },
                            minLength: {
                              value: 10,
                              message: "Phone number must be minimum 10 digit",
                            },
                          })}
                        />
                        {errors.phone?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.phone && (
                          <small className="text-danger error">
                            {errors.phone.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">Email</label>
                        <input
                          type="text"
                          {...register("email", {
                            required: true,
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email",
                            },
                          })}
                          placeholder="Email"
                        />
                        {errors.email?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.email && (
                          <small className="text-danger error">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">PAN (Optional)</label>
                        <input
                          type="text"
                          style={{ textTransform: "uppercase" }}
                          name=""
                          id=""
                          // onKeyUp={fetchPanFromIcs}
                          maxLength={10}
                          placeholder="PAN"
                          {...register("pan", {
                            pattern: {
                              value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                              message: "Please enter valid PAN",
                            },
                          })}
                        />
                        {errors.pan && (
                          <small className="text-danger error">
                            {errors.pan.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">Address (Optional)</label>
                        <textarea
                          type="text"
                          {...register("address", {
                            minLength: {
                              value: 10,
                              message: "address must be minimum 10 characters",
                            },
                          })}
                          placeholder="Address"
                        ></textarea>
                        {errors.address && (
                          <small className="error">
                            {errors.address.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">PIN Code (Optional)</label>
                        <input
                          type="text"
                          minLength={6}
                          maxLength={6}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("pin", {
                            pattern: {
                              value: /^\d+$/,
                              message: "PIN code must be number.",
                            },
                          })}
                          placeholder="PIN Code"
                        />

                        {errors.pin && (
                          <small className="text-danger error">
                            {errors.pin.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        ref={recaptchaRef}
                        size="invisible"
                      />
                    </div>
                    <div className="col-12">
                      <div className="type_checkbox mt-3">
                        <input
                          type="checkbox"
                          id="memoryOfSomeone"
                          {...register("memoryOfSomeone")}
                        />
                        <label htmlFor="memoryOfSomeone">
                          Special Note
                        </label>
                      </div>
                      {showMemoryBox && (
                        <div className="form-part mt-2 memory_field">
                          <textarea
                            type="text"
                            {...register("memoryText")}
                          ></textarea>
                        </div>
                      )}
                    </div>
                    <div className="col-12 mt-2">
                      <button
                        type="submit"
                        className="box-hover custom-btn-cls donation_btn ms-0 donate-now-clicked-form"
                        disabled={isSubmitting ? true : false}
                      >
                        {isSubmitting ? (
                          <div className="d-flex align-items-center">
                            <div
                              className="spinner-border text-light"
                              role="status"
                            ></div>
                            <div className="ms-2">DONATING...</div>
                          </div>
                        ) : (
                          <div className="ms-2">DONATE NOW ({totalAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "INR",
                            maximumFractionDigits: 0,
                          })})</div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default KartikDeepdaan