import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import PageHeader from "../components/common/PageHeader";
import AppContext from "../context/AppContext";
import MetaData from "../helpers/MetaData";
import { useForm } from "react-hook-form";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import axios from "axios";
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useState } from "react";

const UpdateProfile = () => {
  const search = useLocation().search;
  const link_id = new URLSearchParams(search).get("link");
  const sectionRef = useRef(null);

  const { id } = useParams()
    const {user, fetchUser, donor, profiles} = useContext(AppContext)
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [ics_data, setICSdata] = useState([]);
    const [user_name,  setUserName] = useState([]);
    const [user_email,   setUserEmail] = useState([]);
    const [user_phone,   setUserPhone] = useState([]);  
    const [user_address,  setUserAddress] = useState([]);
    const [user_streetName,  setUserStreetName] = useState([]);
    const [user_areaName,  setUserAreaName] = useState([]);
    const [user_pan,  setUserPan] = useState([]);
    const [user_pin,  setUserPin] = useState([]);
    const [user_state,  setUserState] = useState([]);
    const [user_city,  setUserCity] = useState([]);
    const navigate = useNavigate();

   

    const icsProfileUpdate = async (data) => {
      //console.log('jjjjj')

      try {

        let profileUpdateData = {
                icsid: id,
                name: data.name,
                number : user_phone,
                email : data.email,
                panNo : data.pan,
                dob : '',
                ma : '',
                addressLine1 : data.address,
                addressLine2 : data.streetName,
                addressLine3 : data.areaName,
                city : data.city,
                state : data.state,
                country : 'India',
                pincode : data.pin,
                followupCategory : data.comm,
                followupDescription : 'followupDescription',
                mode: 'STP',       
        }


        const respon = await ApiService.icsUpdate(profileUpdateData)







        // const url = process.env.REACT_APP_ICS_URL
        // // const url = 'http://localhost:8080/ics/api/actionHandler'
        // const response = await axios.get(url,{
        //     params:{
        //         cn:'giftIssued',
        //         an:'deliverUpdate',
        //         icsid: id,
        //         name: data.name,
        //         number : user_phone,
        //         email : data.email,
        //         panNo : data.pan,
        //         dob : '',
        //         ma : '',
        //         addressLine1 : data.address,
        //         addressLine2 : '',
        //         addressLine3 : '',
        //         city : data.city,
        //         state : data.state,
        //         country : 'India',
        //         pincode : data.pin,
        //         followupCategory : data.comm,
        //         followupDescription : 'followupDescription',
        //         mode: 'STP',                    },
        //     withCredentials: true,
        //     auth: {
        //      username: process.env.REACT_APP_ICS_USERNAME,
        //      password: process.env.REACT_APP_ICS_PASSWORD
        //     }
        // });

        if(respon){
          //window.location.reload(true)
          navigate('/ics/dashboard')
        }

        //console.log(respon);
    } catch (error) {
        console.error(error);
    }

     // console.log(response.data);
    }

    const sendProfileDataToDms = async (data) => {
      try {
        const {link_click, icsid, ...rest} = data;
        let donorId;
        if(donor) {
          if(donor.legal_name === rest.name) {
            donorId = donor.id
          }else {
            const _donor = profiles?.find(profile => profile.legal_name === rest.name);
            donorId = _donor.id
          }

          await ApiService.updateDonor({
            id: donorId,
            data: rest
          });
        }
        
      } catch (error) {
        console.log(error);
      }
    }

    const handleProfileUpdate = async (data) => {
        try {

            data.link_click = link_id ? 'yes' : 'No';
            data.icsid = id;
            
            const res = await ApiService.updateProfile(data)
            // console.log(res)
            fetchUser()
            notification('success', res.message)
            if(donor) {
              sendProfileDataToDms(data);
            }
            icsProfileUpdate(data)
           
        } catch (err) {
            console.log(err)
            notification('error', err.response.data.message)
        }
    }


    ///ICS START///
const  GetData = async ()  => {
 const mob = localStorage.getItem('ics_number');

 const response = await ApiService.getIcsData(mob)
    // const url = process.env.REACT_APP_ICS_URL
    // // const url = 'http://localhost:8080/ics/api/actionHandler'
    // const response = await axios.get(url,{
    //     params:{
    //         cn:'donation',
    //         an:'getICSids', //or getDonations
    //         phone: mob,
    //         mode: 'DETAILED'    //DETAILED or DETAILED_WITH_DONATIONS or blank
    //     },
    //     withCredentials: true,
    //     auth: {
    //      username: process.env.REACT_APP_ICS_USERNAME,
    //      password: process.env.REACT_APP_ICS_PASSWORD
    //     }
    // });

    //console.log(response.data);

    response.data.filter(dat => dat.id==id).map((item) => (
           setUserName(item.name),
           setUserEmail(item.email ? item.email.emailAddress : ''),
           setUserPhone(item.number),
           setUserAddress(item.address.line1),
           setUserStreetName(item.address.line2),
           setUserAreaName(item.address.line3),
           setUserPan(item.pan),
           setUserPin(item.address.pincode), 
           setUserCity(item.address.city),
           setUserState(item.address.state)   
    ))
   

    setICSdata(response.data);

  
  }
    ///ICS  END///
    
    //console.log(ics_data);
    

    useEffect(() => {
      fetchUser();
      GetData();
    },[])


    useEffect(() => {
      let intervalId;
      
      if (link_id) {
        intervalId = setInterval(() => {
          sectionRef.current.scrollIntoView({ behavior: 'smooth' });
          clearInterval(intervalId); // Clear the interval after the first click
        }, 100);
      }
  
      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, [link_id]);
    

    useEffect(() => {
        if(user) {
            reset({
              name: ics_data ? user_name : user.name,
              email: ics_data ? user_email : user.email,
              phone: ics_data ? user_phone : user.phone,
              address: ics_data ? user_address : user.address,
              streetName: ics_data ? user_streetName : '',
              areaName: ics_data ? user_areaName : '',
              pan: ics_data ? user_pan : user.pan,
              pin: ics_data ? user_pin : user.pin,
              city: ics_data ? user_city : user.city,
              state: ics_data ? user_state : user.state,
  
          })
          
        }
    },[user,user_name, user_address, user_pan, user_phone, user_pin, user_email])

  return (
    <>
      <MetaData title="Dashboard - ISKCON of Bhiwandi" />
      <PageHeader title="User profile" imgSrc="/images/page-header/24.jpg" />
      <section className="donation-form-sec">
        <div className="container">
          <div className="row d-flex justify-content-center mb-5">
            <div className="col-md-10">
              <div className="heading">
                <h2 className="head-1 text-uppercase">Update Profile</h2>
                <p className="b-line">
                  If you would like to make a donation towards a particular area
                  of activity, please select an option from below. ISKCON relies
                  entirely on voluntary donations and so every donation counts.
                  Please note that donation is processed on a secure site.
                </p>
              </div>
            </div>
            <div ref={sectionRef} className="form-wrap my-5">
              <form onSubmit={handleSubmit(handleProfileUpdate)}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Name</label>
                      <input
                        type="text"
                        disabled={true}
                        placeholder="Name"
                        {...register('name')}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Mobile Number</label>
                      <input
                        type="tel"
                        placeholder="Mobile Number"
                        disabled={true}
                        {...register('phone')}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Email</label>
                      <input
                        type="email"
                        
                        placeholder="Email"
                        {...register('email')}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">PAN</label>
                      <input
                        type="text"
                        style={{textTransform: 'uppercase'}}
                        maxLength={10}
                        disabled={user_pan ? true : false}
                        placeholder="PAN"
                        {...register('pan', {
                          pattern: {
                            value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                            message: 'Please enter valid PAN'
                          }
                        })}
                      />  
                      {user_pan ? 
                      <small 
                          className="donation-link"
                         style={{color:'#F3B01B'}}
                        >
                          * Note :- Please contact to support team for changes in the PAN number on   support@iskconbhiwandi.org
                        </small>
                        : ''}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Street Name</label>
                      <input
                        type="text"
                        placeholder="Street Name"
                        {...register('streetName')}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Area Name</label>
                      <input
                        type="text"
                        placeholder="Area Name"
                        {...register('areaName')}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Address</label>
                      <input
                        type="text"
                        placeholder="Address"
                        {...register("address", { 
                          required: true ,
                          minLength: {
                            value: 10,
                            message: "House / Building No. / Street Name must be minimum 10 characters",
                          },
                        })}

                      />
                      {errors.address?.type === "required" && (
                            <small className="text-danger error">
                              This is requiredd
                            </small>
                          )}
                          {errors.address && (
                            <small className="error">
                              {errors.address.message}
                            </small>
                          )}
                    </div>
                  </div>
                
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">PIN</label>
                      <input
                        type="text"
                        placeholder="PIN"
                        minLength={6}
                        maxLength={6}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        {...register('pin',{
                          pattern: {
                            value: /^\d+$/,
                            message: "PIN code must be number.",
                          }}
                        
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">State</label>
                      <input
                        type="text"
                        placeholder="State"
                        {...register('state')}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">City</label>
                      <input
                        type="text"
                        placeholder="City"
                        {...register('city')}
                      />
                    </div>
                  </div>
                  
                  <div className="col-12">
                    <button
                      className="box-hover custom-btn-cls ms-0 mt-md-0 mt-3 donate-now-clicked-form"
                    >
                      submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateProfile;
