import React, { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../components/common/PageHeader";
import AppContext from "../context/AppContext";
import MetaData from "../helpers/MetaData";
import { HiOutlineDownload } from "react-icons/hi";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import { useState } from "react";
import moment from "moment";
import fileDownload from "js-file-download";
import GoToTop from "../helpers/GoToTop";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Tooltip from "@mui/material/Tooltip";
import DonationTable from "../components/common/DonationTable";
import DownloadTenbe from "../components/10BE/downloadTenbe";

const DmsAllTransactions = () => {
  console.log(`DmsAllTransactions.jsx`);
  const navigate = useNavigate();
  const { donor } = useContext(AppContext);
  const [donations, setDonations] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [paginationDisable, setPaginationDisable] = useState(false)
  // console.log(donor)

  const fetchAllDonations = async (id) => {
    try {
      setPaginationDisable(true)
      const response = await ApiService.fetchAllDonations(id, page);
      // console.log(response)
      if (response) {
        setDonations(response.donations);
        setPage(+response.page);
        setTotalPages(+response.totalPages);
        setPaginationDisable(false)

      }
    } catch (err) {
      setPaginationDisable(false)

      console.log(err);
    }
  };

  useEffect(() => {
    if (donor) {
      fetchAllDonations(donor.id);
    }
  }, [donor]); //here remove donor depedency

  useEffect(() => {
    if (donor) {
      fetchAllDonations(donor.id);
    }
  }, [page])

  return (
    <>
      <GoToTop />
      <MetaData title="Dashboard - ISKCON of Bhiwandi" />

      <section className="dashboard-table">
        <div className="container">
          <div className="top-head pb-3 pb-lg-0 heading">
            <h2 className="head-1"></h2>
            <div className="d-flex justify-content-center flex-wrap">
              <DownloadTenbe />
              <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/Service-requests")}
              >
                Service Requests
              </button>
              <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/join-family")}
              >
                Join Family
              </button>
              <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/all-transactions")}
              >
                All Transactions
              </button>
              <button
                className="custom-btn-cls box-hover"
                onClick={() => navigate("/donation-form")}
              >
                Donate more
              </button>
            </div>
          </div>

          {/* <div className="donation-table">
            <h5 className="table-title mt-lg-5 mt-3 mb-lg-3 mb-0">Donations</h5>

            <div className="table-heading py-4 d-none d-lg-block">
              <div className="row">
                <div className="col-lg-2">
                  <h5>Date</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Status</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Name</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Amount</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Download</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Dispatch Details</h5>
                </div>
              </div>
            </div>

            <div className="table-content pt-2 pt-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-6 ">
                  <div className="table-row">
                    {donations?.map((donation, i) => (
                      <div key={i} className="row align-items-center py-3">
                        <div className="col-lg-2">
                          <div className="form-field">
                            <h5 className="form-title">Date :</h5>
                            <p id="donation-date">
                              {moment(donation.donation_date).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="form-field">
                            <h5 className="form-title">Status :</h5>
                            <p
                              id="donation-status"
                              className={`text-uppercase success`}
                            >
                              Success
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="form-field">
                            <h5 className="form-title">Name :</h5>
                            <p id="donation-name">{donation?.donor_name}</p>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="form-field">
                            <h5 className="form-title">Amount :</h5>
                            <p id="donation-amount">{donation?.amount}</p>
                          </div>
                        </div>

                        <div className="col-lg-2">
                          <div className="form-field flex-column d-inline-flex">
                            <>
                              <h5 className="form-title">Download :</h5>
                              <div className="download-btns">
                                <button
                                  className="m-0 custom-btn-cls box-hover "
                                  disabled
                                >
                                  80G <HiOutlineDownload />
                                  <span>Download 80G certificate a</span>
                                </button>

                                <button
                                  disabled
                                  className="m-0 ms-2 custom-btn-cls box-hover"
                                >
                                  Annadaan <HiOutlineDownload />
                                  <span>Annadaan certificate</span>
                                </button>
                              </div>
                            </>
                          </div>
                        </div>

                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <DonationTable
            donations={donations}
            title="Donations"
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            paginationDisable={paginationDisable}
            donor={donor}
          />

        </div>
      </section>
    </>
  );
};

export default DmsAllTransactions;
