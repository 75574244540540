import React from "react";
import DonationTableRow from "./DonationTableRow";

const DonationTable = ({ donations, title, totalPages, page, setPage, paginationDisable, donor }) => {
  //console.log("paginationDisable", paginationDisable, page)
  return (
    <div className="donation-table">
      <h5 className="table-title mt-lg-5 mt-3 mb-lg-3 mb-0">{title}</h5>

      <div className="table-heading py-4 d-none d-lg-block">
        <div className="row">
          <div className="col-lg-2">
            <h5>Date</h5>
          </div>
          <div className="col-lg-2">
            <h5>Purpose</h5>
          </div>

          <div className="col-lg-2">
            <h5>Status</h5>
          </div>
          <div className="col-lg-2">
            <h5>Name</h5>
          </div>
          <div className="col-lg-2">
            <h5>Amount</h5>
          </div>
          <div className="col-lg-2">
            <h5>Download</h5>
          </div>
          {/* <div className="col-lg-2">
                  <h5>Dispatch Details</h5>
                </div> */}
        </div>
      </div>

      <div className="table-content pt-2 pt-lg-0">
        <div className="row">
          <div className="col-lg-12 col-md-6 ">
            <div className="table-row">
              {donations?.map((donation, i) => (
                <DonationTableRow key={i} donation={donation} />
              ))}
              {!donor && (
                <center><h5 className="text-muted p-4">Unable to show details</h5></center>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Pagination */}
      {
        (donations && donations.length > 0) &&
        <nav>
          <ul className="pagination">
            <li className={`page-item ${page === 1 ? "disabled" : paginationDisable ? "disabled" : ""}`}>

              <button
                type="button"
                className="page-link arrow"
                onClick={() => setPage(page - 1)}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 256 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                </svg>
              </button>
            </li>
            <li className={`page-item ${page === 1 ? "d-none" : ""}`}>
              <a className="page-link" onClick={() => { if (!paginationDisable) { setPage(page - 1) } }}>
                {page - 1}
              </a>
            </li>
            <li className="page-item">
              <a className="page-link active">{page}</a>
            </li>
            <li className={`page-item ${page === totalPages ? "d-none" : ""}`}>
              <a className="page-link" onClick={() => { if (!paginationDisable) { setPage(page + 1) } }}>
                {page + 1}
              </a>
            </li>
            <li className={`page-item ${page === totalPages ? "disabled" : paginationDisable ? "disabled" : ""}`}>


              <button
                type="button"
                className="page-link arrow"
                onClick={() => setPage(page + 1)}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 256 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      }
    </div>
  );
};

export default DonationTable;
