import { useEffect, useRef, useState, useCallback } from "react";
import PageHeader from "../components/common/PageHeader";
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { useForm } from "react-hook-form";
import ApiService from "../api/ApiService";
import notification from "../helpers/notification";
import IskconStoryVideo from "../components/about/IskconStoryVideo";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext } from "react";
import AppContext from "../context/AppContext";

const Contact = () => {
  const { user } = useContext(AppContext);
  const [top, setTop] = useState(0);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const recaptchaRef = useRef();

  const connectSubmit = async (data) => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      data.token = token;

      const res = await ApiService.newConnect(data, token);
      notification("success", "Hare Krishna! Your form has been accepted.");
      reset();
      recaptchaRef.current.reset();
    } catch (err) {
      console.log(err);
      notification("error", err.response.data.message);
    }
  };

  const [meta, setMeta] = useState(null);
  const fetchMeta = async (pagename) => {
    try {
      let res = await ApiService.fetchMetaData(pagename);
      setMeta(res);
    } catch (error) {
      console.log(error);
    }
  };
  const festRef = useCallback((node) => {
    if (node) {
      setTop(node.offsetTop - 45);
    }
  }, []);
  useEffect(() => {
    if (user) {
      reset({
        firstname: user.name.split(" ")[0],
        lastname: user.name.split(" ")[1],
        phone: user.phone,
        email: user.email,
        address: user.address,
      });
    }
  }, [user]);

  useEffect(() => {
    fetchMeta("connect");
  }, []);

  return (
    <>
      <MetaData title="Connect - ISKCON of Bhiwandi" />
      <PageHeader title="Connect" imgSrc="/images/page-header/13.webp" />
      <section className="connect-heading-sec" ref={festRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="heading">
                <h2 className="head-1">HERE TO SERVE YOU</h2>
                <p className="b-line">
                  We are so happy that you took the time to visit us and we
                  welcome you to the ISKCON Bhiwandi devotional family. Please
                  write in to us for any questions, to offer your services to
                  Sri Sri Radha Madhava, for information on any of our
                  services and courses, or any suggestions. We are here to serve
                  you, Hare Krishna!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mt-5 mt-lg-0 order-1 order-lg-0">
              <div className="contact-address">
                <div className="contact-logo">
                  <figure className="mb-0">
                    <img src="/images/common/logo.png" alt="" />
                  </figure>
                  <h5>Sri Sri Radha Madhava Temple </h5>
                  <h5>ISKCON Bhiwandi</h5>
                </div>
                <p>
                  Hare Krishna hill, Mansarovar,
                  <span className="d-block">Bhiwandi - 421302, Maharashtra.</span>
                </p>
                <p>
                  <span className="key">Phone:</span>
                  <a href="tel:9930398330" className="value">

                    +91-9930398330
                  </a>
                  <a href="tel:9175263178" className="value">

                    +91-9175263178
                  </a>
                </p>
                <p>
                  <span className="key">Email:</span>
                  <a href="mailto:iskconbhiwandi@gmail.com" className="value">
                    iskconbhiwandi@gmail.com
                  </a>
                </p>

                <div className="mt-4">
                  <p>
                    <span className="key">Kaustubh Hari Das, Admin Head:</span>
                    <br />
                    <a href="mailto:support@iskconbhiwandi.org">support@iskconbhiwandi.org</a>
                  </p>
                  <div>
                    <a href="tel:7977909015">+917977909015</a>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-lg-6 order-0 order-lg-1">
              <div className="contact-form">
                <div className="form-title mb-5">
                  <h4>
                    contact <span>form</span>
                  </h4>
                </div>
                <form
                  className="order-0 order-lg-1"
                  onSubmit={handleSubmit(connectSubmit)}
                >
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      <div className="input-wrap">
                        <label htmlFor="firstname" className="imp">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="firstname"
                          {...register("firstname", { required: true })}
                          onKeyPress={(event) => {
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.firstname?.type === "required" && (
                          <span> First name is required </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-wrap">
                        <label htmlFor="lastname" className="imp">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="lastname"
                          {...register("lastname", { required: true })}
                          onKeyPress={(event) => {
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.lastname?.type === "required" && (
                          <span> Last name is required </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-wrap">
                        <label htmlFor="email" className="imp">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          {...register("email", {
                            required: true,
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email",
                            },
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <span> Email address is required </span>
                        )}
                        {errors.email && (
                          <small className="text-danger error">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-wrap">
                        <label htmlFor="phone" className="imp">
                          Phone
                        </label>
                        <input
                          type="tel"
                          id="phone"
                          minLength={10}
                          maxLength={13}
                          {...register("phone", {
                            required: true,
                            minLength: 10,
                            maxLength: 13,
                          })}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.phone?.type === "required" && (
                          <span> Please Enter Valid Number </span>
                        )}
                        {errors.phone?.type === "maxLength" && (
                          <span> Please Enter Valid Number </span>
                        )}
                        {errors.phone?.type === "minLength" && (
                          <span> Please Enter Valid Number </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-wrap">
                        <label htmlFor="message" className="imp">
                          Your Message
                        </label>
                        <textarea
                          id="message"
                          {...register("message", { required: true })}
                        ></textarea>
                        {errors.message?.type === "required" && (
                          <span> Message is required </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="type_checkbox mt-3">
                        <input
                          type="checkbox"
                          id="isAgree"
                          {...register("isAgree")}
                          defaultChecked={true}
                        />
                        <label htmlFor="isAgree">
                          I authorize ISKCON to send notification via SMS/RCS/Whatsapp/Email.
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        ref={recaptchaRef}
                        size="invisible"
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <button className="box-hover custom-btn" type="submit">
                        submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <IskconStoryVideo
        imgSrc="/images/connect/be-closer-to-krishna.webp"
        videoLink="https://www.youtube.com/watch?v=AYz7ffeR95M"
        smallText="FIVE SIMPLE RITUALS"
        bigText="TO BE CLOSER TO KRISHNA"
      />
      <GoToTop top={top} />
    </>
  );
};

export default Contact;
