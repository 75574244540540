import moment from 'moment'
import React, { useState } from 'react'
import ApiService from '../../api/ApiService';
import fileDownload from 'js-file-download';
import { useNavigate } from 'react-router-dom';

const DonationTableRow = ({ donation }) => {

  const navigate = useNavigate()

  const [is80GLoading, setIs80GLoading] = useState(false);
  const [isReceiptLoading, setIsReceiptLoading] = useState(false);
  const [isCertificateLoading, setIsCertificateLoading] = useState(false);

  const downloadDmsReceipt = async (data) => {
    try {

      const response = await ApiService.downloadDmsReceipt(data.id, {
        responseType: 'arraybuffer',
      });

      // OPEN IN NEW TAB
      const blob = new Blob([response], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, '_blank');

      // DIRECT DOWNLOAD
      // Create a link to download the file
      // const link = document.createElement('a');
      // link.href = pdfUrl;
      // link.download = `receipt-${data.id}.pdf`; // Specify the file name
      // document.body.appendChild(link);
      // link.click();

      // // Clean up the URL and remove the link element
      // URL.revokeObjectURL(pdfUrl);
      // document.body.removeChild(link);

      setIsReceiptLoading(false);
      setIs80GLoading(false);

    } catch (error) {
      console.log("Unable to download Receipt: ", error);
    }
  }

  const handleDownload = async ({ type, data }) => {
    try {

      switch (type) {
        case 'annadaan': {
          setIsCertificateLoading(true);
          const file = await ApiService.downloadAnnadaanCertificate(data.ref_id);
          fileDownload(file, "Annadaan-certificate.jpg");
          setIsCertificateLoading(false);
          break;
        }
        case '80g': {
          setIs80GLoading(true)
          // IF NOT VALID REDIRECT TO UPDATE DETAILS PAGE
          if (data.valid) {
            downloadDmsReceipt(data);
          } else {


            const _donation = await ApiService.fetchDonationByTransactionId(data.transaction_id);
            const donationType = donation?.donationType?.slug === "general-donation" ? 'donation' : "campaign"
            navigate(`/update-details?donation-type=${donationType}`, { state: { donorId: data.id }, })
            setIs80GLoading(false)
            


            
            // ELSE DIRECT DOWNLOAD
            // const _donation = await ApiService.fetchDonationByTransactionId(data.transaction_id);

            // const fetch_data = {
            //   phone: data.phone,
            // }

            // const dmsProfiles = await ApiService.fetchDonor(fetch_data);

            // const dmsProfiles_donor_fetch = dmsProfiles.donor.legal_name === data.name && dmsProfiles.donor.phone === data.phone ? dmsProfiles.donor : dmsProfiles.profiles.find(donor => donor.legal_name === data.name && donor.phone === data.phone);

            // //return  console.log("dmsProfiles_donor_fetch", dmsProfiles_donor_fetch)

            // if (dmsProfiles_donor_fetch) {
            //   // TODO: handle subscription and life membership
            //   const donationType = donation?.donationType?.slug === "general-donation" ? 'donation' : "campaign"
            //   let updateFormUrl = `/update-details?update-pan=${_donation._id}&uid=${data.id}&donation-type=${donationType}`;
            //   setIs80GLoading(false)
            //   navigate(updateFormUrl)
            //  // console.log("ics donor", updateFormUrl)
            // }
          }
          break;
        }
        case 'receipt': {
          setIsReceiptLoading(true);
          downloadDmsReceipt(data);
          break;
        }
        default: {
          console.log("Certificate type not match");
        }
      }

    } catch (error) {
      console.log(error)
    }
  }

 // console.log("donation", donation)

  return (
    <div className="row align-items-center py-3">
      <div className="col-lg-2">
        <div className="form-field">
          <h5 className="form-title">Date :</h5>
          <p id="donation-date">
            {moment(donation.donation_date).format("DD/MM/YYYY")}
          </p>
        </div>
      </div>
      <div className="col-lg-2">
        <div className="form-field">
          <h5 className="form-title">Purpose :</h5>
          <p id="donation-date">{donation?.scheme_type?.name}</p>
        </div>
      </div>
      <div className="col-lg-2">
        <div className="form-field">
          <h5 className="form-title">Status :</h5>
          <p
            id="donation-status"
            className={`text-uppercase success`}
          >
            Success
          </p>
        </div>
      </div>
      <div className="col-lg-2">
        <div className="form-field">
          <h5 className="form-title">Name :</h5>
          <p id="donation-name">{donation?.donor_name}</p>
        </div>
      </div>
      <div className="col-lg-2">
        <div className="form-field">
          <h5 className="form-title">Amount :</h5>
          <p id="donation-amount">{donation?.amount}</p>
        </div>
      </div>

      <div className="col-lg-2">
        <div className="form-field flex-column d-inline-flex">
          <>
            <h5 className="form-title">Download :</h5>
            <div className="download-btns">

              {/* 80G button */}
              <button
                onClick={() => handleDownload({
                  type: '80g',
                  data: {
                    id: donation.id,
                    valid: donation?.tax_receipt?.valid,
                    transaction_id: donation.transaction_reference,
                    phone: donation.phone,
                    name: donation.donor_name
                  }
                })}
                className=" ms-0 custom-btn-cls box-hover"
                disabled={is80GLoading}
              >
                {
                  is80GLoading &&
                  <div className="spinner-border text-light me-1"></div>
                }
                80G
                <span>Download 80G</span>
              </button>

              {/* Receipt Button */}
              {
                !donation?.tax_receipt?.valid &&
                <button
                  onClick={() => handleDownload({
                    type: "receipt",
                    data: {
                      id: donation.id
                    }
                  })}
                  className=" ms-0 custom-btn-cls box-hover"
                  disabled={isReceiptLoading}
                >
                  {
                    isReceiptLoading &&
                    <div className="spinner-border text-light me-1"></div>
                  }
                  Receipt
                  <span>Download Receipt</span>
                </button>
              }

              {/* Annadaan Button */}
              {/* {donation?.scheme_type?.name === "Annadaan" && (
                            <button
                              disabled={isCertificateLoading}
                              className="ms-0 custom-btn-cls box-hover"
                              onClick={() => handleDownload({
                                type: 'annadaan',
                                data: {
                                  ref_id: donation.transaction_reference,
                                }
                              })}
                            >
                                {
                                    isCertificateLoading &&
                                    <div className="spinner-border text-light me-1"></div>
                                }
                              Annadaan
                              <span>Annadaan certificate</span>
                            </button>
                          )} */}
            </div>
          </>
        </div>
      </div>

      {/* <div className="col-lg-2"></div> */}
    </div>
  )
}

export default DonationTableRow