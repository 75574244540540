import React, { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../components/common/PageHeader";
import AppContext from "../context/AppContext";
import MetaData from "../helpers/MetaData";
import { useState } from "react";
import GoToTop from "../helpers/GoToTop";
import ApiService from "../api/ApiService";
import axios from "axios";
import notification from "../helpers/notification";
import moment from "moment";
import DownloadTenbe from "../components/10BE/downloadTenbe";

const ServiceRequests = () => {
  const { setUser, setToken, user, donor } = useContext(AppContext);
  const navigate = useNavigate();

  const [ics_data, setICSdata] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [icsaddr, setICSdataAddr] = useState([]);

  const [serviceRequests, setServiceRequests] = useState([]);

  const fetchFromIcs = async () => {
    const ics_id = localStorage.getItem("ics_id");

    const response = await ApiService.getServiceData(ics_id);

    //console.log(response.rows);
    setICSdata([...response.rows].reverse());
  };


  const ics_id = localStorage.getItem("ics_id");

  const FetchIcsUser = async () => {
    const icsmob = localStorage.getItem("ics_number");

    const response = await ApiService.getIcsData(icsmob);

    setICSdataAddr(response.data);
    //console.log(response.data);
  };

//   const HandelServiceRequest = async (id, catid) => {
//     console.log(id);
//     let ics_id = localStorage.getItem("ics_id");
//     try {
//       let handelRequest = {
//         icsId: ics_id,
//         id: id,
//         categoryId: catid,
//         status: "PURGED",
//         oper: "edit",
//       };

//       const response = await ApiService.handelservRequest(handelRequest);

//       if (response.message === "Your request has been cancelled") {
//         notification("success", response.message);
//       }

//       console.log(response.message);
//     } catch (error) {
//       console.error(error);
//     }
//   };

  const HandelServiceRequest = async (id, profile_id) => {
   // return console.log("id, profile_id", id+'----'+profile_id)
    try {

      const response = await ApiService.updateServiceRequestStatus({
        profile_id: profile_id,
        status: false,
        enquiry_id: id,
      });
      if (response.message) {
        notification("success", response.message);
        fetchServiceRequests(donor.profile_id);
      }

    } catch (error) {
      console.error(error);
    }
  };

  const fetchServiceRequests = async (id) => {
    try {
      const response = await ApiService.getServiceRequests(id);
      //console.log(response);
      setServiceRequests(response.service_requests);

    } catch (error) {
      console.error(error);
    }
  }

  // useEffect(() => {
  //   fetchFromIcs();
  //   FetchIcsUser();
  // }, []);

  useEffect(() => {
    if(donor){
      fetchServiceRequests(donor.profile_id);
    }
  },[donor]);

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setIsButtonDisabled(true);
    // }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds

    const timer = setTimeout(() => {
      setIsButtonDisabled(true);
    }, 20000); // 20 seconds in milliseconds

    return () => {
      clearTimeout(timer);
    };
  }, []); // Empty dependency array to run the effect only once

  return (
    <>
      <GoToTop />
      <MetaData title="Dashboard - ISKCON of Bhiwandi" />
      <section className="dashboard-table">
        <div className="container">
          <div className="top-head pb-3 pb-lg-0 heading">
            <h2 className="head-1"></h2>
            <div className="d-flex justify-content-center flex-wrap">
              <DownloadTenbe />
              <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/service-requests")}
              >
                Service Requests
              </button>
              <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/join-family")}
              >
                Join Family
              </button>
              <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/all-transactions")}
              >
                All Transactions
              </button>
              <button
                className="custom-btn-cls box-hover"
                onClick={() => navigate("/donation-form")}
              >
                Donate more
              </button>
         
            </div>
          </div>

          <div className="life-membership-table">
            <h5 className="table-title mt-lg-5 mt-3 mb-lg-3 mb-0">
              Service Requests
            </h5>
            <div className="table-heading py-lg-4 py-2 d-none d-lg-block">
              <div className="row justify-content-evenly align-items-center">
                <div className="col-lg-2">
                  <h5>Requested By</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Service</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Address</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Created On</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Status</h5>
                </div>
                <div className="col-lg-2">
                  <h5>Action</h5>
                </div>
              </div>
            </div>

            <div className="table-content pt-2 pt-lg-0">
              <div className="row">
                {/* {ics_data.map((item, id) => (
                  <div key={id} className="col-lg-12 col-md-6 ">
                    <div className="table-row py-lg-4 py-3">
                      <div className="row align-items-center">
                        <div className="col-lg-2">
                          <div className="form-field">
                            <h5 className="form-title">Requested By :</h5>
                            <p id="donation-name">{item.cell[2]}</p>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="form-field">
                            <h5 className="form-title">Service :</h5>
                            <p id="donation-name">
                              {
                                item.cell[4]
                                // item.cell[3] == 1 ? 'Speak to a senior priest' :
                                // item.cell[3] == 2 ? 'Distribute Prasad on your Special Day' :
                                // item.cell[3] == 3 ? 'Receive Krishna Prasad' :
                                // item.cell[3] == 4 ? 'Sankirtan at Home' :
                                // item.cell[3] == 5 ? 'Download Spiritual Literature' : ''
                              }
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="form-field">
                            <h5 className="form-title">Address :</h5>
                            <p id="donation-name">
                              {icsaddr?.filter((dat) => dat.id == item.cell[1])
                                .map((addr, id) => (
                                  <>
                                    <p>
                                      {addr.address.line1 +
                                        "" +
                                        addr.address.line2}
                                    </p>
                                    <p>
                                      {addr.address.line3 +
                                        "" +
                                        addr.address.state}
                                    </p>
                                    <p>{addr.address.pincode}</p>
                                  </>
                                ))}
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-2">
                          <div className="form-field">
                            <h5 className="form-title">Created On :</h5>
                            <p id="donation-name">{item.cell[0]}</p>
                          </div>
                        </div>

                        <div className="col-lg-2">
                          <div className="form-field">
                            <h5 className="form-title">Status :</h5>
                            <p id="donation-name">
                              {item.cell[13] === "PURGED"
                                ? "Cancelled"
                                : item.cell[13]}
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-2">
                          <div className="form-field">
                            <h5 className="form-title">Action :</h5>
                            <p id="donation-name">
                              {item.cell[13] === "OPEN" ? (
                                <button
                                  className="custom-btn-cls box-hover text-uppercase"
                                  onClick={() =>
                                    HandelServiceRequest(item.id, item.cell[3])
                                  }
                                  disabled={false}
                                >
                                  Cancel Now
                                </button>
                              ) : (
                                <button
                                  className="custom-btn-cls  text-uppercase"
                                  onClick={() =>
                                    HandelServiceRequest(item.id, item.cell[3])
                                  }
                                  disabled={true}
                                  style={{ backgroundColor: "grey" }}
                                >
                                  Closed
                                </button>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}

                {
                    serviceRequests?.map((enquiry, id) => (
                    <div key={id} className="col-lg-12 col-md-6 ">
                        <div className="table-row py-lg-4 py-3">
                            <div className="row align-items-center">
                                <div className="col-lg-2">
                                    <div className="form-field">
                                        <h5 className="form-title">Requested By :</h5>
                                        <p id="donation-name">{enquiry?.donor_name}</p>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-field">
                                        <h5 className="form-title">Service :</h5>
                                        <p id="donation-name">{enquiry?.category}</p>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-field">
                                        <h5 className="form-title">Address :</h5>
                                        <p id="donation-name">{enquiry?.address}</p>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-field">
                                        <h5 className="form-title">Created On :</h5>
                                        <p id="donation-name">{moment(enquiry?.createdAt).format('DD-MM-YYYY')}</p>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-field">
                                        <h5 className="form-title">Status :</h5>
                                        <p id="donation-name">{enquiry?.status ? 'OPEN' : 'Cancelled'}</p>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                <div className="form-field">
                                    <h5 className="form-title">Action :</h5>
                                    <p id="donation-name">
                                    {enquiry?.status ? (
                                        <button
                                        className="custom-btn-cls box-hover text-uppercase"
                                        onClick={() => HandelServiceRequest(enquiry?.id, enquiry?.profile_id)}
                                        disabled={false}
                                        >
                                        Cancel Now
                                        </button>
                                    ) : (
                                        <button
                                        className="custom-btn-cls  text-uppercase"
                                        disabled={true}
                                        style={{ backgroundColor: "grey" }}
                                        >
                                        Closed
                                    </button>
                                    )}
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))
                }
                {!donor && (
                <center><h5 className="text-muted p-4">Unable to show details</h5></center>
              )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceRequests;
