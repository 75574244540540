import React ,{ useState, useCallback, useEffect }from 'react'
import { useLocation } from 'react-router-dom';
import MetaData from '../helpers/MetaData'
import PageHeader from '../components/common/PageHeader'
import GoToTop from "../helpers/GoToTop";
import ApiService from "../api/ApiService";

const TenbeThankyou = () => {
    const [top, setTop] = useState(0);
  const location = useLocation();
  const phone = location.state?.phone;
  const pan = location.state?.pan;

    const updateTenbedonor = async () => {
          try {
              let data = { phone: phone, pan:pan }
            await ApiService.updateTenbedonor(data);
    
          } catch (error) {
              console.error("Error fetching donor list:", error);
          }
      };

  const sectionRef = useCallback((node) => {
        if (node) {
            let headerHeight;
            if (window.innerWidth > 991) {
                headerHeight = 140;
            } else if (window.innerWidth > 576) {
                headerHeight = 80;
            } else {
                headerHeight = 73;
            }
            setTop(node?.offsetTop - headerHeight);
        }
    });

     useEffect(() => {
        updateTenbedonor()
        }, [phone]);


    return (
        <>
            <MetaData title="Thank You - ISKCON of Bhiwandi" />
            <PageHeader title="Thank You" imgSrc="/images/page-header/25.jpg" />
            <section className="thank-you-container" ref={sectionRef}>
                <div className="container">
                    <div id="scrollToThankYouPage" className="row align-items-center justify-content-between">
                        <div className="text-center">
                            <h2 className='text-center'>Your Form 10BE are successfully downloaded</h2>
                            <p className='mt-0' style={{ textAlign: 'center' }}>Form 10BE for available for period April 2024 - September 2024</p>
                            <p className='mt-0' style={{ textAlign: 'center' }}>Form 10BE for period October 2024 - March 2025   
                            will be available in May 2025</p>

                            <p className='mt-0' style={{ textAlign: 'center' }}>
                            In case you have any queries, please send an e-mail to
                            <a href='mailto:support@iskconbhiwandi.org' > support@iskconbhiwandi.org</a> 
                            </p>


                            <p className='mt-0' style={{ textAlign: 'center' }}>
                                Hare Krishna.
                            </p>
                        </div>
                    </div>
                </div>
            </section >
            <GoToTop top={top} />
        </>
    );
};

export default TenbeThankyou;

