import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import ApiService from "../api/ApiService";
import PageHeader from "../components/common/PageHeader";
import MetaData from "../helpers/MetaData";
import notification from "../helpers/notification";
import moment from "moment";
import axios from "axios";
import { useCallback } from "react";
import GoToTop from "../helpers/GoToTop";
import { useFieldArray, useForm } from "react-hook-form";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import { Modal } from 'react-bootstrap'
import fileDownload from "js-file-download";


const DmsUpdateDetails = () => {
  const { fetchUser, fetchDonor, user } = useContext(AppContext);
  const search = useLocation().search;
  const relationValues = [{ name: "", age: "", relation: "" }];
  const donationId = new URLSearchParams(search).get("update-pan");
  const type = new URLSearchParams(search).get("donation-type");
  const location = useLocation();
  const dms_donation_Id = location.state?.donorId;
  const redirect = new URLSearchParams(search).get("redirect");
  const testDate = new URLSearchParams(search).get("testDate")
  const navigate = useNavigate();

  //console.log("dms_donation_Id", dms_donation_Id)

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      state: "",
      city: "",
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "familyMember",
  });

  const [donationDetails, setDonationDetails] = useState(null);
  const [states, setStates] = useState([]);
  const [state, setState] = useState("");
  const [top, setTop] = useState(0);
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showFYModal, setShowFYModal] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [donorData, setDonorData] = useState(null);

  const formRef = useCallback((node) => {
    if (node !== null) {
      setTop(node.offsetTop);
    }
  });

  const fetchStateCity = async (pin) => {
    try {
      let url = `https://api.postalpincode.in/pincode/${pin}`;

      let config = {
        method: "GET",
        url,
      };
      const { data } = await axios(config);

      let cityVal = '';
      if (data[0].PostOffice.length > 1) {
        data[0].PostOffice.map(ct => {
          if (ct.Block !== 'NA') {
            return cityVal = ct.Block
          }
        })
      } else {
        cityVal = data[0].PostOffice[0].Block;
      }

      if (data[0].Status === "Success") {
        setValue("city", cityVal !== '' ? cityVal : data[0].PostOffice[0].District);
        setValue("state", data[0].PostOffice[0].State);
      } else {
        notification("warning", "Enter valid PIN code!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkValidPin = async (pin) => {
    try {
      let url = `https://api.postalpincode.in/pincode/${pin}`;
      let config = {
        method: "GET",
        url,
      };
      const { data } = await axios(config);

      if (data[0].Status === "Error") {
        notification("warning", "Enter valid PIN code!");
      }
    } catch (err) {
      console.log(err);
    }
  };


  const fetchDonationDms = async () => {
    try {
      const response = await ApiService.fetchDonationbyId(dms_donation_Id);
      //console.log("response", response)

      const respon = {
        scheme_type: response.scheme_type?.name,
        amount: response.amount,
        name: response.donor_name,
        phone: response.phone,
        email: response.email,
        pan: response.pan,
        address_line_1: response.address_line_1,
        address_line_2: response.address_line_2,
        landmark: response.landmark,
        pin: response.pincode,
        city: response.city,
        state: response.state,
        transaction_id: response.transaction_reference,
        donor_id: response.donor_id,
      };
      setDonorData(respon);
      //console.log(respon);
      // if (response.donor) {
      //   setDonorData(response.donor);
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStates = async () => {
    try {
      var config = {
        method: "GET",
        url: "https://api.countrystatecity.in/v1/countries/IN/states",
        headers: {
          "X-CSCAPI-KEY": process.env.REACT_APP_CSCKEY,
        },
      };

      let res = await axios(config);

      let sortedArray = res.data.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });

      setStates(
        sortedArray.map((item) => ({ value: item.iso2, label: item.name }))
      );
      // console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  //   const handleChange = (e) => {
  //     setDonationDetails((prev) => ({
  //       ...prev,
  //       [e.target.name]: e.target.value,
  //     }));
  //   };

  //   const fetchDonationDetails = async (e) => {
  //     try {
  //       let data = {
  //         id: donationId,
  //         type,
  //       };
  //       const res = await ApiService.fetchDonationDetails(data);
  //       // Financial Year Logic Starts
  //       let currentYear = moment().get('year')
  //       let today = moment()
  //       let fyStart = moment(`01-04-${currentYear - 1}`, 'DD-MM-YYYY')
  //       let fyEnd = moment(`01-04-${currentYear}`, 'DD-MM-YYYY')
  //       let fyDeadlineStart = moment(`01-04-${currentYear}`, 'DD-MM-YYYY')
  //       let fyDeadlineEnd = moment(`15-04-${currentYear}`, 'DD-MM-YYYY')
  //       if (moment(res.createdAt).isSameOrAfter(fyStart) && moment(res.createdAt).isBefore(fyEnd)) {
  //         // Between FY Start && FY End
  //         console.log('Between FY Start && FY End')
  //         if (moment(today).isBefore(fyDeadlineStart)) {
  //           // Allow normally
  //           console.log("Allow normally")
  //           setShowForm(true)
  //         } else if (moment(today).isSameOrAfter(fyDeadlineStart) && moment(today).isSameOrBefore(fyDeadlineEnd)) {
  //           // Allow normally with popup
  //           console.log("Allow normally with popup")
  //           setShowForm(true)
  //           setShowFYModal(true)
  //         } else {
  //           // Don't Allow
  //           console.log("Don't Allow")
  //           notification(
  //             "error",
  //             "You cannot create 80G Certificate"
  //           );
  //           // navigate('/')
  //         }
  //       } else if (moment(res.createdAt).isAfter(fyEnd)) {
  //         // Next FY
  //         console.log("Next FY")
  //         setShowForm(true)
  //       } else {
  //         // FY Passed Don't Allow, Too Late
  //         console.log("FY Passed Don't Allow, Too Late")
  //         notification(
  //           "error",
  //           "You cannot create 80G Certificate"
  //         );
  //         // navigate('/')
  //       }
  //       // Financial Year Logic Ends
  //       // console.log(res)
  //       if (res.eightyGGenerated) {
  //         notification(
  //           "error",
  //           "80G already generated"
  //         );

  //         return navigate(`/80g-download/${res._id}`, {
  //           replace: true
  //         }),


  //           setTimeout(() => {
  //             window.location.href = '/all-transactions'
  //             // notification(
  //             //   "error",
  //             //   "PAN Not Updated, please try after sometimes"
  //             // );
  //           }, 1000);




  //       }
  //       reset({
  //         ...res,
  //         dob: moment(res.dob).format("DD/MM/YYYY"),
  //         country: "India"
  //       });
  //       //console.log("Current Values After Donation fetch: " , res , getValues())
  //       //setDonationDetails(res);

  //       if (res?.pan && res?.areaName && res?.streetName && res?.pin && type === "lifeMembership") {
  //         if (redirect) {
  //           navigate(redirect);
  //         } else {
  //           // navigate(`/thank-you/${res?._id}/${type}`);
  //           navigate(`/all-transactions`);
  //         }
  //       }

  //     } catch (err) {
  //       console.log(err);
  //       //console.log(err.response.status)
  //       notification(
  //         "error",
  //         "You cannot create 80G Certificate"
  //       );
  //     }
  //   };

  //   const resetDetails = () => {
  //     if (donationDetails) {
  //       // reset({
  //       //   ...donationDetails,
  //       //   dob: moment(donationDetails?.dob).format("DD/MM/YYYY"),
  //       //   state,
  //       // });
  //       //console.log("Current Values After resetDetails: ", getValues())
  //     }
  //   };

  //   const handleFileDownload = async (id) => {
  //     try {
  //       const res = await ApiService.download80g(`${id}.pdf`);
  //       fileDownload(res, `${'Reciept-' + donationDetails?.eightyGNo}.pdf`)
  //     } catch (error) {
  //       console.log(error);
  //       // notification(
  //       //   "error",
  //       //   "Unable to download certificate, try again after some time!"
  //       // );
  //     }
  //   };

  const downloadDmsReceipt = async (id) => {
    try {

      const response = await ApiService.downloadDmsReceipt(id, {
        responseType: 'arraybuffer',
      });

      // OPEN IN NEW TAB
      const blob = new Blob([response], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, '_blank');

      // DIRECT DOWNLOAD
      // Create a link to download the file
      // const link = document.createElement('a');
      // link.href = pdfUrl;
      // link.download = `receipt-${data.id}.pdf`; // Specify the file name
      // document.body.appendChild(link);
      // link.click();

      // // Clean up the URL and remove the link element
      // URL.revokeObjectURL(pdfUrl);
      // document.body.removeChild(link);

      // setIsReceiptLoading(false);
      // setIs80GLoading(false);
      setLoading(false)
      navigate("/all-transactions")
      setShow(false)
    } catch (error) {
      console.log("Unable to download Receipt: ", error);
    }
  }


  const submitDetails = async (data) => {
    try {
      setLoading(true)
      data.transaction_id = donorData.transaction_id;
      data.donor_id = donorData.donor_id;
      const res = await ApiService.update80gDetails({ ...data });
      //console.log("Test ", res)
      if (res.message === '80G details updated successfully') {
        downloadDmsReceipt(dms_donation_Id)
      }
    } catch (error) {

    }
  }


  //   const submitDetails = async (data) => {
  //     // Loader for 80G Generation Form
  //     // return console.log(data)
  //     setLoading(true)
  //     try {
  //       data.userType = user ? 'user' : 'guest';
  //       //data.icsid = icsid;
  //       let details = {
  //         data,
  //         type,
  //       };



  //       const res = await ApiService.updatePanDetails(details);
  //       // console.log(res)
  //       // fetchDonationDetails();

  //       fetchDonor()

  //       setLoading(false)
  //       handleFileDownload(donationId)

  //       if (Object.keys(res)[0] === 'lifeMembership') {
  //         if (Object.values(res)[0].pan && Object.values(res)[0].streetName && Object.values(res)[0].areaName) {
  //           if (redirect) {
  //             navigate(redirect, {
  //               replace: true,
  //             });
  //           } else {
  //             navigate(`/all-transactions`);
  //             // navigate(`/thank-you/${donationDetails._id}/${type}`, {
  //             //   replace: true,
  //             // });
  //           }
  //         }
  //       } else {
  //         if (res?.donation.pan && res?.donation.streetName && res?.donation.areaName) {
  //           if (redirect) {
  //             navigate(redirect, {
  //               replace: true,
  //             });
  //           } else {
  //             navigate(`/all-transactions`);
  //             // navigate(`/thank-you/${donationDetails._id}/${type}`, {
  //             //   replace: true,
  //             // });
  //           }
  //         }
  //       }

  //     } catch (err) {
  //       setLoading(false)
  //       console.log(err)
  //     }
  //   }

  const handleSubmitDetails = async (data) => {
    try {
      //   if (!data.pan || !data.areaName || !data.streetName || !data.pin) {
      //     let unfilledField = `${!data.pan ? "PAN" : ""} ${!data.streetName ? "Street Name" : ""} ${!data.areaName ? "Area Name" : ""} ${!data.pin ? "PIN" : ""}`;
      //     notification("warning", `Please provide ${unfilledField}.`);
      //     return false;
      //   }
      setShow(true)

      // data.userType = user ? 'user' : 'guest';
      // let details = {
      //   data,
      //   type,
      // };

      // const res = await ApiService.updatePanDetails(details);
      // fetchDonationDetails();
      // if (user) {
      //   fetchUser();
      // }

      // if(Object.keys(res)[0] === 'lifeMembership') {
      //   if (Object.values(res)[0].pan && Object.values(res)[0].streetName && Object.values(res)[0].areaName) {
      //     navigate(`/thank-you/${donationDetails._id}/${type}`, {
      //       replace: true,
      //     });
      //   }
      // }else {
      //   if (res?.donation.pan && res?.donation.streetName && res?.donation.areaName) {
      //     navigate(`/thank-you/${donationDetails._id}/${type}`, {
      //       replace: true,
      //     });
      //   }
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const getState = () => {
    states.map((st) => {
      if (st.value === donorData?.state) {
        setState(st.label);
        return;
      }
    });
  };

  //   const fetchFromIcs = async () => {
  //     try {
  //         const response = await ApiService.getIcsData(user?.phone);
  //         console.log('=====================', response.data);

  //         if (Array.isArray(response.data)) {
  //             const dataItem = response.data.find(dat => dat.id == icsid);
  //             if (dataItem) {
  //                 console.log(dataItem.pan);
  //                 setPan(dataItem.pan)
  //                 reset({ 'pan': icspan });
  //             } else {
  //                 console.error('No data found with the specified id.');
  //             }
  //         } else {
  //             console.error('Invalid data format received.');
  //         }
  //     } catch (error) {
  //         console.error('Error fetching data:', error);
  //     }
  // };

  useEffect(() => {
    fetchDonationDms();
    fetchStates();
    // fetchDonationDetails();
  }, []);

  useEffect(() => {
    if (states.length) {
      getState();
    }
  }, [states]);

  //   useEffect(() => {
  //     if (state) {
  //       resetDetails();
  //     }
  //   }, [state]);

  // useEffect(() => {
  //   if (donorData) {
  //     reset({ ...getValues()})
  //     console.log("Current Values After Street update: ", getValues())
  //   }
  // }, [donorData])
  //console.log("donorData", donorData)
  useEffect(() => {
    if (donorData) {
      reset({
        donationFor: donorData?.scheme_type,
        amount: donorData?.amount,
        name: donorData?.name,
        phone: donorData?.phone,
        email: donorData?.email,
        pan: donorData?.pan,
        address_line_1: donorData?.address_line_1,
        address_line_2: donorData?.address_line_2,
        landmark: donorData?.landmark,
        pin: donorData?.pin,
        city: donorData?.city,
        state: donorData?.state
      });
    }
  }, [donorData]);

  return (
    <>
      <MetaData title="Update 80G Details - ISKCON of Bhiwandi" />
      <PageHeader
        title="80G Certificate"
        imgSrc="/images/page-header/17.webp"
      />
      {/* {
        showForm
        ? */}
      <section className="donation-form-sec" ref={formRef}>
        <div className="container">
          <div className="row d-flex justify-content-center mb-5">
            <div className="col-md-10">
              <div className="heading">
                <h2 className="head-1">80G Certificate</h2>
                <p className="b-line text-center">
                  If you login to dashboard you will be able to see your receipt anytime.
                </p>
              </div>
            </div>
          </div>
          {type === "lifeMembership" ? (
            <div className="form-wrap my-5">
              <form onSubmit={handleSubmit(handleSubmitDetails)}>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">Your First Name</label>
                      <input
                        type="text"
                        {...register("firstname")}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">Your Last Name</label>
                      <input
                        type="text"
                        {...register("lastname")}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">Date of Birth</label>
                      <input
                        type="text"
                        {...register("dob")}
                        defaultValue={moment(donationDetails?.dob).format(
                          "DD/MM/YYYY"
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">If you have an initiated name</label>
                      <input type="text" {...register("initiatedName")} />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">
                        Dedicate this donation to others name
                      </label>
                      <input type="text" {...register("dedicatorName")} />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">Your E-mail ID</label>
                      <input
                        type="text"
                        {...register("email")}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">Contact No</label>
                      <input
                        type="text"
                        {...register("phone")}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">PAN </label>
                      <input
                        type="text"
                        style={{ textTransform: "uppercase" }}
                        maxLength={10}
                        {...register("pan", {
                          required: true,
                          pattern: {
                            value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                            message: "Please enter valid PAN",
                          },
                        })}
                        name="pan"
                        placeholder="PAN"
                      />
                      {errors.pan?.type === "required" && (
                        <small className="text-danger error">
                          This is required
                        </small>
                      )}
                      {errors.pan && (
                        <small className="text-danger error">
                          {errors.pan.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">Company Name</label>
                      <input type="text" {...register("companyName")} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">House / Building No. / Street Name</label>
                      <input
                        type="text"
                        {...register("streetName", {
                          required: true,
                          minLength: {
                            value: 10,
                            message: "House / Building No. / Street Name must be minimum 10 characters",
                          },
                        })}
                        name="streetName"
                        placeholder="House / Building No. / Street Name"
                      />
                      {errors.streetName?.type === "required" && (
                        <small className="text-danger error">
                          This is required
                        </small>
                      )}
                      {errors.streetName && (
                        <small className="error">
                          {errors.streetName.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Area Name</label>
                      <input
                        type="text"
                        {...register("areaName", {
                          required: true,
                          minLength: {
                            value: 10,
                            message: "Area name must be minimum 10 characters",
                          },
                        })}
                        name="areaName"
                        placeholder="Area Name"
                      />
                      {errors.areaName?.type === "required" && (
                        <small className="text-danger error">
                          This is required
                        </small>
                      )}
                      {errors.areaName && (
                        <small className="error">
                          {errors.areaName.message}
                        </small>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-lg-8">
                        <div className="form-part">
                          <label htmlFor="">Address</label>
                          <input
                            type="text"
                            {...register("address", {
                              required: true,
                            })}
                            name="address"
                            placeholder="Address"
                          />
                          {errors.address?.type === "required" && (
                            <small className="text-danger error">
                              This is required
                            </small>
                          )}
                        </div>
                      </div> */}
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">Country</label>
                      <input type="text" {...register("country")} />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">State</label>
                      <input
                        type="text"
                        {...register("state", {
                          required: true,
                        })}
                      />
                      {errors.state?.type === "required" && (
                        <small className="text-danger error">
                          This is required
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">City/district</label>
                      <input
                        type="text"
                        {...register("city", { required: true })}
                      />
                      {errors.city?.type === "required" && (
                        <small className="text-danger error">
                          This is required
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">PIN</label>
                      <input
                        type="tel"
                        maxLength={6}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        {...register("pin", {
                          required: true,
                          pattern: {
                            value: /^\d+$/,
                            message: "PIN code must be number.",
                          },
                          minLength: {
                            value: 6,
                            message: "Please enter a valid PIN code",
                          },
                          maxLength: {
                            value: 6,
                            message: "Please enter a valid PIN code",
                          },
                          onChange: (e) => {
                            if (e.target.value.length === 6) {
                              checkValidPin(e.target.value);
                            }
                          },
                        })}
                      />
                      {errors.pin?.type === "required" && (
                        <small className="text-danger error">
                          This is required
                        </small>
                      )}
                      {errors.pin && (
                        <small className="text-danger error">
                          {errors.pin.message}
                        </small>
                      )}
                    </div>
                  </div>
                  {donationDetails?.familyMembers.length >= 1 && (
                    <div className="col-12">
                      {donationDetails?.familyMembers?.map((member, i) => (
                        <div className="row" key={member._id}>
                          <div className="col-lg-4">
                            <div className="form-part">
                              <label htmlFor="">Family member name</label>
                              <input
                                maxLength={50}
                                type="text"
                                {...register(`familyMembers.${i}.name`)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-part">
                              <label htmlFor="">Age</label>
                              <input
                                type="number"
                                {...register(`familyMembers.${i}.age`)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-part">
                              <label htmlFor="">Relation</label>
                              <input
                                type="text"
                                {...register(`familyMembers.${i}.relation`)}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">Preacher</label>
                      <input type="text" {...register("preacher")} />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">
                        How did you come to know about us
                      </label>
                      <input type="text" {...register("platformName")} />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-part">
                      <label htmlFor="">Message</label>
                      <input type="text" {...register("message")} />
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      className="box-hover custom-btn-cls ms-0 mt-md-0 mt-3 donate-now-clicked-form"
                      type="submit"
                    >
                      submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="form-wrap my-5">
              <form onSubmit={handleSubmit(handleSubmitDetails)}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Donation For</label>
                      <input
                        {...register("donationFor")}
                        disabled={true}
                        type="text"
                        placeholder="donation for"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Amount</label>
                      <input
                        type="text"
                        {...register("amount")}
                        disabled={true}
                        placeholder="amount"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Name</label>
                      <input
                        type="text"
                        {...register("name")}
                        disabled={true}
                        placeholder="name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Mobile Number</label>
                      <input
                        type="text"
                        {...register("phone")}
                        disabled={true}
                        placeholder="phone"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Email</label>
                      <input
                        type="text"
                        {...register("email")}
                        placeholder="email"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">PAN</label>
                      <input
                        type="text"
                        disabled={donationDetails?.pan ? true : false}
                        style={{ textTransform: "uppercase" }}
                        maxLength={10}
                        {...register("pan", {
                          required: true,
                          pattern: {
                            value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                            message: "Please enter valid PAN",
                          },
                        })}
                        placeholder="PAN"
                      />
                      {errors.pan?.type === "required" && (
                        <small className="text-danger error">
                          This is required
                        </small>
                      )}
                      {errors.pan && (
                        <small className="text-danger error">
                          {errors.pan.message}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Address with landmark</label>
                      <textarea
                        rows={6} 
                        {...register("address_line_1", {
                          required: true,
                          minLength: {
                            value: 10,
                            message: "must be minimum 10 characters",
                          },
                        })}
                        placeholder="Address 1"
                      />
                      {errors.address_line_1?.type === "required" && (
                        <small className="text-danger error">This is required</small>
                      )}
                      {errors.address_line_1 && (
                        <small className="error">{errors.address_line_1.message}</small>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 d-none">
                    <div className="form-part">
                      <label htmlFor="">Address 2</label>
                      <input
                        type="text"
                        {...register("address_line_2")
                        }
                        placeholder="Address 2"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 d-none">
                    <div className="form-part">
                      <label htmlFor="">Landmark</label>
                      <input
                        type="text"
                        {...register("landmark")
                        }
                        placeholder="Landmark"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">PIN</label>
                      <input
                        type="tel"
                        maxLength={6}
                        {...register("pin", {
                          required: true,
                          pattern: {
                            value: /^\d+$/,
                            message: "PIN code must be number.",
                          },
                          minLength: {
                            value: 6,
                            message: "Please enter a valid PIN code",
                          },
                          maxLength: {
                            value: 6,
                            message: "Please enter a valid PIN code",
                          },
                          onChange: (e) => {
                            setValue("state", "");
                            setValue("city", "");
                            if (e.target.value.length === 6) {
                              fetchStateCity(e.target.value);
                            }
                          },
                        })}
                        placeholder="PIN"
                      />
                      {errors.pin?.type === "required" && (
                        <small className="text-danger error">
                          This is required
                        </small>
                      )}
                      {errors.pin && (
                        <small className="text-danger error">
                          {errors.pin.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">City </label>
                      <input
                        type="text"
                        {...register("city", { required: true })}
                        placeholder="City"
                      />
                      {errors.city?.type === "required" && (
                        <small className="text-danger error">
                          This is required
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">State</label>
                      <input
                        type="text"
                        {...register("state", { required: true })}
                        placeholder="State"
                      />
                      {errors.state?.type === "required" && (
                        <small className="text-danger error">
                          This is required
                        </small>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Country</label>
                      <input
                        type="text"
                        {...register("country")}
                        placeholder="Country"
                        readOnly
                        disabled
                      />

                    </div>
                  </div> */}
                  {/* <div className="col-lg-6">
                        <div className="form-part">
                          <label htmlFor="">PIN</label>
                          <input
                            type="text"
                            maxLength={6}
                            {...register("pin", {
                              required: true,
                              pattern: {
                                value: /^\d+$/,
                                message: "PIN code must be number.",
                              },
                              onChange: (e) => {
                                setValue("state", "");
                                setValue("city", "");
                                if (e.target.value.length === 6) {
                                  fetchStateCity(e.target.value);
                                }
                              },
                            })}
                            placeholder="PIN"
                          />
                          {errors.pin?.type === "required" && (
                            <small className="text-danger error">
                              This is required
                            </small>
                          )}
                          {errors.pin && (
                            <small className="text-danger error">
                              {errors.pin.message}
                            </small>
                          )}
                        </div>
                      </div> */}

                  <div className="col-12">
                    <button
                      className="box-hover custom-btn-cls ms-0 mt-md-0 mt-3 donate-now-clicked-form"
                      type="submit"
                    >
                      submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </section>

      {/*    <section className="thank-you-container">
            <div className="container">
      <div className="row">
               <div className="col-12">
      //              <div className="thank-you-text">
      //               <div className="heading">
      //                   <h2 className="head-1" style={{ textAlign: 'left' }}>Thank You!</h2>
      //                   <p className="mt-4" style={{ textAlign: 'left' }}>Dear Krishna Bhakt,</p>
      //                   <p className='mt-0' style={{ textAlign: 'left' }}>The 80G certificate can no longer be generated.</p>
      //                   <p className='mt-0' style={{ textAlign: 'left' }}>
      //                       Hare Krishna.
      //                   </p>
      //               </div>
      //               <Link to="/join-family" className='d-inline-block text-center box-hover custom-btn thanks mt-4'>JOIN THE FAMILY OF LORD KRISHNA</Link>
      //               </div>
      //           </div>
      //           </div>
      //       </div>
      //   </section>

      // } */}

      <Modal centered show={show} onHide={() => setShow(false)} className="eightyG-modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {
            loading
              ?
              <p>Validating Details and Generating 80G</p>
              :
              <p>Please check your PAN <span className="text-uppercase">{getValues('pan')}</span> and address <span>{`${getValues('address_line_1')} ${getValues('address_line_2')}`}</span> carefully. Details once sumbitted cannot be changed.</p>

          }
          {
            loading
              ?
              <button disabled className='mb-0 custom-btn-cls box-hover px-4' >
                Submitting Data
                <div className="spinner-border text-light ms-2" role="status"></div>
              </button>
              :
              <button onClick={() => submitDetails(getValues())} className='mb-0 custom-btn-cls box-hover px-4' >
                OK
              </button>
          }
        </Modal.Body>
      </Modal>
      <Modal centered show={showFYModal} onHide={() => setShowFYModal(false)} className="eightyG-modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>As per Income Tax Department guidelines, for getting 80G certificate it is mandatory to provide complete Address and Name as on PAN card.</p>
          <p className="mb-0">Please Note: Any changes to your donations are allowed only till the 15th of April.</p>
          <p>Contact <a href="mailto:support@iskconbhiwandi.org">support@iskconbhiwandi.org</a> for any queries.</p>
          <button onClick={() => setShowFYModal(false)} className='mb-0 custom-btn-cls box-hover px-4' >
            OK
          </button>
        </Modal.Body>
      </Modal>

      <GoToTop top={top} />
    </>
  );
};

export default DmsUpdateDetails;
