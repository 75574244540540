import React, { useEffect, useState, useContext } from "react";
import ApiService from "../../api/ApiService";
import AppContext from "../../context/AppContext";
import Tooltip from '@mui/material/Tooltip';
import notification from "../../helpers/notification";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap';

const DownloadTenbe = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const navigate = useNavigate();
    const { donor } = useContext(AppContext);

    const [btnshow, setButtonshow] = useState(false);
    const [status, setStatus] = useState(false);
    const [pan, setPan] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDialogOpenPan, setIsDialogOpenPan] = useState(false);
    const [loader, setLoader] = useState(false);
    const [selectedPan, setSelectedPan] = useState("");

    // Close modal dialogs
    const closeDialogView = () => setIsDialogOpen(false);
    const closeDialogViewPan = () => setIsDialogOpenPan(false);

    // Fetch Donor List
    const fetchTenbedonorList = async (id = null) => {
        try {
            const res = await ApiService.fetchTenbedonorList(id);
            if (!res || typeof res !== "object") throw new Error("Invalid response format");

            setButtonshow(res?.btnshow ?? false);
            setStatus(res?.status ?? "Unknown");

            // Ensure pan is an array and remove duplicates
            const uniquePan = Array.isArray(res?.pan) ? [...new Set(res.pan)] : [];
            setPan(uniquePan);
        } catch (error) {
            console.error("Error fetching donor list:", error.message);
        }
    };

    // Handle Single or Multiple PAN Download
    const handleDownloadBe = async (panList) => {
        try {
            if (!Array.isArray(panList) || panList.length === 0) {
                notification("error", "No valid PAN found.");
                return;
            }

            if (panList.length > 1) {
                setIsDialogOpenPan(true);
                return;
            }

            setLoader(true);
            const blob = await ApiService.downloadRecieptBe(panList[0]);
            if (!blob || blob.size === 0) throw new Error("Empty file received");

            const fileType = blob.type.split('/')[1];
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${panList[0]}_files.${fileType}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            notification("success", "Downloaded successfully");
            navigate("/download-success/", { state: { phone: donor?.phone, pan:panList[0] } });
        } catch (error) {
            setIsDialogOpen(true);
            console.error("Download failed:", error);
        } finally {
            setLoader(false);
        }
    };

    // Handle Multiple PAN Selection Download
    const handleMultiplePan = async () => {
        if (!selectedPan) {
            notification("error", "Please select a PAN.");
            return;
        }

        try {
            setLoader(true);
            const blob = await ApiService.downloadRecieptBe(selectedPan);
            if (!blob || blob.size === 0) throw new Error("Empty file received");

            const fileType = blob.type.split('/')[1];
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${selectedPan}_files.${fileType}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            notification("success", "Downloaded successfully");
            navigate("/download-success/", { state: { phone: donor?.phone, pan:selectedPan } });
        } catch (error) {
            console.error("Download failed:", error);
            notification("error", "Contact support at support@iskconbhiwandi.org");
        } finally {
            setLoader(false);
        }
    };

    // Fetch donor list when component mounts
    useEffect(() => {
        if (donor?.phone) {
            fetchTenbedonorList(donor.phone);
        }
    }, [donor]);

    if (!btnshow || !status) return null;

    return (
        <>
            <Tooltip title="Download">
                <button
                    className="custom-btn-cls box-hover text-uppercase d-flex align-items-center justify-content-center"
                    onClick={!loader ? () => handleDownloadBe(pan) : null}
                    disabled={loader}
                    aria-label="Download Form 10BE FY-24-25"
                >
                    Form 10BE FY-24-25
                    {loader && <div className="spinner-border text-light ms-2" role="status"></div>}
                </button>
            </Tooltip>

            {/* Support Modal */}
            <Modal centered show={isDialogOpen} onHide={closeDialogView}>
                <div className="modal-wrap text-center p-4" style={{ maxWidth: "450px", margin: "0 auto" }}>
                    <button className="btn-close position-absolute" style={{ right: "10px", top: "10px" }} onClick={closeDialogView}></button>
                    <h2>Contact Support!</h2>
                    <p className="mt-2">
                        Kindly send your queries to
                        <a href="mailto:support@iskconbhiwandi.org" className="fw-bold text-decoration-none"> support@iskconbhiwandi.org</a>
                    </p>
                    <p className="mt-2">Hare Krishna.</p>
                </div>
            </Modal>

            {/* PAN Selection Modal */}
            <Modal centered show={isDialogOpenPan} onHide={closeDialogViewPan}>
                <div className="modal-content p-4 text-center" style={{ maxWidth: "450px", margin: "0 auto" }}>
                    <button className="btn-close position-absolute" style={{ right: "10px", top: "10px" }} onClick={closeDialogViewPan}></button>
                    <h5 className="mb-3">Select PAN to Download</h5>
                    <form onSubmit={handleSubmit(handleMultiplePan)}>
                        <div className="mb-3">
                            <label htmlFor="pan" className="form-label">Select PAN</label>
                            <select
                                id="pan"
                                className="form-select"
                                value={selectedPan}
                                onChange={(e) => setSelectedPan(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select PAN</option>
                                {pan.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <button type="submit"  className="custom-btn-cls box-hover text-uppercase  align-items-center justify-content-center" disabled={loader}>
                            {loader ? <div className="spinner-border text-light ms-2" role="status"></div> : "Download"}
                        </button>

                    </form>
                </div>
            </Modal>
        </>
    );
};

export default DownloadTenbe;
