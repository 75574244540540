import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MetaData from "../helpers/MetaData";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import GoToTop from "../helpers/GoToTop";
import AppContext from "../context/AppContext";
import moment from "moment/moment";
import DownloadTenbe from "../components/10BE/downloadTenbe";

const DmsDashboard = () => {
  //console.log(`DmsDashboard.jsx`);
  const { donor, fetchDonor } = useContext(AppContext);
  const search = useLocation().search;
  const link_id = new URLSearchParams(search).get("link");
  const [profiles, setProfiles] = useState(null);
  const [relatives, setRelatives] = useState(null);

  const navigate = useNavigate();

  // if (!donor?.legal_name) {
  //   navigate("/");
  // }
  //console.log("profiles", profiles)
  const fetchProfiles = async (id) => {
    try {
      const response = await ApiService.fetchDonorProfile(id);
      setProfiles(response.profiles);
    } catch (err) {
      console.log(err);
      notification("error", "Unable to fetch data");
    }
  };

  const updateDefaultProfile = async (profileId, donorId) => {
    try {
      await ApiService.updateDefaultProfile({ profileId, donorId });

      fetchProfiles(donor.id);
      notification("success", "Default profile updated successfully");
      fetchDonor()
    } catch (err) {
      console.log(err);
    }
  };

  // TODO: CREATE DEDICATED API FOR FETCHING RELATIVES
  const fetchRelatives = async (id) => {
    try {
      const response = await ApiService.fetchRelatives(id);
      if (response && response?.relatives) {
        setRelatives(response?.relatives);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleEditProfile = (id) => {
    // navigate("/update-profile/", {
    //   state: { donorId: id },
    // });
  };

  useEffect(() => {
    if (donor) {
      fetchProfiles(donor.id);
      fetchRelatives(donor.id);
    }
  }, [donor]);

  return (
    <>
      <GoToTop />
      <MetaData title="Dashboard - ISKCON of Bhiwandi" />
      <section className="dashboard-table">
        <div className="container">
          <div className="top-head pb-3 pb-lg-0 heading">
            <h2 className="head-1"></h2>
            <div className="d-flex justify-content-center flex-wrap">
              <DownloadTenbe />
              <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/service-requests")}
              >
                Service Requests
              </button>
              <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/join-family")}
              >
                Join Family
              </button>
              <button
                className="custom-btn-cls box-hover text-uppercase"
                onClick={() => navigate("/all-transactions")}
              >
                All Transactions
              </button>
              <button
                className="custom-btn-cls box-hover"
                onClick={() => navigate("/donation-form")}
              >
                Donate more
              </button>
            </div>
          </div>

          <div className="life-membership-table">
            <h5 className="table-title mt-lg-5 mt-3 mb-lg-3 mb-0">Profiles</h5>
            <div className="table-heading py-lg-4 py-2 d-none d-lg-block">
              <div className="row justify-content-evenly align-items-center">
                <div className="col-lg-3">
                  <h5>Default</h5>
                </div>
                <div className="col-lg-3">
                  <h5>Name</h5>
                </div>
                <div className="col-lg-3">
                  <h5>Address</h5>
                </div>
                <div className="col-lg-3">
                  <h5>Action</h5>
                </div>
              </div>
            </div>
            <div className="table-content pt-2 pt-lg-0">
              {profiles && (
                <div className="row">
                  <div className="col-lg-12 col-md-6 ">
                    <div className="table-row py-lg-4 py-3">
                      <div className="row align-items-center">
                        <div className="col-lg-3">
                          <div className="form-field">
                            <h5 className="form-title">Default :</h5>
                            <p id="donation-purpose">
                              <input
                                type="radio"
                                name="default_profile"
                                id="default_profile"
                                value={profiles?.primary_donor?.id}
                                defaultChecked //={
                              //   profiles?.default_id ==
                              //   profiles?.primary_donor?.id
                              // }
                              // onChange={() =>
                              //   updateDefaultProfile(
                              //     profiles.id,
                              //     profiles?.primary_donor?.id
                              //   )
                              // }
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-field">
                            <h5 className="form-title">Name :</h5>
                            <p id="donation-name">
                              {profiles.primary_donor?.legal_name}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-field">
                            <h5 className="form-title">Address :</h5>
                            <p id="donation-name">
                              {`
                                ${profiles.primary_donor?.addresses[0]?.address_line_1 ?? ""}
                                ${profiles.primary_donor?.addresses[0]?.address_line_2 ?? ""}
                                ${profiles.primary_donor?.addresses[0]?.landmark ?? ""}
                                ${profiles.primary_donor?.addresses[0]?.city ?? ""}
                                ${profiles.primary_donor?.addresses[0]?.state ?? ""}
                                ${profiles.primary_donor?.addresses[0]?.pincode ?? ""}
                              `}
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="form-field">
                            <h5 className="form-title">Action :</h5>
                            <p id="donation-name">
                              <button
                                className="custom-btn-cls box-hover text-uppercase"
                                //onClick={handleEditProfile(profiles?.primary_donor?.id)}
                                // onClick={() =>
                                //   navigate("/update-profile/", { state: { donorId: profiles?.primary_donor?.id }, })
                                // }

                                onClick={() => 
                                  link_id 
                                    ? navigate(`/update-profile?link=${link_id}`, { state: { donorId: profiles?.primary_donor?.id } }) 
                                    : navigate("/update-profile/", { state: { donorId: profiles?.primary_donor?.id } })
                                }                                
                               
                              >
                                Edit Profile
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {profiles && (
                <>
                  {profiles?.profiles?.map((profile, i) => (
                    <div className="row" key={i}>
                      <div className="col-lg-12 col-md-6 ">
                        <div className="table-row py-lg-4 py-3">
                          <div className="row align-items-center">
                            <div className="col-lg-3">
                              <div className="form-field">
                                <h5 className="form-title">Default :</h5>
                                {/* <p id="donation-purpose">
                                  <input
                                    type="radio"
                                    name="default_profile"
                                    disabled
                                    value={profile?.id}
                                    id="default_profile"
                                    defaultChecked={
                                      profiles?.default_id == profile?.id
                                    }
                                    // onChange={() =>
                                    //   updateDefaultProfile(
                                    //     profiles.id,
                                    //     profile?.id
                                    //   )
                                    // }
                                  />
                                </p> */}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-field">
                                <h5 className="form-title">Name :</h5>
                                <p id="donation-name">{profile?.legal_name}</p>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-field">
                                <h5 className="form-title">Address :</h5>
                                <p id="donation-name">
                                  {
                                    profile?.addresses[0]?.address_line_1 ?
                                      <>
                                        {`${profile?.addresses[0]?.address_line_1 ?? ""}
                                       ${profile?.addresses[0]?.address_line_2 ?? ""}
                                        ${profile?.addresses[0]?.landmark ?? ""}
                                        ${profile?.addresses[0]?.city ?? ""}
                                        ${profile?.addresses[0]?.state ?? ""}
                                        ${profile?.addresses[0]?.pincode ?? ""}
                                      `}

                                      </>
                                      :
                                      <>
                                        {`
                                      ${profiles.primary_donor?.addresses[0]?.address_line_1 ?? ""}
                                      ${profiles.primary_donor?.addresses[0]?.address_line_2 ?? ""}
                                      ${profiles.primary_donor?.addresses[0]?.landmark ?? ""}
                                      ${profiles.primary_donor?.addresses[0]?.city ?? ""}
                                      ${profiles.primary_donor?.addresses[0]?.state ?? ""}
                                      ${profiles.primary_donor?.addresses[0]?.pincode ?? ""}
                                    `}
                                      </>
                                  }
                                </p>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="form-field">
                                <h5 className="form-title">Action :</h5>
                                <p id="donation-name">
                                  <button
                                    className="custom-btn-cls box-hover text-uppercase"
                                    onClick={() =>
                                      navigate("/update-profile/", { state: { donorId: profile?.id }, })
                                    }
                                  >
                                    Edit Profile
                                  </button>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {!donor && (
                <center><h5 className="text-muted p-4">Unable to show details</h5></center>
              )}
            </div>
          </div>

          <div className="life-membership-table">
            <h5 className="table-title mt-lg-5 mt-3 mb-lg-3 mb-0">Relatives</h5>
            <div className="table-heading py-lg-4 py-2 d-none d-lg-block">
              <div className="row justify-content-evenly align-items-center">
                <div className="col-lg-3">
                  <h5>Name</h5>
                </div>
                <div className="col-lg-3">
                  <h5>Relation</h5>
                </div>
                <div className="col-lg-3">
                  <h5>DOB</h5>
                </div>
                <div className="col-lg-3">
                  <h5>MA</h5>
                </div>
              </div>
            </div>
            <div className="table-content pt-2 pt-lg-0">
              {
                relatives?.map((relative, i) => (
                  <div className="row" key={i}>
                    <div className="col-lg-12 col-md-6 ">
                      <div className="table-row py-lg-4 py-3">
                        <div className="row align-items-center">
                          <div className="col-lg-3">
                            <div className="form-field">
                              <h5 className="form-title">Name :</h5>
                              <p id="donation-name">{relative?.legal_name}</p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-field">
                              <h5 className="form-title">Relation :</h5>
                              <p id="donation-name">{relative?.relation}</p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-field">
                              <h5 className="form-title">DOB :</h5>
                              <p id="donation-name">{relative?.dob ? moment(relative?.dob).format('DD-MM-YYYY') : "-"}</p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-field">
                              <h5 className="form-title">MA :</h5>
                              <p id="donation-name">{relative?.doa ? moment(relative?.doa).format('DD-MM-YYYY') : "-"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
              {!donor && (
                <center><h5 className="text-muted p-4">Unable to show details</h5></center>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DmsDashboard;
