import PageHeader from "../components/common/PageHeader";
import { useEffect, useRef, useState, useCallback } from "react";
import GoToTop from "../helpers/GoToTop";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ApiService from "../api/ApiService";
import { useForm, Controller } from "react-hook-form";
import notification from "../helpers/notification";
import MetaData from "../helpers/MetaData";
import { BsCheckLg } from "react-icons/bs";
import Fancybox from "../helpers/Fancybox";
import Skeleton from "react-loading-skeleton";
import { BsFillPlayCircleFill } from "react-icons/bs";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import axios from "axios";
import moment from "moment/moment";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";

const expiry = process.env.REACT_APP_OTPEXPIRY;
let timer = null;

const DonationForm = () => {
  // const formRef = useRef();

  const navigate = useNavigate();
  const { slug } = useParams();
  const { setToken, user, token, donor, profiles } = useContext(AppContext);

  const search = useLocation().search;
  const donationId = new URLSearchParams(search).get("donationId");

  const [donationTypes, setDonationTypes] = useState([null]);
  const [popularDonation, setPopularDonations] = useState([]);
  const [donationType, setDonationType] = useState(null);
  const [donationImg, setDonationImg] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [categories, setCategories] = useState([]);
  const [type, setType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isRetry, setIsRetry] = useState(false);
  const [retryDonationDetails, setRetryDonationDetails] = useState(null);
  const [showMemoryBox, setShowMemoryBox] = useState(false);
  const [goseva, setGoseva] = useState(0);
  const [bhagavadGitaPrice, setBhagavadGitaPrice] = useState(0);
  const [isGoseva, setIsGoseva] = useState(false);
  const [isSadhuBhojanSeva, setIsSadhuBhojanSeva] = useState(false);
  const [eightyg, setIseightyG] = useState(false);
  const [isBhagvadGita, setIsBhagvadGita] = useState(false);
  const [top, setTop] = useState(0);
  const [showOtp, setShowOtp] = useState(false);
  const [isVerified, setIsverified] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpExp, setOtpExp] = useState(expiry);
  const [showresendlink, setShowResendLink] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [icsdontype, setIcsDontype] = useState("A General Donation");
  const [ics_data, setICSdata] = useState();
  const [ics_address, setIcsAddress] = useState([]);
  const [ics_pincode, setIcsPincode] = useState([]);
  const [icsdatawl, setICSdatawl] = useState([]);
  const [item_data, setItemData] = useState([]);
  const [wl_loginics, setWithlogin] = useState([]);
  const [wl_icsname, setIcsname] = useState("");

  //console.lsetTimeout(() => {

  // if(slug){
  //   setTimeout(() => {
  //     const scrollToDonateFromRef = document.getElementById('scrollToDonationForm');
  //     if (scrollToDonateFromRef) {
  //       const offset = -600;
  //       const targetScrollTop = scrollToDonateFromRef.offsetTop - offset;
  //       const container = document.documentElement;
  //       container.scrollTop = targetScrollTop;
  //     } else {
  //       console.error("Element scrollToDonateFromRef not found!");
  //     }
  //   }, 4000);
  // }

  const [recieptId, setRecieptId] = useState("");

  const currentDate = new Date();
  const formattedDate = currentDate.toDateString();

  const Soa = localStorage.getItem("SpecialOccassionAmount");

  const msp = localStorage.getItem("multiplesevaPrice");

  const msn = localStorage.getItem("multiplesevaName");

  const msp_value = !Soa == "" ? msp.split(",") : "";
  const msp_value_a = msp_value[0];
  const msp_value_b = msp_value[1];
  const msp_value_c = msp_value[2];
  const msp_value_d = msp_value[3];
  const msp_value_e = msp_value[4];
  const msp_value_f = msp_value[5];
  const msp_value_g = msp_value[6];
  const msp_value_h = msp_value[7];

  const msn_value = !Soa == "" ? msn.split(",") : "";
  const msn_value_a = msn_value[0];
  const msn_value_b = msn_value[1];
  const msn_value_c = msn_value[2];
  const msn_value_d = msn_value[3];
  const msn_value_e = msn_value[4];
  const msn_value_f = msn_value[5];
  const msn_value_g = msn_value[6];
  const msn_value_h = msn_value[7];

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      state: "",
      city: "",
      ...(donor && { name: donor?.legal_name })
    },
  });

  const watchSomeoneMemory = watch([
    "memoryOfSomeone",
    "amount",
    "sadhuBhojanSeva",
    "bhagavadGita",
    "goseva",
  ]);
  const addressWatch = watch("address");
  const pinWatch = watch("pin");

  const fetchStateCity = async (pin) => {
    try {
      let url = `https://api.postalpincode.in/pincode/${pin}`;

      let config = {
        method: "GET",
        url,
      };
      const { data } = await axios(config);

      let cityVal = "";
      if (data[0].PostOffice.length > 1) {
        data[0].PostOffice.map((ct) => {
          if (ct.Block !== "NA") {
            return (cityVal = ct.Block);
          }
        });
      } else {
        cityVal = data[0].PostOffice[0].Block;
      }

      if (data[0].Status === "Success") {
        setValue(
          "city",
          cityVal !== "" ? cityVal : data[0].PostOffice[0].District
        );
        setValue("state", data[0].PostOffice[0].State);
      } else {
        notification("warning", "Enter valid PIN code!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formRef = useCallback((node) => {
    if (node) {
      setTop(node.offsetTop - 40);
    }
  });
  // useEffect(() => {
  //   const value = watch();

  //   const { amount, sadhuBhojanSeva, bhagavadGita, goseva } = value;

  //   if (amount !== undefined || sadhuBhojanSeva !== undefined || bhagavadGita !== undefined || goseva !== undefined) {
  //     let total =
  //       (amount ? Number(amount) : 0) +
  //       (sadhuBhojanSeva ? Number(sadhuBhojanSeva) : 0) +
  //       (bhagavadGita ? Number(bhagavadGita) * 200 : 0) +
  //       (goseva ? Number(goseva) * 120 : 0);

  //     setTotalAmount(total);
  //   }

  //   setShowMemoryBox(value.memoryOfSomeone);
  // }, [watch(["amount", "sadhuBhojanSeva", "bhagavadGita", "goseva"])]);

  useEffect(() => {
    const showBox = watch((value) => {
      //console.log(value)

      if (value) {

        const { amount, sadhuBhojanSeva, bhagavadGita, goseva } = value;
        let total =
          (amount ? Number(amount) : 0) +
          (sadhuBhojanSeva ? Number(sadhuBhojanSeva) : 0) +
          (bhagavadGita ? Number(bhagavadGita) * 200 : 0) +
          (goseva ? Number(goseva) * 120 : 0);
        setTotalAmount(total);

        setShowMemoryBox(value.memoryOfSomeone);
      }
    });
    return () => showBox.unsubscribe();
  }, [watch]);

  const recaptchaRef = useRef();

  const fetchDonationType = async () => {
    try {
      const res = await ApiService.fetchDonationService(slug);
      //console.log("--------------------", res);
      setDonationType(res.donationType);
      setIsGoseva(res.donationType?.goseva);
      setIseightyG(res.donationType?.eightyGb);
      setIsSadhuBhojanSeva(res.donationType?.sadhuBhojanSeva);
      setIsBhagvadGita(res.donationType?.bhagvadGita);
      if (res.donationType?.popular) {
        setPopularDonations(res.donationTypes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPopularDonations = async () => {
    try {
      const res = await ApiService.fetchPopularDonations();
      // console.log(res)
      setPopularDonations(res);
      if (!slug) {
        setDonationImg(res[0].image);
        setVideoUrl(res[0].videoUrl);
        setIsGoseva(res[0].goseva);
        setIsSadhuBhojanSeva(res[0].sadhuBhojanSeva);
        setIseightyG(res[0].eightyGb);
        setIsBhagvadGita(res[0].bhagvadGita);

        let details = {};
        if (!isRetry) {
          details.donationType = res[0]?._id;
        }
        if (donor && !isRetry) {
          details.name = donor?.legal_name;
          details.phone = donor?.phone;
          details.email = donor?.email;
          details.pan = donor?.pan;

          if (donor?.addresses?.length) {
            details.pin = donor?.addresses[0]?.pincode;
            details.city = donor?.addresses[0]?.city;
            details.state = donor?.addresses[0]?.state;
            details.address = `${donor?.addresses[0]?.address_line_1 ?? ''} ${donor?.addresses[0]?.address_line_2 ?? ''}`;
          }
        }
        // TODO reset
        reset(details);
        if (donor?.addresses?.length && donor?.addresses[0]?.pincode) {
          fetchStateCity(donor?.addresses[0]?.pincode);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!slug) {
      fetchPopularDonations();
    } else {
      // formRef.current.scrollIntoView({ behavior: "smooth" });
      fetchDonationType();
    }
    if (user) {
      setIsverified(true);
    }
  }, [isRetry, donationId, user]);

  useEffect(() => {
    if (donationType?.popular) {
      let details = { donationType: donationType?._id };
      if (donor && !isRetry) {
        details.name = donor?.legal_name;
        details.phone = donor?.phone;
        details.email = donor?.email;
        details.pan = donor?.pan;

        if (donor?.addresses?.length) {
          details.pin = donor?.addresses[0]?.pincode;
          details.city = donor?.addresses[0]?.city;
          details.state = donor?.addresses[0]?.state;
          details.address = `${donor?.addresses[0]?.address_line_1 ?? ''} ${donor?.addresses[0]?.address_line_2 ?? ''}`;
        }
      }
      // TODO reset
      reset(details);
      if (donor?.addresses?.length && donor?.addresses[0]?.pincode) {
        fetchStateCity(donor?.addresses[0]?.pincode);
      }
    } else {
      let details = {
        donationType: donationType?.title
          ? donationType?.title.split("|")[0] +
          " " +
          (donationType?.title?.split("|").length !== 1
            ? donationType?.title?.split("|")[1]
            : "")
          : "",
        amount: donationType?.amount,
      };

      if (donor && !isRetry) {
        details.name = donor?.legal_name;
        details.phone = donor?.phone;
        details.email = donor?.email;
        details.pan = donor?.pan;

        if (donor?.addresses?.length) {
          details.pin = donor?.addresses[0]?.pincode;
          details.city = donor?.addresses[0]?.city;
          details.state = donor?.addresses[0]?.state;
          details.address = `${donor?.addresses[0]?.address_line_1 ?? ''} ${donor?.addresses[0]?.address_line_2 ?? ''}`;
        }
      }
      // TODO reset
      reset(details);
      if (donor?.addresses?.length && donor?.addresses[0]?.pincode) {
        fetchStateCity(donor?.addresses[0]?.pincode);
      }

    }
    setDonationImg(donationType?.image);
    setVideoUrl(donationType?.videoUrl);
  }, [donationType, user, isRetry]);

  function getTime(time) {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  }

  const handleSendOtp = async (phone) => {
    if (timer) {
      clearInterval(timer);
    }
    if (phone.length >= 10) {
      try {
        const res = await ApiService.sendOtp({ number: phone });
        notification("success", res.message);
        setShowTimer(true);
        timer = setInterval(() => {
          setOtpExp((prev) => prev - 1);
        }, 1000);
      } catch (err) {
        console.log(err);
        notification("error", err.response.data.message);
      }
    }
  };

  //console.log(item_data)
  //console.log(icsdatawl)

  const handleVerifyOtp = async (otp) => {
    try {
      let otpData = {
        number: getValues("phone"),
        otp,
      };
      const res = await ApiService.loginUser(otpData);
      // console.log(res);
      localStorage.setItem(`token`, res.token);
      setToken(res.token);
      notification("success", res.message);
      setOtp("");
      setIsverified(true);
      setShowOtp(false);
      madeDonation(getValues());
    } catch (err) {
      console.log(err);
      notification("error", err.response.data.message);
    }
  };

  let idd = "";
  const ics_id = localStorage.getItem("ics_id");

  let ics_idd = "";

  if (token) {
    if (item_data.id) {
      ics_idd = item_data.id;
    } else {
      ics_idd = wl_loginics;
    }
  } else {
    if (item_data.id) {
      ics_idd = item_data.id;
    } else {
      ics_idd = ics_id;
    }
  }

  // console.log(ics_idd)

  // SEND DATA TO ICS

  const sendDonationICS = async (data, idd, final_amt) => {
    // console.log(idd);
    const pay_idd = localStorage.getItem("my-key");
    const ics_id = localStorage.getItem("ics_id");

    let address = "";
    let pincode = "";
    if (data.address) {
      address = data.address;
      // } else if (item_data.length > 0) {
      //   if (item_data.address.line1) {
      //     address = item_data.address.line1 + ', ' + item_data.address.city + ', ' + item_data.address.state;
      //     data.city = item_data.address.city;
      //     data.state = item_data.address.state;
      //     pincode = item_data.address.pincode;
      //   } else {
      //     if (icsdatawl.length > 0) {
      //       if (icsdatawl[0].address.line1) {
      //         address = icsdatawl[0].address.line1 + ', ' + icsdatawl[0].address.city + ', ' + icsdatawl[0].address.state;
      //         data.city = icsdatawl[0].address.city;
      //         data.state = icsdatawl[0].address.state;
      //         pincode = icsdatawl[0].address.pincode;
      //       }
      //     }
      //   }
      // }
    } else if (icsdatawl.length > 0) {
      if (icsdatawl[0].address.line1) {
        address =
          icsdatawl[0].address.line1 +
          ", " +
          icsdatawl[0].address.city +
          ", " +
          icsdatawl[0].address.state;
        data.city = icsdatawl[0].address.city;
        data.state = icsdatawl[0].address.state;
        pincode = icsdatawl[0].address.pincode;
      }
    }

    if (data.address) {
      if (data.pin) {
        pincode = data.pin;
        //console.log(pincode);
      }
    }

    //console.log(address);

    try {
      let ics_id = "";

      if (token) {
        if (item_data.id) {
          ics_id = item_data.id;
        } else {
          ics_id = wl_loginics;
        }
      } else {
        if (item_data.id) {
          ics_id = item_data.id;
        } else {
          ics_id = ics_id;
        }
      }

      const ress = await ApiService.getIcsData(data.phone);
      //setICSdatawl(ress.data);
      // console.log(ress.data[0].address.line1);

      let icsData = {
        don_id: idd,
        icsid: "",
        donorName: wl_icsname ? wl_icsname : data.name,
        donorEmail: data.email,
        donorContact: data.phone,
        donorPAN: item_data.pan
          ? item_data.pan.toUpperCase()
          : data.pan.toUpperCase(),
        donorCity: data.city ? data.city : "",
        donorState: data.state ? data.state : "",
        donorCountry: "India",
        donorAddress: data.address
          ? data.address
          : ress.data[0].address.line1 +
          ", " +
          ress.data[0].address.city +
          ", " +
          ress.data[0].address.state,
        donorPin: data.pin ? data.pin : ress.data[0].address.pincode,
        amount: final_amt,
        schemeName: "A General Donation",
        modeName: "online",
        intrumentNo: pay_idd,
        comments: data.memoryText ? data.memoryText : "",
        instrumentDate: moment(formattedDate).format("DD-MM-YYYY"),
        bankName: "Razorpay",
        bankBranch: "",
      };

      const respon = await ApiService.sendDataToIcs(icsData);

      // console.log(respon.checkEightyG)

      if (respon) {
        localStorage.removeItem("my-key");
        localStorage.setItem("updtpnid", ics_id);
        localStorage.setItem(
          "updtpnno",
          item_data.pan ? item_data.pan.toUpperCase() : ""
        );
      }
      // console.log(response.checkEightyG);
      // console.log(response.data.data.recieptURL);
      // console.log(ics_id);

      // if (response.data.data) {
      const jsonData = JSON.stringify(respon);

      //   const responseData = JSON.parse(jsonData);

      //   const receipt = responseData.data.rno + 'R' + responseData.data.rbno;
      let AllData = {
        donor_id: idd,
        checkEightyG: respon.checkEightyG,
        receiptDate: respon.receiptDate,
        rbno: respon.rbno,
        rno: respon.rno,
        amount: respon.amount,
        mode: respon.mode,
        bankName: respon.bankName,
        bankBranch: respon.bankBranch,
        instrumentNo: respon.instrumentNo,
        instrumentDate: respon.instrumentDate,
        receiptURL: respon.receiptURL,
        donorName: respon.donorName,
        donorAddress: respon.donorAddress,
        donorCity: respon.donorCity,
        donorState: respon.donorState,
        donorCountry: respon.donorCountry,
        donorPin: respon.donorPin,
        donorContact: respon.donorContact,
        donorEmail: respon.donorEmail,
        donorPAN: respon.donorPAN,
      };

      const res = await ApiService.downloadNon80g(AllData);

      localStorage.setItem("responseData", jsonData);
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const formatName = (name) => {
    return name
      .split(" ") // Naam ko space ke basis pe todna
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Har word ka pehla letter capital baaki small
      .join(" "); // Wapas string me jodna
  };

  const madeDonation = async (data) => {
    try {
      const multisevatype = localStorage.getItem("multiplesevatype");
      const token = await recaptchaRef.current.executeAsync();
      data.token = token;
      data.goseva = goseva * 120;
      data.name = wl_icsname ? formatName(wl_icsname) : formatName(data.name);
      data.bhagavadGita = bhagavadGitaPrice * 200;
      data.userType = donor ? "user" : "guest";
      data.multipleSeva = Soa ? multisevatype : "";
      data.msp_value_a = msp_value_a ? msp_value_a : "";
      data.msp_value_b = msp_value_b ? msp_value_b : "";
      data.msp_value_c = msp_value_c ? msp_value_c : "";
      data.msp_value_d = msp_value_d ? msp_value_d : "";
      data.msp_value_e = msp_value_e ? msp_value_e : "";
      data.msp_value_f = msp_value_f ? msp_value_f : "";
      data.msp_value_g = msp_value_g ? msp_value_g : "";
      data.msp_value_h = msp_value_h ? msp_value_h : "";

      data.msn_value_a = msn_value_a ? msn_value_a : "";
      data.msn_value_b = msn_value_b ? msn_value_b : "";

      data.msn_value_c = msn_value_c ? msn_value_c : "";
      data.msn_value_d = msn_value_d ? msn_value_d : "";
      data.msn_value_e = msn_value_e ? msn_value_e : "";
      data.msn_value_f = msn_value_f ? msn_value_f : "";
      data.msn_value_g = msn_value_g ? msn_value_g : "";
      data.msn_value_h = msn_value_h ? msn_value_h : "";

      let _doonationType;
      if (isRetry) {
        _doonationType = retryDonationDetails.donationType;
      } else {
        _doonationType =
          popularDonation.length > 0 || donationType.popular
            ? data.donationType
            : donationType?._id;
      }

      const res = await ApiService.donate({
        ...data,
        type,
        donationType: _doonationType,
        ...(donor && donor?.id && { donorId: donor?.id }),
      });

      const final_amt = res.amount / 100;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        currency: res.currency,
        amount: res.amount,
        name: process.env.REACT_APP_PROJECT_NAME,
        description: "Help Us to Help the Society",
        image: `${process.env.REACT_APP_S3_URL}/assets/img/logo.png`,
        order_id: res.id,
        handler: function (response) {
          const textForStorage = response.razorpay_payment_id;
          localStorage.setItem("my-key", textForStorage);
          const textFromStorage = localStorage.getItem("my-key");

          if (textFromStorage) {
            // sendDonationICS(data, res.donationId, final_amt);
            navigate(`/thank-you/${res.donationId}/donation`, {
              replace: true,
            });
            localStorage.setItem("multiplesevaPrice", "");
            localStorage.setItem("multiplesevaName", "");
            localStorage.setItem("SpecialOccassionAmount", "");
            localStorage.setItem("multiplesevatype", "");
            localStorage.setItem(`donation-${res.donationId}`, res.donationId);
          }
        },
        prefill: {
          name: res.name ? res.name : "",
          email: res.email ? res.email : "",
          contact: res.phone ? res.phone : "",
        },
        notes: {
          type: "donation",
        },
        theme: {
          color: "#FFD370",
        },
        modal: {
          ondismiss: async function () {
            const cancelDonation = await ApiService.cancelDonation(res.id);
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", function (response) {
        // console.log(response)
        navigate(`/payment-failed?id=${res.donationId}&&type=donation`, {
          replace: true,
        });
      });
      setLoading(false);
      recaptchaRef.current.reset();
    } catch (err) {
      setLoading(false);
      console.log(err);
      notification("error", "Unable to make donation, try again later!");
    }
  };

  const handleDonate = async (data) => {
    let donor_name;
    if (token) {
      if (!isNaN(data.name)) {
        if (data.name == donor?.id) {
          donor_name = donor.legal_name;
        } else {
          donor_name = profiles?.find((profile) => profile?.id == data?.name)?.legal_name;
        }
        data.name = donor_name;
      }
    }
    setLoading(true);
    madeDonation(data);
  };

  const resendOtp = () => {
    setOtpExp(expiry);
    handleSendOtp(getValues("phone"));
    setShowResendLink(false);
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handleDonationTypechange = (e) => {
    const indx = e.nativeEvent.target.selectedIndex;
    setIcsDontype(e.nativeEvent.target[indx].text);
    let _donationType = popularDonation.find(
      (type) => type._id === e.target.value
    );
    setDonationImg(_donationType.image);
    setVideoUrl(_donationType.videoUrl);
    setIsGoseva(_donationType.goseva);
    setIsSadhuBhojanSeva(_donationType.sadhuBhojanSeva);
    setIseightyG(_donationType.eightyGb);
    setIsBhagvadGita(_donationType.bhagvadGita);
    if (!_donationType.bhagvadGita) {
      // setValue("bhagavadGita", "");
      setBhagavadGitaPrice(0);
    }
    if (!_donationType.sadhuBhojanSeva) {
      setValue("sadhuBhojanSeva", "");
    }
    if (!_donationType.goseva) {
      setGoseva(0);
    }
  };

  const [meta, setMeta] = useState(null);
  const fetchMeta = async (pagename) => {
    try {
      let res = await ApiService.fetchMetaData(pagename);
      setMeta(res);
    } catch (error) {
      console.log(error);
    }
  };

  const retryDonation = async () => {
    try {
      const res = await ApiService.retryDonation(donationId);
      //console.log(res, "retry");

      setIsRetry(true);
      setRetryDonationDetails(res);
      setIsSadhuBhojanSeva(false);
      setIseightyG(false);
      setIsBhagvadGita(false);
      setIsGoseva(false);
      setGoseva(0);
      setBhagavadGitaPrice(0);
      let addOns = {};
      for (let i = 0; i < res.donationAddOns.length; i++) {
        switch (res.donationAddOns[i].name) {
          case "sadhuBhojanSeva":
            addOns.sadhuBhojanSeva = res.donationAddOns[i].amount;
            setIsSadhuBhojanSeva(true);
            break;
          case "bhagavadGita":
            addOns.bhagavadGita = Number(res.donationAddOns[i].amount) / 200;
            setBhagavadGitaPrice(Number(res.donationAddOns[i].amount) / 200);
            setIsBhagvadGita(true);
            break;
          case "goseva":
            addOns.goseva = Number(res.donationAddOns[i].amount) / 120;
            setGoseva(Number(res.donationAddOns[i].amount) / 120);
            setIsGoseva(true);
            break;
          default: console.log("No match found");
        }
      }

      reset({
        ...res,
        ...addOns,
        donationType: res.type,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    fetchMeta("donation-form");
    if (donationId) {
      retryDonation();
    }
  }, [isRetry, donationId]);

  useEffect(() => {
    if (!user) {
      if (otpExp === 0) {
        setShowTimer(false);
        setShowResendLink(true);
        clearInterval(timer);
        setOtpExp(expiry);
      }
    }
  }, [otpExp, expiry, user]);

  const handleGetIcsData = async (id) => {
    const res = await ApiService.fetchDonorById(id);
    //console.log("DMS DATA --", res.donor)
    if(!donationId){
      handelOptions();
    }
   
    setICSdata(res.donor);
    if (res.donor.id > 0) {
      if (!token) {
        setLoginPopup(true);
      }
    }
  };

  const { loginPopup, setLoginPopup } = useContext(AppContext);

  const campaignRef = useCallback((node) => {
    if (node) {
      let headerHeight;
      if (window.innerWidth > 991) {
        headerHeight = 121;
      } else if (window.innerWidth > 576) {
        headerHeight = 80;
      } else {
        headerHeight = 73;
      }
      setTop(node?.offsetTop - headerHeight);
    }
  });

  const handelOther = (e) => {
    setICSdata("");
    const currentValues = getValues()
    reset({
      ...currentValues,
      name: "",
      pan: "",
      address: "",
      pin: "",
      email: "",
    });
  };

  const handelOptions = (e) => {
    // TODO reset
    const currentValues = getValues()
    reset({
      ...currentValues,
      name: donor?.legal_name,
      phone: donor?.phone,
      email: donor?.email,
      pan: donor?.pan,
      ...(donor?.addresses?.length ? {
        address: `${donor?.addresses[0]?.address_line_1 ?? ''} ${donor?.addresses[0]?.address_line_2 ?? ''}`,
        pin: donor?.addresses[0]?.pincode,
      } : {
        address: `${donor?.default_address?.address_line_1 ?? ''} ${donor?.default_address?.address_line_2 ?? ''}`,
        pin: donor?.default_address?.pincode,
      }),
    });
    // if (token) {
    setICSdata(donor)
    // }
  };

  //SELECT PROFILE IN NAME INPUT WHICH FLOW IN WEBSITE
  const handleSelectProfiles = (e) => {
    const donorId = e.target.value;
    const currentValues = getValues()

    if (donor) {
      let _donor;
      if (donorId == donor?.legal_name) {
        _donor = donor
      } else {
        _donor = profiles.find((profile) => profile.legal_name == donorId);
      };

      if (_donor) {
        reset({
          ...currentValues,
          name: _donor?.legal_name,
          phone: _donor.phone,
          email: _donor.email,
          pan: _donor.pan,
          pin: _donor?.addresses[0] ? _donor?.addresses[0]?.pincode : '',
          address: _donor?.addresses[0] ? `${_donor?.addresses[0]?.address_line_1 ?? ''} ${_donor?.addresses[0]?.address_line_2 ?? ''}` : ''
        });

        if (_donor?.addresses[0] && _donor?.addresses[0]?.pincode) {
          fetchStateCity(_donor?.addresses[0]?.pincode);
        }
      }
    }
  };

  // console.log("donor:->", profiles[0]?.id)

  // useEffect(() => {
  //   campaignRef();
  //   if (token) {
  //     handleGetIcsData(profiles[0]?.id);
  //     handelOptions();
  //   }
  // }, [token]);

  useEffect(() => {
    campaignRef();
    if (token && profiles?.length > 0) {
      handleGetIcsData(profiles[0]?.id);
    }
  }, [token, profiles]);


  return (
    <>
      <MetaData title="Donation Form - ISKCON of Bhiwandi" />
      <PageHeader title="Donation form" imgSrc="/images/page-header/17.webp" />
      <section className="donation-form-sec" ref={formRef}>
        <div className="container">
          <div className="row d-flex justify-content-center mb-5">
            <div className="col-md-10">
              <div id="scrollToDonationForm" className="heading">
                <h2 className="head-1">QUICK DONATE</h2>
                <p className="b-line">
                  If you would like to make a donation towards a particular area
                  of activity, please select an option from below. ISKCON relies
                  entirely on voluntary donations and so every donation counts.
                  Please note that donation is processed on a secure site.
                </p>
              </div>
            </div>
          </div>
          <div
            className="form-wrap my-5"
            style={{ scrollMarginBlockStart: "150px" }}
          >
            <form onSubmit={handleSubmit(handleDonate)}>
              <div className="row align-items-start">
                <div className="col-lg-4 pe-xl-4">
                  <div className="donate-img">
                    {donationType ? (
                      <>
                        {donationType?.videoUrl ? (
                          <Fancybox>
                            {donationType?.type === "donationType" ? (
                              <a
                                data-fancybox="video"
                                href={donationType?.videoUrl}
                                data-thumb={`${process.env.REACT_APP_S3_URL}/uploads/donation/donation-type/${donationImg}`}
                              >
                                <figure className="up-right hasVid">
                                  {donationImg ? (
                                    <>
                                      <img
                                        src={`${process.env.REACT_APP_S3_URL}/uploads/donation/donation-type/${donationImg}`}
                                        alt=""
                                      />
                                      <span className="donate-play">
                                        <span className="wave">
                                          <BsFillPlayCircleFill />
                                        </span>
                                      </span>
                                    </>
                                  ) : (
                                    <Skeleton height={320} />
                                  )}
                                </figure>
                              </a>
                            ) : (
                              <a
                                data-fancybox="video"
                                href={donationType?.videoUrl}
                                data-thumb={`${process.env.REACT_APP_S3_URL}/uploads/festival/${donationImg}`}
                              >
                                <figure className="up-right hasVid">
                                  {donationImg ? (
                                    <>
                                      <img
                                        src={`${process.env.REACT_APP_S3_URL}/uploads/festival/${donationImg}`}
                                        alt=""
                                      />
                                      <span className="donate-play">
                                        <span className="wave">
                                          <BsFillPlayCircleFill />
                                        </span>
                                      </span>
                                    </>
                                  ) : (
                                    <Skeleton height={320} />
                                  )}
                                </figure>
                              </a>
                            )}
                          </Fancybox>
                        ) : (
                          <>
                            {donationType?.type === "donationType" ? (
                              <figure className="up-right">
                                {donationImg ? (
                                  <img
                                    src={`${process.env.REACT_APP_S3_URL}/uploads/donation/donation-type/${donationImg}`}
                                    alt=""
                                  />
                                ) : (
                                  <Skeleton height={320} />
                                )}
                              </figure>
                            ) : (
                              <figure className="up-right">
                                {donationImg ? (
                                  <img
                                    src={`${process.env.REACT_APP_S3_URL}/uploads/festival/${donationImg}`}
                                    alt=""
                                  />
                                ) : (
                                  <Skeleton height={320} />
                                )}
                              </figure>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {videoUrl ? (
                          <>
                            <Fancybox>
                              <a
                                data-fancybox="video"
                                href={donationType?.videoUrl}
                                data-thumb={`${process.env.REACT_APP_S3_URL}/uploads/donation/donation-type/${donationImg}`}
                              >
                                <figure className="up-right hasVid">
                                  {donationImg ? (
                                    <>
                                      <img
                                        src={`${process.env.REACT_APP_S3_URL}/uploads/donation/donation-type/${donationImg}`}
                                        alt=""
                                      />
                                      <span className="donate-play">
                                        <span className="wave">
                                          <BsFillPlayCircleFill />
                                        </span>
                                      </span>
                                    </>
                                  ) : (
                                    <Skeleton height={320} />
                                  )}
                                </figure>
                              </a>
                            </Fancybox>
                          </>
                        ) : (
                          <>
                            <figure className="up-right">
                              {donationImg ? (
                                <img
                                  src={`${process.env.REACT_APP_S3_URL}/uploads/donation/donation-type/${donationImg}`}
                                  alt=""
                                />
                              ) : (
                                <Skeleton height={320} />
                              )}
                            </figure>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  {eightyg && (
                    <div ref={campaignRef} className="form-part mt-4 me-lg-2">
                      <div className="notes-wrap mt-0">
                        <p className="text-center">
                          <span> Please Note:</span> Complete Address with
                          PIN-Code and PAN is mandatory for an 80G Receipt.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-8 mt-lg-0 mt-4 ">
                  <div className="row">
                    {Soa ? (
                      <>
                        <div className="col-md-6">
                          <div className="form-part">
                            <label htmlFor="">Donation For</label>
                            <input
                              id="donationType"
                              value={localStorage
                                .getItem("multiplesevaName")
                                .replace(/,/g, ":")}
                              type="text"
                              placeholder="Donation For"
                            />

                            <Link
                              className="donation-link"
                              to="/donate#all-donation"
                            >
                              view more donation options
                            </Link>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-6">
                          <div className="form-part">
                            <label htmlFor="">Donation For</label>
                            {donationType?.popular ||
                              (popularDonation.length > 0 && !isRetry) ? (
                              <select
                                {...register("donationType", {
                                  required: true,
                                  onChange: (e) => {
                                    handleDonationTypechange(e);
                                  },
                                })}
                              >
                                {popularDonation.map((data, i) => (
                                  <option key={i} value={data._id}>
                                    {data.title.split("|")[0]}
                                    {data.title.split("|")[3]}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <input
                                type="text"
                                placeholder=""
                                {...register("donationType", {
                                  required: true,
                                })}
                                readOnly
                              />
                            )}
                            {errors.donationType?.type === "required" && (
                              <small className="text-danger error">
                                This is required
                              </small>
                            )}
                            <Link
                              className="donation-link"
                              to="/donate#all-donation"
                            >
                              view more donation options
                            </Link>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="amount">Amount</label>
                        {donationType?.amount || isRetry ? (
                          <input
                            type="number"
                            placeholder="Enter Amount"
                            {...register("amount", { required: true })}
                          />
                        ) : (
                          <input
                            type="number"
                            defaultValue={Soa}
                            readOnly={Soa ? true : false}
                            placeholder="Enter Amount"
                            {...register("amount", {
                              required: "Amount is required",
                              pattern: {
                                value: /^\d+$/,
                                message: "Amount must be a number.",
                              },
                            })}
                          />
                        )}
                        {errors.amount && (
                          <small className="text-danger error">{errors.amount.message}</small>
                        )}
                      </div>
                    </div>


                    {/* <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">Amount</label>
                        {donationType?.amount || isRetry ? (
                          <input
                            type="text"
                            placeholder="Enter Amount"
                            {...register("amount", { required: true })}
                          // disabled
                          />
                        ) : (
                          <input
                            type="text"
                            value={Soa}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register("amount", {
                              required: true,
                              //value: localStorage.getItem('multiplesevatype') ? true : false,
                              pattern: {
                                value: /^\d+$/,
                                message: "Amount must be number.",
                              },
                            })}
                            placeholder="Enter Amount"
                          />
                        )}
                        {errors.amount?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.amount && (
                          <small className="text-danger error">
                            {errors.amount.message}
                          </small>
                        )}
                      </div>
                    </div> */}

                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">Mobile Number</label>
                        <input
                          type="tel"
                          placeholder="Phone Number"
                          // onKeyUp={getIcsData}
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          readOnly={donor ? true : false}
                          // disabled=
                          {...register("phone", {
                            required: true,
                            pattern: {
                              value: /^[6-9]\d*$/,
                              message: "Please provide valid phone number",
                            },
                            minLength: {
                              value: 10,
                              message: "Phone number must be minimum 10 digit",
                            },
                          })}
                        />
                        {errors.phone?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.phone && (
                          <small className="text-danger error">
                            {errors.phone.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">Email</label>
                        <input
                          type="text"
                          {...register("email", {
                            required: true,
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email",
                            },
                          })}
                          placeholder="Email"
                        />
                        {errors.email?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.email && (
                          <small className="text-danger error">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                    {!ics_data || ics_data == "" ? (
                      <div className="col-md-6">
                        <div className="form-part">
                          <label htmlFor="">Name</label>
                          <input
                            maxLength={50}
                            type="text"
                            // onKeyUp={fetchNameFromIcs}
                            {...register("name", {
                              required: true,
                              maxLength: {
                                value: 50,
                                message:
                                  "Name can't exceeds more than 50 characters",
                              },
                              minLength: {
                                value: 3,
                                message:
                                  "Name must be greater than 3 characters",
                              },
                              pattern: {
                                value: /^[A-Za-z ]*$/,
                                message: "Special Characters not allowed !",
                              },
                            })}
                            onKeyPress={(event) => {
                              if (/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Name"
                          />
                          {errors.name?.type === "required" && (
                            <small className="text-danger error">
                              This is required
                            </small>
                          )}
                          {errors.name?.message && (
                            <small className="text-danger error">
                              {errors.name?.message}
                            </small>
                          )}
                          {token ? (
                            <button
                              className="donation-link"
                              onClick={handelOptions}
                              type="button"
                            >
                              Select Options
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-6">
                        <div className="form-part">
                          <label htmlFor="">Select Name</label>
                          <select
                            {...register("name", {
                              required: true,
                              onChange: (e) => {
                                handleSelectProfiles(e);
                              },
                            })}
                          >
                            {
                              retryDonationDetails && (
                                <option value={retryDonationDetails.name}>
                                  {retryDonationDetails.name}
                                </option>
                              )
                            }
                            {
                              donor && (
                                <option value={donor?.legal_name}>
                                  {donor.legal_name}
                                </option>
                              )
                            }
                            {
                              profiles && profiles?.map((profile, i) => (
                                <option key={i} value={profile.legal_name}>
                                  {profile.legal_name}
                                </option>
                              ))
                            }
                          </select>

                          {errors.donationType?.type === "required" && (
                            <small className="text-danger error">
                              This is required
                            </small>
                          )}
                          <button
                            className="donation-link"
                            onClick={handelOther}
                            type="button"
                          // style={{fontSize:'18px'}}
                          >
                            To enter new name click here
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">PAN (Optional)</label>
                        <Tooltip
                          title="For 80G reciept"
                          placement="bottom-start"
                        >
                          <input
                            type="text"
                            style={{ textTransform: "uppercase" }}
                            name=""
                            id=""
                            // onKeyUp={fetchPanFromIcs}
                            maxLength={10}
                            placeholder="PAN"
                            {...register("pan", {
                              pattern: {
                                value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                                message: "Please enter valid PAN",
                              },
                            })}
                          />
                        </Tooltip>
                        {errors.pan && (
                          <small className="text-danger error">
                            {errors.pan.message}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">Address with landmark (Optional)</label>
                        <Tooltip
                          title="For Prasadam please provide this"
                          placement="bottom-start"
                        >
                          <textarea
                            defaultValue={ics_address}
                            type="text"
                            {...register("address", {
                              //required: pinWatch?.trim()?.length > 0,
                              minLength: {
                                value: 10,
                                message:
                                  "address must be minimum 10 characters",
                              },
                            })}
                            placeholder="Address"
                          ></textarea>
                        </Tooltip>
                        {errors.address?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.address && (
                          <small className="error">
                            {errors.address.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">PIN Code (Optional)</label>
                        <input
                          defaultValue={ics_pincode}
                          type="text"
                          minLength={6}
                          maxLength={6}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("pin", {
                            //required: addressWatch?.trim()?.length > 0,
                            pattern: {
                              value: /^\d+$/,
                              message: "PIN code must be number.",
                            },
                            onChange: (e) => {
                              setValue("state", "");
                              setValue("city", "");
                              if (e.target.value.length === 6) {
                                fetchStateCity(e.target.value);
                              }
                            },
                          })}
                          placeholder="PIN Code"
                        />
                        {errors.pin?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.pin && (
                          <small className="text-danger error">
                            {errors.pin.message}
                          </small>
                        )}
                      </div>
                    </div>
                    {isGoseva && (
                      <div className="col-xl-6">
                        <div className="donationAddOns mb-3">
                          <div className="row">
                            <div className="col-xl-5 col-sm-6">
                              <p>
                                Perform Goseva by feeding the cows.{" "}
                                <span className="sub">(Optional)</span>
                              </p>
                            </div>
                            <div className="col-xl-7 col-sm-6 ps-sm-0 mt-sm-0 mt-2">
                              <div className="donationAddOns-board">
                                <div className="amount">
                                  <img
                                    src="/images/donation/cows-min.png"
                                    alt=""
                                  />
                                  <span>₹120.00</span>
                                </div>
                                <div className="count-wrap">
                                  <div className="count">
                                    <button
                                      type="button"
                                      className={goseva <= 0 ? "disabled" : ""}
                                      onClick={() => {
                                        setGoseva(goseva - 1);
                                        setValue("goseva", goseva - 1);
                                      }}
                                    >
                                      <span>-</span>
                                    </button>
                                    <span className="showCount">{goseva}</span>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setGoseva(goseva + 1);
                                        setValue("goseva", goseva + 1);
                                      }}
                                    >
                                      <span>+</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <span className="sub">
                                Feed a Cow @ Rs.120 for a day. Select no. of
                                Cows to feed
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {isSadhuBhojanSeva && (
                      <div className="col-xl-6">
                        <div className="donationAddOns mb-3">
                          <div className="row">
                            <div className="col-xl-5 col-sm-6">
                              <p>
                                Perform Sadhu Bhojan Seva.{" "}
                                <span className="sub">(Optional)</span>
                              </p>
                            </div>
                            <div className="col-xl-7 col-sm-6 ps-sm-0 mt-sm-0 mt-2">
                              <div className="donationAddOns-board">
                                <div className="amount">
                                  <img
                                    src="/images/donation/sadhu-min.png"
                                    alt=""
                                  />
                                  <input
                                    {...register("sadhuBhojanSeva")}
                                    type="text"
                                    placeholder="Enter Amount"
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <span className="sub">
                                Sponsor Bhojan Seva for Brahmanas. Contribute
                                any amount.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* {isBhagvadGita && (
                      <div className="col-xl-6">
                        <div className="donationAddOns mb-3">
                          <div className="row">
                            <div className="col-xl-5 col-sm-6">
                              <p>
                                Sponsor Printing of Bhagavad Gita for free
                                distribution{" "}
                                <span className="sub"> (Optional)</span>
                              </p>
                            </div>
                            <div className="col-xl-7 col-sm-6 ps-sm-0 mt-sm-0 mt-2">
                              <div className="donationAddOns-board">
                                <div className="amount">
                                  <img
                                    src="/images/donation/bhagavad-gita-min.png"
                                    alt=""
                                  />
                                  <input
                                    {...register("bhagavadGita")}
                                    type="text"
                                    placeholder="Enter Amount"
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <span className="sub">
                                Sponsor Printing of Bhagavad Gita for free
                                distribution in Villages, Schools, Orphanages,
                                Jails, etc. (₹ 160 per book)
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                    {isBhagvadGita && (
                      <div className="col-xl-6">
                        <div className="donationAddOns mb-3">
                          <div className="row">
                            <div className="col-xl-5 col-sm-6">
                              <p>
                                Sponsor Printing of Bhagavad Gita for free
                                distribution{" "}
                                <span className="sub"> (Optional)</span>
                              </p>
                            </div>
                            <div className="col-xl-7 col-sm-6 ps-sm-0 mt-sm-0 mt-2">
                              <div className="donationAddOns-board">
                                <div className="amount">
                                  <img
                                    src="/images/donation/bhagavad-gita-min.png"
                                    alt=""
                                  />
                                  <span>₹ 200.00</span>
                                </div>
                                <div className="count-wrap">
                                  <div className="count">
                                    <button
                                      type="button"
                                      className={
                                        bhagavadGitaPrice <= 0 ? "disabled" : ""
                                      }
                                      onClick={() => {
                                        setBhagavadGitaPrice(
                                          bhagavadGitaPrice - 1
                                        );
                                        setValue(
                                          "bhagavadGita",
                                          bhagavadGitaPrice - 1
                                        );
                                      }}
                                    >
                                      <span>-</span>
                                    </button>
                                    <span className="showCount">
                                      {bhagavadGitaPrice}
                                    </span>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setBhagavadGitaPrice(
                                          bhagavadGitaPrice + 1
                                        );
                                        setValue(
                                          "bhagavadGita",
                                          bhagavadGitaPrice + 1
                                        );
                                      }}
                                    >
                                      <span>+</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <span className="sub">
                                Sponsor Printing of Bhagavad Gita for free
                                distribution in Villages, Schools, Orphanages,
                                Jails, etc. (₹ 200 per book)
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-12">
                      <div className="type_checkbox mt-3">
                        <input
                          type="checkbox"
                          id="memoryOfSomeone"
                          {...register("memoryOfSomeone")}
                        />
                        <label htmlFor="memoryOfSomeone">
                          This Donation in the memory/honor of someone or
                          performed on a specific occasion
                        </label>
                      </div>
                      {showMemoryBox && (
                        <div className="row">
                          <div className="col-lg-2 col-md-6">
                            <div className="form-part">
                              <label htmlFor="mrandms">Title</label>
                              <select {...register("mrandms")}>
                                <option value="">Select Title</option>
                                <option value="mrtitle">Mr</option>
                                <option value="mstitle">Ms</option>
                                <option value="latere">Late</option>
                              </select>

                              {errors.occasion?.message && (
                                <small className="text-danger error">
                                  {errors.occasion?.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-part">
                              <label htmlFor="inNameOf">Name </label>
                              <input
                                type="text"
                                placeholder="In Name Of"
                                {...register("inNameOf", { required: true })}
                              />
                              {errors.inNameOf?.type === "required" && (
                                <small className="text-danger error">
                                  This is required
                                </small>
                              )}
                              {errors.inNameOf?.message && (
                                <small className="text-danger error">
                                  {errors.inNameOf?.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="form-part">
                              <label htmlFor="relationwithDonor">
                                Relation
                              </label>
                              <input
                                type="text"
                                placeholder="Relation"
                                {...register("relationwithDonor")}
                              />

                              {errors.relationwithDonor?.message && (
                                <small className="text-danger error">
                                  {errors.relationwithDonor?.message}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6">
                            <div className="form-part">
                              <label htmlFor="">Relative Mobile Number</label>
                              <input
                                type="tel"
                                placeholder="Phone Number"
                                // onKeyUp={getIcsData}
                                maxLength={10}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                // disabled=
                                {...register("relativephone", {
                                  pattern: {
                                    value: /^[6-9]\d*$/,
                                    message:
                                      "Please provide valid phone number",
                                  },
                                  minLength: {
                                    value: 10,
                                    message:
                                      "Phone number must be minimum 10 digit",
                                  },
                                })}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="form-part">
                              <label htmlFor="occasion">Occasion</label>
                              <select
                                {...register("occasion", { required: true })}
                              >
                                <option value="">Select Occasion</option>
                                <option value="Birthday">Birthday</option>
                                <option value="MarriageAnniversary">
                                  Marriage Anniversary
                                </option>
                                <option value="DeathAnniversary">
                                  Death Anniversary
                                </option>
                                <option value="OtherOccasion">
                                  Any Other Occasion
                                </option>
                              </select>
                              {errors.occasion?.type === "required" && (
                                <small className="text-danger error">
                                  This is required
                                </small>
                              )}
                              {errors.occasion?.message && (
                                <small className="text-danger error">
                                  {errors.occasion?.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="form-part">
                              <label htmlFor="inDate">Occasion Date</label>
                              <div className="input-date">
                                <input
                                  type="date"
                                  placeholder="Date"
                                  {...register("inDate", { required: true })}
                                  min={getCurrentDate()}
                                ></input>
                                {errors.inDate?.type === "required" && (
                                  <small className="text-danger error">
                                    This is required
                                  </small>
                                )}
                                {errors.inNameOf?.message && (
                                  <small className="text-danger error">
                                    {errors.inNameOf?.message}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className="form-part mt-2 memory_field">
                            <textarea
                              type="text"
                              {...register("memoryText")}
                            ></textarea>
                          </div> */}
                          <label htmlFor="">Message/Prayer</label>
                          <textarea
                            type="text"
                            maxLength={250}
                            {...register("memoryText")}
                          ></textarea>
                          <small>* Maximum 250 characters allowed</small>
                        </div>
                      )}
                    </div>
                    {/* <div className="col-12">
                      <div className="type_checkbox mt-3">
                        <input
                          type="checkbox"
                          id="memoryOfSomeone"
                          {...register("memoryOfSomeone")}
                        />
                        <label htmlFor="memoryOfSomeone">
                          Is this donation in memory of someone or performed on
                          a specific day?
                        </label>
                      </div>
                      {showMemoryBox && (
                        <div className="form-part mt-2 memory_field">
                          <textarea
                            type="text"
                            maxLength={250}
                            {...register("memoryText")}
                          ></textarea>
                          <small>* Maximum 250 characters allowed</small>
                        </div>
                      )}
                    </div> */}
                    <div className="col-12">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        ref={recaptchaRef}
                        size="invisible"
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <button
                        type="submit"
                        className="box-hover custom-btn-cls donation_btn ms-0 donate-now-clicked-form"
                        disabled={loading ? true : false}
                      >
                        {loading ? (
                          <div className="d-flex align-items-center">
                            <div
                              className="spinner-border text-light"
                              role="status"
                            ></div>
                            <div className="ms-2">DONATING...</div>
                          </div>
                        ) : (
                          <>
                            {Soa ? (
                              <>
                                DONATE NOW ₹
                                {Soa > 0 &&
                                  `${Soa.toLocaleString("en-IN", {
                                    maximumFractionDigits: 0,
                                    style: "currency",
                                    currency: "INR",
                                  })}`}
                                /-
                              </>
                            ) : (
                              <>
                                DONATE NOW{" "}
                                {totalAmount > 0 &&
                                  `${totalAmount.toLocaleString("en-IN", {
                                    maximumFractionDigits: 0,
                                    style: "currency",
                                    currency: "INR",
                                  })}/-`}
                              </>
                            )}
                          </>
                        )}
                      </button>
                      <div className="form-part mt-4 me-lg-2 d-md-none ">
                        <div className="notes-wrap mt-0">
                          <p className="text-center">
                            <span> Please Note:</span> Complete Address with
                            PIN-Code and PAN is mandatory for an 80G Receipt.
                          </p>
                        </div>
                      </div>
                      {showOtp && (
                        <>
                          <div className="form-part d-inline-block my-2">
                            <input
                              type="text"
                              placeholder="Enter OTP"
                              maxLength={6}
                              minLength={6}
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {!showresendlink && showTimer && (
                              <span
                                style={{
                                  color: "#9F1D21",
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                {getTime(otpExp)}s
                              </span>
                            )}
                          </div>
                          <>
                            {showresendlink && (
                              <button
                                className="custom-btn-cls box-hover"
                                onClick={resendOtp}
                              >
                                RESEND
                              </button>
                            )}
                          </>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {eightyg && (
            <div className="donate-note my-5">
              <p>
                Note: AVAIL 80G BENEFITS ON THE DONATIONS MADE TO ISKCON
                BHIWANDI
              </p>
              <p>
                (Preaching center - ISKCON JUHU) AS PER THE INCOME TAX ACT 1961
                AND RULES MADE THERE UNDER.
              </p>
              <p>
                "Tax Exemption order ref no. AAATI0017PF20219 dated 24/09/2021
                valid upto 31/03/2026"
              </p>
            </div>
          )}
        </div>
      </section>
      <GoToTop top={top} />
    </>
  );
};

export default DonationForm;