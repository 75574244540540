import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import PageHeader from "../components/common/PageHeader";
import AppContext from "../context/AppContext";
import MetaData from "../helpers/MetaData";
import { useForm } from "react-hook-form";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

const DmsUpdateProfile = () => {
  const { fetchDonor } = useContext(AppContext)
    const search = useLocation().search;
  const link_id = new URLSearchParams(search).get("link");
  //const { id } = useParams();
  const location = useLocation();
  const id = location.state?.donorId;
  //console.log(id)
  const sectionRef = useRef(null);
  const [donorData, setDonorData] = useState(null);
  const [states, setStates] = useState([]);
  // console.log("donorData", donorData);

  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  const navigate = useNavigate();

  const icsProfileUpdate = async (data) => {
    try {
      let profileUpdateData = {
        icsid: donorData?.ics_id,
        name: data.name,
        number: donorData?.phone,
        email: data.email,
        panNo: data.pan,
        dob: '',
        ma: '',
        addressLine1: data.address,
        addressLine2: data.streetName,
        addressLine3: data.areaName,
        city: data.city,
        state: data.state,
        country: 'India',
        pincode: data.pin,
        followupCategory: data.comm,
        followupDescription: 'followupDescription',
        mode: 'STP',
      }

      const response = await ApiService.icsUpdate(profileUpdateData);

      if (response) {
        navigate('/dashboard');
      }

    } catch (error) {
      console.error(error);
    }
  }

  const handleProfileUpdate = async (data) => {

    setIsUpdateLoading(true);
    try {
      data.link_click = link_id ? 'yes' : 'No';
      const response = await ApiService.updateDonor({ id, data });
      const response1 = await ApiService.updateProfile(data);

      notification("success", response.message);
      fetchDonorById();
      icsProfileUpdate(data);
      fetchDonor();
      navigate("/dashboard")
    } catch (error) {
      console.log(error);
    } finally {
      setIsUpdateLoading(false); // Stop loading
    }
  };

  const fetchDonorById = async () => {
    try {
      const response = await ApiService.fetchDonorById(id);
      if (response.donor) {
        setDonorData(response.donor);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const fetchStateCity = async (pin) => {
    try {
      let url = `https://api.postalpincode.in/pincode/${pin}`;

      let config = {
        method: "GET",
        url,
      };
      const { data } = await axios(config);

      let cityVal = '';
      if(data[0].PostOffice.length > 1) {
        data[0].PostOffice.map(ct => {
          if(ct.Block !== 'NA') {
            return cityVal = ct.Block
          }
        })
      }else {
        cityVal = data[0].PostOffice[0].Block;
      } 

      if (data[0].Status === "Success") {
        setValue("city", cityVal !== '' ? cityVal : data[0].PostOffice[0].District);
        setValue("state", data[0].PostOffice[0].State);
      } else {
        notification("warning", "Enter valid PIN code!");
      }
    } catch (err) {
      console.log(err);
    }
  };


  const checkValidPin = async (pin) => {
    try {
      let url = `https://api.postalpincode.in/pincode/${pin}`;
      let config = {
        method: "GET",
        url,
      };
      const { data } = await axios(config);

      if (data[0].Status === "Error") {
        notification("warning", "Enter valid PIN code!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchStates = async () => {
    try {
      var config = {
        method: "GET",
        url: "https://api.countrystatecity.in/v1/countries/IN/states",
        headers: {
          "X-CSCAPI-KEY": process.env.REACT_APP_CSCKEY,
        },
      };

      let res = await axios(config);

      let sortedArray = res.data.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });

      setStates(
        sortedArray.map((item) => ({ value: item.iso2, label: item.name }))
      );
      // console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (donorData) {
      reset({
        name: donorData.legal_name,
        phone: donorData.phone,
        email: donorData.email,
        pan: donorData.pan,
        address_line_1: donorData?.addresses[0]?.address_line_1,
        pin: donorData?.addresses[0]?.pincode,
        state: donorData?.addresses[0]?.state,
        city: donorData?.addresses[0]?.city,
        address_line_2: donorData?.addresses[0]?.address_line_2,
        landmark: donorData?.addresses[0]?.landmark,
      });
    }
  }, [donorData]);

  useEffect(() => {
    fetchStates();
    fetchDonorById();
  }, []);

  return (
    <>
      <MetaData title="Dashboard - ISKCON of Bhiwandi" />
      <PageHeader title="User profile" imgSrc="/images/page-header/24.jpg" />

      <section className="donation-form-sec">
        <div className="container">
          <div className="row d-flex justify-content-center mb-5">
            <div className="col-md-10">
              <div className="heading">
                <h2 className="head-1 text-uppercase">Update Profile</h2>
                <p className="b-line">
                  If you would like to make a donation towards a particular area
                  of activity, please select an option from below. ISKCON relies
                  entirely on voluntary donations and so every donation counts.
                  Please note that donation is processed on a secure site.
                </p>
              </div>
            </div>
            <div ref={sectionRef} className="form-wrap my-5">
              <form onSubmit={handleSubmit(handleProfileUpdate)}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Name</label>
                      <input
                        type="text"
                        disabled={true}
                        placeholder="Name"
                        {...register("name")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Mobile Number</label>
                      <input
                        type="tel"
                        placeholder="Mobile Number"
                        disabled={true}
                        {...register("phone")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Email</label>
                      <input
                        type="email"
                        placeholder="Email"
                        {...register("email")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">PAN</label>
                      <input
                        type="text"
                        style={{ textTransform: "uppercase" }}
                        maxLength={10}
                        disabled={donorData?.pan ? true : false}
                        placeholder="PAN"
                        {...register("pan", {
                          pattern: {
                            value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                            message: "Please enter valid PAN",
                          },
                        })}
                      />
                       {errors.pan?.type === "required" && (
                        <small className="text-danger error">
                          This is required
                        </small>
                      )}
                      {errors.pan && (
                        <small className="text-danger error">
                          {errors.pan.message}
                        </small>
                      )}
                      {donorData?.pan ?
                       <small style={{ whiteSpace: "nowrap" }}>
                       * Please contact to support team for changes in the PAN number on{" "}
                       <a href="mailto:support@iskconbhiwandi.org" style={{ display: "inline" }}>
                         support@iskconbhiwandi.org
                       </a>
                     </small>
                     
                        : ''}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Address with landmark</label>
                      <textarea
                        rows={6} 
                        {...register("address_line_1", {
                          required: true,
                          minLength: {
                            value: 10,
                            message: "must be minimum 10 characters",
                          },
                        })}
                        placeholder="Address 1"
                      />
                      {errors.address_line_1?.type === "required" && (
                        <small className="text-danger error">This is required</small>
                      )}
                      {errors.address_line_1 && (
                        <small className="error">{errors.address_line_1.message}</small>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 d-none">
                    <div className="form-part">
                      <label htmlFor="">Landmark</label>
                      <input
                        type="text"
                        placeholder="Landmark"
                        {...register("landmark")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 d-none">
                    <div className="form-part">
                      <label htmlFor="">Address 2</label>
                      <input
                        type="text"
                        placeholder="Address 2"
                        {...register("address_line_2")}
                      />
                    </div>
                  </div>


                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">PIN</label>
                      <input
                        type="tel"
                        maxLength={6}
                        {...register("pin", {
                          required: true,
                          pattern: {
                            value: /^\d+$/,
                            message: "PIN code must be number.",
                          },
                          minLength: {
                            value: 6,
                            message: "Please enter a valid PIN code",
                          },
                          maxLength: {
                            value: 6,
                            message: "Please enter a valid PIN code",
                          },
                          onChange: (e) => {
                            setValue("state", "");
                            setValue("city", "");
                            if (e.target.value.length === 6) {
                              fetchStateCity(e.target.value);
                              checkValidPin(e.target.value);
                            }
                          },
                        })}
                        placeholder="PIN"
                      />
                      {errors.pin?.type === "required" && (
                        <small className="text-danger error">
                          This is required
                        </small>
                      )}
                      {errors.pin && (
                        <small className="text-danger error">
                          {errors.pin.message}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">State</label>
                      <input
                        type="text"
                        placeholder="State"
                        {...register("state")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">City</label>
                      <input
                        type="text"
                        placeholder="City"
                        {...register("city")}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <button className="box-hover custom-btn-cls ms-0 mt-md-0 mt-3 donate-now-clicked-form"
                      disabled={isUpdateLoading}>
                      {isUpdateLoading && <div className="spinner-border text-light me-1"></div>}
                      submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DmsUpdateProfile;
