import React, { useEffect, useState, useCallback } from "react";
import GoToTop from "../../helpers/GoToTop";
const AboutFestivals = () => {
    const [top, setTop] = useState(0);
    const festRef = useCallback((node) => {
        if (node) {
            setTop(node.offsetTop - 45);
        }
    }, []);
    return (
        <>
            <section className="welcome-festival" ref={festRef}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="welcome-content">
                                <div className="heading">

                                    <h2 className="head-1">Recent Events</h2>
                                </div>
                                <p className="b-line"></p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <GoToTop top={top} />
        </>
    )
}

export default AboutFestivals