import React, { useEffect, useState, useCallback } from "react";
import GoToTop from "../../helpers/GoToTop";

const AboutFestivals = () => {
    const [top, setTop] = useState(0);
    const festRef = useCallback((node) => {
        if (node) {
            setTop(node.offsetTop - 45);
        }
    }, []);
    return (
        <>
            <section className="welcome-festival" ref={festRef}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="welcome-content">
                                <div className="heading">

                                    <h2 className="head-1">COME, CELEBRATE THE LORD</h2>
                                </div>
                                <p className="b-line">Every breath in Krishna Consciousness is celebratory and yet there are a few occasions that further elevate the unceasing joy of Bhakti, of servitude and love.
                                    Srila Prabhupada described festivals as "the mothers of devotion”— days when the nectar of Bhakti is available to one and all and at Sri Sri Radha Madhava Temple
                                    festivals are celebrated with splendour, fervour and piety in equal measure. Come join in the festivities. Hare Krishna !</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <GoToTop top={top} />
        </>
    )
}

export default AboutFestivals