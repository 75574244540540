import PageHeader from "../components/common/PageHeader";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import GoToTop from "../helpers/GoToTop";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import ApiService from "../api/ApiService";
import { useForm, Controller } from "react-hook-form";
import notification from "../helpers/notification";
import MetaData from "../helpers/MetaData";
import { BsCheckLg } from "react-icons/bs";
import Fancybox from "../helpers/Fancybox";
import Skeleton from "react-loading-skeleton";
import { BsFillPlayCircleFill } from "react-icons/bs";
import AppContext from "../context/AppContext";
import axios from "axios";
import moment from "moment/moment";
import Tooltip from '@mui/material/Tooltip';

const expiry = process.env.REACT_APP_OTPEXPIRY;
let timer = null;

const CampaignDonationForm = () => {
  const { user, setToken, token, donor, profiles } = useContext(AppContext);
  const formRef = useRef();
  const navigate = useNavigate();
  const { slug, amount } = useParams();

  const search = useLocation().search;
  const donationId = new URLSearchParams(search).get("donationId");
  const anyAmount = new URLSearchParams(search).get("anyAmount");
  const smsCampaign = new URLSearchParams(search).get('sms-campaign') ?? localStorage.getItem('tracking_campaign');

  const [popularDonation, setPopularDonations] = useState([]);
  const [campaign, setCampaign] = useState(null);
  const [campaignEightyG, setCampaignEightyg] = useState(false);

  const [donationType, setDonationType] = useState(null);
  const [donationImg, setDonationImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMemoryBox, setShowMemoryBox] = useState(false);
  const [donationAmount, setDonationAmount] = useState(
    amount === "any" ? (anyAmount ? anyAmount : 30) : amount
  );
  const [readonly, setReadOnly] = useState(true);
  const [goseva, setGoseva] = useState(0);
  const [bhagavadGitaPrice, setBhagavadGitaPrice] = useState(0);
  const [isGoseva, setIsGoseva] = useState(false);
  const [isSadhuBhojanSeva, setIsSadhuBhojanSeva] = useState(false);
  const [isBhagvadGita, setIsBhagvadGita] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [isVerified, setIsverified] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpExp, setOtpExp] = useState(expiry);
  const [showresendlink, setShowResendLink] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [totalAmount, setTotalAmount] = useState([]);
  const [isTrackingCampaign, setIsTrackingCampaign] = useState(false);
  const [icsdontype, setIcsDontype] = useState('A General Donation');
  const currentDate = new Date();
  const formattedDate = currentDate.toDateString();
  const [top, setTop] = useState(0);

  const [ics_data, setICSdata] = useState()
  const [ics_address, setIcsAddress] = useState([])
  const [ics_pincode, setIcsPincode] = useState([])
  const [icsdatawl, setICSdatawl] = useState([])
  const [item_data, setItemData] = useState([])
  const [wl_loginics, setWithlogin] = useState([])
  const [wl_icsname, setIcsname] = useState("")
  const [final_amount, setFinalAmount] = useState([])
  const [reqpin, setReqpin] = useState(false);

  //console.log(item_data);
 
  //if(slug){
  // localStorage.setItem('ics_id', item_data.id)

  //  setTimeout(() => {
  //   const scrollToDonateFromRef = document.getElementById('scrollToAllPages');
  //   if (scrollToDonateFromRef) {
  //     const offset = -600;
  //     const targetScrollTop = scrollToDonateFromRef.offsetTop - offset;
  //     const container = document.documentElement;
  //     container.scrollTop = targetScrollTop;
  //   } else {
  //     console.error("Element scrollToDonateFromRef not found!");
  //   }
  // }, 1000);
  //}

  //console.log(icsdontype)
  // console.log(amount)

  const campaignRef = useCallback((node) => {
    if (node) {
      let headerHeight;
      if (window.innerWidth > 991) {
        headerHeight = 121;
      } else if (window.innerWidth > 576) {
        headerHeight = 80;
      } else {
        headerHeight = 73;
      }
      setTop(node?.offsetTop - headerHeight);
    }
  });


  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: {
      donationAmount,
      donateBtns: amount === "any" ? "any" : donationAmount,
      state: "",
      city: "",
    },
  });

  const watchSomeoneMemory = watch("memoryOfSomeone");
  const addressWatch = watch('address');
  const pinWatch = watch('pin');

  const fetchStateCity = async (pin) => {
    try {
      let url = `https://api.postalpincode.in/pincode/${pin}`;

      let config = {
        method: "GET",
        url,
      };
      const { data } = await axios(config);

      let cityVal = '';
      if (data[0].PostOffice.length > 1) {
        data[0].PostOffice.map(ct => {
          if (ct.Block !== 'NA') {
            return cityVal = ct.Block
          }
        })
      } else {
        cityVal = data[0].PostOffice[0].Block;
      }

      if (data[0].Status === "Success") {
        setValue("city", cityVal !== '' ? cityVal : data[0].PostOffice[0].District);
        setValue("state", data[0].PostOffice[0].State);
      } else {
        notification("warning", "Enter valid PIN code!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCampaign = async () => {
    try {
      // console.log('hit')
      let { campaign, isTrackingCampaign } = await ApiService.fetchCampaign(slug);
      setIsGoseva(campaign.goseva);
      setIsSadhuBhojanSeva(campaign.sadhuBhojanSeva);
      setIsBhagvadGita(campaign.bhagvadGita);
      setCampaign(campaign);
      setCampaignEightyg(campaign?.donateBtns?.filter(dt => dt.amount == donationAmount)[0]?.schemEightyg);

      setIsTrackingCampaign(smsCampaign ? true : false);
      setGoseva(0);
      setBhagavadGitaPrice(0)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const showBox = watch((value) => {
      // console.log(value)
      const { donationAmount, sadhuBhojanSeva, bhagavadGita, goseva } = value;
      let total = (donationAmount ? Number(donationAmount) : 0) + (sadhuBhojanSeva ? Number(sadhuBhojanSeva) : 0) + (bhagavadGita ? Number(bhagavadGita) * 200 : 0) + (goseva ? (Number(goseva) * 120) : 0);
      setTotalAmount(total)
      setShowMemoryBox(value.memoryOfSomeone)
    });
    return () => showBox.unsubscribe();
  }, [watch]);

  useEffect(() => {
    fetchCampaign();
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
    formRef.current.scrollIntoView({ behavior: "smooth" });
    fetchMeta("donation-form");
  }, [anyAmount]);

  useEffect(() => {
    if (amount === "any") {
      setReadOnly(false);
    }
  }, [amount]);


  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };
  useEffect(() => {

    if (donor) {
      setIsverified(true);
      // TODO: reset
      reset({
        name: donor.legal_name,
        phone: donor.phone,
        email: donor.email,
        pan: donor.pan,
        ...(donor.addresses?.length > 0 && {
          address: `${donor?.addresses[0]?.address_line_1 ?? ''} ${donor?.addresses[0]?.address_line_2 ?? ''}`,
          pin: donor?.addresses[0]?.pincode,
        }),
        donationAmount,
        donateBtns: amount === "any" ? "any" : donationAmount,
      });

      if (donor?.addresses[0]?.pincode) {
        fetchStateCity(donor?.addresses[0]?.pincode)
      }
    }

    if (!donor && donationAmount) {
      reset({
        donationAmount,
        donateBtns: amount === "any" ? "any" : donationAmount,
      })
    }

  }, [donor]);

  function getTime(time) {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  }

  const handleSendOtp = async (phone) => {
    if (timer) {
      clearInterval(timer);
    }
    if (phone.length >= 10) {
      try {
        const res = await ApiService.sendOtp({ number: phone });
        notification("success", res.message);
        setShowTimer(true);
        timer = setInterval(() => {
          setOtpExp((prev) => prev - 1);
        }, 1000);
      } catch (err) {
        console.log(err);
        notification("error".err.response.data.message);
      }
    }
  };

  const handleVerifyOtp = async (otp) => {
    try {
      let otpData = {
        number: getValues("phone"),
        otp,
      };
      //   console.log(otpData);
      const res = await ApiService.loginUser(otpData);
      //   console.log(res);
      localStorage.setItem("token", res.token);
      setToken(res.token);
      notification("success", res.message);
      setOtp("");
      setIsverified(true);
      setShowOtp(false);

      madeDonation(getValues());
    } catch (error) {
      console.log(error);
      notification("error", error.response.data.message);
    }
  };

  const ics_id = localStorage.getItem('ics_id')

  let ics_idd = ""

  if (token) {
    if (item_data.id) {
      ics_idd = item_data.id;
    } else {
      ics_idd = wl_loginics;
    }

  } else {
    if (item_data.id) {
      ics_idd = item_data.id
    } else {
      ics_idd = ics_id;
    }



  }

  //console.log(ics_idd)



  const sendDonationICS = async (data, idd, final_amt) => {
    const pay_idd = localStorage.getItem('my-key');
    const ics_id = localStorage.getItem('ics_id');

    let address = "";
    let pincode = "";
    if (data.address) {
      address = data.address;
    } else if (item_data.length > 0) {
      //  if (item_data.address.line1) {
      //    address = item_data.address.line1 + ', '+ item_data.address.city +', '+item_data.address.state;
      //    data.city = item_data.address.city;
      //    data.state = item_data.address.state;
      //    pincode = item_data.address.pincode;
      //  } else {
      //    if (icsdatawl.length > 0) {
      //      if (icsdatawl[0].address.line1) {
      //        address = icsdatawl[0].address.line1 + ', '+icsdatawl[0].address.city+ ', '+icsdatawl[0].address.state;
      //        data.city = icsdatawl[0].address.city;
      //        data.state = icsdatawl[0].address.state;
      //        pincode = icsdatawl[0].address.pincode;
      //      } 
      //    }
      //  }
    } else if (icsdatawl.length > 0) {
      if (icsdatawl[0].address.line1) {
        address = icsdatawl[0].address.line1 + ', ' + icsdatawl[0].address.city + ', ' + icsdatawl[0].address.state;
        data.city = icsdatawl[0].address.city;
        data.state = icsdatawl[0].address.state;
        pincode = icsdatawl[0].address.pincode;
      }
    }
    //console.log(data.pin);
    if (data.address) {
      if (data.pin) {
        pincode = data.pin;
        // console.log(pincode);
      }

    }

    try {

      let ics_id = ""

      if (token) {
        if (item_data.id) {
          ics_id = item_data.id;
        } else {
          ics_id = wl_loginics;
        }

      } else {
        if (item_data.id) {
          ics_id = item_data.id
        } else {
          ics_id = ics_id;
        }



      }


      const ress = await ApiService.getIcsData(data.phone)
      //setICSdatawl(ress.data);
      //console.log(ress.data[0].address.line1);

      let icsData = {
        don_id: idd,
        icsid: '',
        donorName: wl_icsname ? wl_icsname : data.name,
        donorEmail: data.email,
        donorContact: data.phone,
        donorPAN: item_data.pan ? item_data.pan.toUpperCase() : data.pan.toUpperCase(),
        donorCity: data.city ? data.city : '',
        donorState: data.state ? data.state : '',
        donorCountry: 'India',
        donorAddress: data.address ? data.address : ress.data[0].address.line1 + ', ' + ress.data[0].address.city + ', ' + ress.data[0].address.state,
        donorPin: data.pin ? data.pin : ress.data[0].address.pincode,
        amount: final_amt,
        schemeName: 'A General Donation',
        modeName: 'online',
        intrumentNo: pay_idd,
        comments: data.memoryText ? data.memoryText : '',
        instrumentDate: moment(formattedDate).format('DD-MM-YYYY'),
        bankName: 'Razorpay',
        bankBranch: '',
      }


      const respon = await ApiService.sendDataToIcs(icsData)



      // let sendDataToICS = {
      //   method: "POST",
      //   url,
      // };
      // const response  = await axios(sendDataToICS);


      if (respon) {
        localStorage.removeItem('my-key');
        localStorage.setItem('ics_id', ics_id);
        localStorage.setItem('updtpnid', ics_id);
        localStorage.setItem('updtpnno', item_data.pan ? item_data.pan.toUpperCase() : '');
      }
      //console.log(response.data.data);
      // console.log(response.data.data.recieptURL);
      // console.log(ics_id);

      const jsonData = JSON.stringify(respon);

      let AllData = {
        donor_id: idd,
        checkEightyG: respon.checkEightyG,
        receiptDate: respon.receiptDate,
        rbno: respon.rbno,
        rno: respon.rno,
        amount: respon.amount,
        mode: respon.mode,
        bankName: respon.bankName,
        bankBranch: respon.bankBranch,
        instrumentNo: respon.instrumentNo,
        instrumentDate: respon.instrumentDate,
        receiptURL: respon.receiptURL,
        donorName: respon.donorName,
        donorAddress: respon.donorAddress,
        donorCity: respon.donorCity,
        donorState: respon.donorState,
        donorCountry: respon.donorCountry,
        donorPin: respon.donorPin,
        donorContact: respon.donorContact,
        donorEmail: respon.donorEmail,
        donorPAN: respon.donorPAN

      }

      const res = await ApiService.downloadNon80g(AllData)

      localStorage.setItem('responseData', jsonData);


    } catch (err) {

      console.log(err);

    }


  }

  const formatName = (name) => {
    return name
      .split(" ") // Naam ko space ke basis pe todna
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Har word ka pehla letter capital baaki small
      .join(" "); // Wapas string me jodna
  };


  const madeDonation = async (data) => {
    try {
      data.goseva = goseva * 120;
      data.bhagavadGita = bhagavadGitaPrice * 200
      data.name = wl_icsname ? formatName(wl_icsname) : formatName(data.name)
      // console.log(smsCampaign);
      data.userType = donor ? 'user' : 'guest';
      const res = await ApiService.donateCampaign({
        ...data,
        campaign: campaign._id,
        isTrackingCampaign,
        trackingCampaign: smsCampaign,
        ...((donor && donor?.id) && { donorId: donor.id }),
      });

      setFinalAmount(res.amount / 100);

      const final_amt = res.amount / 100

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        currency: res.currency,
        amount: res.amount,
        name: process.env.REACT_APP_PROJECT_NAME,
        description: "Help Us to Help the Society",
        image: `${process.env.REACT_APP_S3_URL}/assets/img/logo.png`,
        order_id: res.id,
        handler: function (response) {
          const textForStorage = response.razorpay_payment_id
          localStorage.setItem('my-key', textForStorage);
          const textFromStorage = localStorage.getItem('my-key');
          if (textFromStorage) {
            // sendDonationICS(data,res.donationId,final_amt);
            localStorage.removeItem('text_id');
            navigate(`/thank-you/${res.donationId}/donation`, { replace: true });
            localStorage.setItem('multiplesevaPrice', '');
            localStorage.setItem('multiplesevaName', '');
            localStorage.setItem('SpecialOccassionAmount', '');
            localStorage.setItem(`donation-${res.donationId}`, res.donationId);
          }
        },
        prefill: {
          name: res.name ? res.name : "",
          email: res.email ? res.email : "",
          contact: res.phone ? res.phone : "",
        },
        notes: {
          type: "donation",
        },
        theme: {
          color: "#FFD370",
        },
        modal: {
          ondismiss: async function () {
            const cancelCampaign = await ApiService.cancelCampaign(res.id);
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", function (response) {
        navigate(`/payment-failed?id=${res.donationId}&&type=campaign${isTrackingCampaign ? `&&sms-campaign=${smsCampaign}` : ''}`, {
          replace: true,
        });
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      notification("error", "Unable to make donation, try again later!");
    }
  };

  const handleDonate = async (data) => {
    let donor_name;
    if (token) {
      if (!isNaN(data.name)) {
        if (data.name == donor.id) {
          donor_name = donor.legal_name;
        } else {
          donor_name = profiles?.find((profile) => profile?.id == data?.name)?.legal_name;
        }
        data.name = donor_name;
      }
    }

    setLoading(true);
    madeDonation(data);
  };

  const resendOtp = () => {
    setOtpExp(expiry);
    handleSendOtp(getValues("phone"));
    setShowResendLink(false);
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const handleDonationTypechange = (e) => {
    const indx = e.nativeEvent.target.selectedIndex;
    setIcsDontype(e.nativeEvent.target[indx].text);
    // console.log(e.target.value)
    if (!(e.target.value === "any")) {
      setDonationAmount(e.target.value);
      // reset({
      //   donationAmount: e.target.value,
      // });
      setCampaignEightyg(campaign?.donateBtns?.filter(dt => dt.amount == e.target.value)[0]?.schemEightyg);
      setValue('donationAmount', e.target.value)
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    // navigate(`/campaign-donation/${campaign.slug}/${e.target.value}`, { replace: true })
  };

  // For Meta Tags
  const [meta, setMeta] = useState(null);
  const fetchMeta = async (pagename) => {
    try {
      let res = await ApiService.fetchMetaData(pagename);
      setMeta(res);
    } catch (error) {
      console.log(error);
    }
  };

  const retryDonation = async () => {
    try {
      const res = await ApiService.retryDonation(donationId);
      setIsSadhuBhojanSeva(false);
      setIsBhagvadGita(false);
      setIsGoseva(false);
      setGoseva(0)
      setBhagavadGitaPrice(0)
      setShowMemoryBox(res?.memoryOfSomeone);
      let addOns = {};
      for (let i = 0; i < res.donationAddOns.length; i++) {
        switch (res.donationAddOns[i].name) {
          case "sadhuBhojanSeva":
            addOns.sadhuBhojanSeva = res.donationAddOns[i].amount;
            setIsSadhuBhojanSeva(true);
            break;
          case "bhagavadGita":
            addOns.bhagavadGita = Number(res.donationAddOns[i].amount) / 200;
            setBhagavadGitaPrice(Number(res.donationAddOns[i].amount) / 200);
            setIsBhagvadGita(true);
            break;
          case "goseva":
            addOns.goseva = Number(res.donationAddOns[i].amount) / 120;
            setGoseva(Number(res.donationAddOns[i].amount) / 120);
            setIsGoseva(true);
            break;
          default: break;
        }
      }
      console.log(addOns)
      reset({
        ...res,
        ...addOns,
        donationAmount: res.amount,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (donationId) {
      retryDonation();
    }
  }, [showMemoryBox]);

  useEffect(() => {
    if (!user) {
      if (otpExp === 0) {
        setShowTimer(false);
        setShowResendLink(true);
        clearInterval(timer);
        setOtpExp(expiry);
      }
    }
  }, [otpExp, expiry, user]);

  // const handleGetIcsData = async (aa) => {
  //   const res = await ApiService.getIcsData(aa)

  //   setICSdata(res.data);
  //   if (res.data[0].id > 0) {

  //     if (!token) {
  //       setLoginPopup(true);
  //     }

  //   }
  // }


  const handleGetIcsData = async (id) => {
    const res = await ApiService.fetchDonorById(id);
    console.log("DMS DATA --", res.donor)
    setICSdata(res.donor);
    if (res.donor.id > 0) {
      if (!token) {
        setLoginPopup(true);
      }
    }
  };



  const handelOther = (e) => {
    const currentValues = getValues()

    setICSdata('');
    reset({
      ...currentValues,
      name: "",
      pan: "",
      address: "",
      pin: "",
      email: "",
      donationAmount,
      isSadhuBhojanSeva,
      isBhagvadGita,
      goseva,
    });
  }

  const handelOptions = (e) => {
    const currentValues = getValues()
    // TODO: reset
    reset({
      ...currentValues,
      name: donor?.legal_name,
      phone: donor?.phone,
      email: donor?.email,
      pan: donor?.pan,
      ...(donor.addresses?.length > 0 && {
        address: `${donor?.addresses[0]?.address_line_1 ?? ''} ${donor?.addresses[0]?.address_line_2 ?? ''}`,
        pin: donor?.addresses[0]?.pincode,
      }),
      donationAmount,
      isSadhuBhojanSeva,
      isBhagvadGita,
      goseva,
      isGoseva,
      bhagavadGitaPrice,
      donateBtns: amount === "any" ? "any" : donationAmount,
    });

    if (donor?.addresses[0]?.pincode) {
      fetchStateCity(donor?.addresses[0]?.pincode)
    }

    setICSdata(donor)
  }




  // const handleSelectProfiles = (e) => {
  //   // console.log(ics_data)
  //   setItemData([])
  //   //console.log(e.target.value)
  //   const responseDataa = ics_data.filter(dat => dat.id == e.target.value);
  //   // console.log(responseDataa)
  //   if (token) {
  //     setWithlogin(responseDataa[0].id)
  //     setIcsname(responseDataa[0].name)
  //   }

  //   reset({
  //     phone: responseDataa[0].number ? responseDataa[0].number.trim() : '',
  //     email: responseDataa[0].email ? responseDataa[0].email.emailAddress.trim() : '',
  //     pan: responseDataa[0].pan ? responseDataa[0].pan.trim() : '',
  //     address: responseDataa[0].address.line1 ? responseDataa[0].address.line1.trim() : '',
  //     pin: responseDataa[0].address.pincode ? responseDataa[0].address.pincode.trim() : '',
  //   });

  // }

  const handleSelectProfiles = (e) => {
    const donorId = e.target.value;
    const currentValues = getValues()
    if (donor) {
      let _donor;
      if (donorId == donor?.legal_name) {
        _donor = donor
      } else {
        _donor = profiles.find((profile) => profile.legal_name == donorId);
      };

      if (_donor) {
        reset({
          ...currentValues,
          name: _donor?.legal_name,
          phone: _donor.phone,
          email: _donor.email,
          pan: _donor.pan,
          pin: _donor?.addresses[0] ? _donor?.addresses[0]?.pincode : '',
          address: _donor?.addresses[0] ? `${_donor?.addresses[0]?.address_line_1 ?? ''} ${_donor?.addresses[0]?.address_line_2 ?? ''}` : '',
          donationAmount,
          isSadhuBhojanSeva,
          isBhagvadGita,
          goseva,
          bhagavadGitaPrice,
          isGoseva,
        });

        if (_donor?.addresses[0] && _donor?.addresses[0]?.pincode) {
          fetchStateCity(_donor?.addresses[0]?.pincode);
        }
      }
    }
  };


  const { loginPopup, setLoginPopup } = useContext(AppContext);


  const CheckAddress = (e) => {
    //console.log('check adress -- ',e.target.value.length);
    if (e.target.value.length > 0) {
      setReqpin(true);
    } else {
      setReqpin(false);
    }
  }

  useEffect(() => {
    campaignRef();
    if (token && profiles?.length > 0) {
      handleGetIcsData(profiles[0]?.id);
    }
  }, [token, profiles]);

  return (
    <>
      <MetaData title="Donation Form - ISKCON of Bhiwandi" />
      <PageHeader
        title="Campaign Donation form"
        imgSrc="/images/page-header/17.webp"
      />
      <section className="donation-form-sec" ref={campaignRef}>
        <div className="container" id="scrollToAllPages">
          <div className="row d-flex justify-content-center mb-5">
            <div className="col-md-10">
              <div className="heading">
                <h2 className="head-1">QUICK DONATE</h2>
                <p className="b-line">
                  If you would like to make a donation towards a particular area
                  of activity, please select an option from below. ISKCON relies
                  entirely on voluntary donations and so every donation counts.
                  Please note that donation is processed on a secure site.
                </p>
              </div>
            </div>
          </div>
          <div
            ref={formRef}
            className="form-wrap my-5"
            style={{ scrollMarginBlockStart: "150px" }}
          >
            {campaign && (
              <form onSubmit={handleSubmit(handleDonate)}>
                <div className="row align-items-start">
                  <div className="col-lg-4 pe-xl-4">
                    <div className="donate-img">
                      <figure className="up-right">
                        {campaign ? (
                          <img
                            src={`${process.env.REACT_APP_S3_URL}/uploads/campaigns/payment-form-images/${campaign.paymentFormImage}`}
                            alt={campaign.title}
                          />
                        ) : (
                          <Skeleton height={320} />
                        )}
                      </figure>
                    </div>
                    {campaignEightyG && (
                      <div className="form-part mt-4 me-lg-2">
                        <div className="notes-wrap mt-0">

                          <p className="text-center">
                            <span> Please Note:</span> Complete Address with
                            PIN-Code and PAN is mandatory for an 80G Receipt.
                          </p>

                        </div>
                      </div>
                    )}

                    {anyAmount && (
                      <div className="form-part mt-4 me-lg-2">
                        <div className="notes-wrap mt-0">

                          <p className="text-center">
                            <span> Please Note:</span> Complete Address with
                            PIN-Code and PAN is mandatory for an 80G Receipt.
                          </p>

                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-8 mt-lg-0 mt-4 ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-part">
                          <label htmlFor="">Donation For</label>
                          <select
                            {...register("donateBtns", {
                              required: true,
                              onChange: (e) => {
                                handleDonationTypechange(e);
                              },
                            })}
                          >
                            {campaign.donateBtns.map((data, i) => (
                              <option key={i} value={data.amount}>
                                {data.title}
                              </option>
                            ))}
                            <option value={"any"}>Donate any amount</option>
                          </select>
                          {/* {errors.donateBtns?.type === 'required' && <span className="error text-danger">This is required</span>} */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-part">
                          <label htmlFor="">Amount</label>
                          <input
                            type="text"
                            readOnly={readonly}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register("donationAmount", {
                              required: true,
                              pattern: {
                                value: /^\d+$/,
                                message: "Amount must be number.",
                              },
                            })}
                            placeholder="Enter Amount"
                          />
                          {errors.amount?.type === "required" && (
                            <span className="error text-danger">
                              This is required
                            </span>
                          )}
                          {errors.amount && (
                            <span className="error text-danger">
                              {errors.amount.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-part">
                          <label htmlFor="">Mobile Number</label>
                          <input
                            type="tel"
                            placeholder="Phone Number"
                            //  onKeyUp={getIcsData}
                            maxLength={10}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            readOnly={donor ? true : false}
                            {...register("phone", {
                              required: true,
                              pattern: {
                                value: /^[6-9]\d*$/,
                                message: "Please provide valid phone number",
                              },
                              minLength: {
                                value: 10,
                                message:
                                  "Phone number must be minimum 10 digit",
                              },
                            })}
                          />
                          {errors.phone?.type === "required" && (
                            <span className="text-danger error">
                              This is required
                            </span>
                          )}
                          {errors.phone && (
                            <span className="error text-danger">
                              {errors.phone.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-part">
                          <label htmlFor="">Email</label>
                          <input
                            type="text"
                            {...register("email", {
                              required: true,
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Please enter a valid email",
                              },
                            })}
                            placeholder="Email"
                          />
                          {errors.email?.type === "required" && (
                            <span className="error text-danger">
                              This is required
                            </span>
                          )}
                          {errors.email && (
                            <span className="error text-danger">
                              {errors.email.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {!ics_data || ics_data == '' ?
                        <div className="col-md-6">
                          <div className="form-part">
                            <label htmlFor="">Name</label>
                            <input
                              maxLength={50}
                              type="text"
                              // onKeyUp={fetchNameFromIcs}
                              {...register("name", {
                                required: true,
                                maxLength: {
                                  value: 50,
                                  message:
                                    "Name can't exceeds more than 50 characters",
                                },
                                minLength: {
                                  value: 3,
                                  message:
                                    "Name must be greater than 3 characters",
                                },
                                pattern: {
                                  value: /^[A-Za-z ]*$/,
                                  message: "Special Characters not allowed !",
                                },
                              })}
                              onKeyPress={(event) => {
                                if (/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="Name"
                            />
                            {errors.name?.type === "required" && (
                              <small className="text-danger error">
                                This is required
                              </small>
                            )}
                            {errors.name?.message && (
                              <small className="text-danger error">
                                {errors.name?.message}
                              </small>
                            )}
                            {token ?
                              <button
                                className="donation-link"
                                onClick={handelOptions}
                                type="button"
                              >
                                Select Options
                              </button>
                              : ''}
                          </div>
                        </div>
                        :

                        <div className="col-md-6">
                          <div className="form-part">
                            <label htmlFor="">Select Name</label>
                            {/* <select
                              {...register("name", {
                                required: true,
                                onChange: (e) => {
                                  handleSelectProfiles(e);
                                },
                              })}
                            >
                              {user ?
                                <option value={user?.name} selected>
                                  {user?.name}
                                </option>
                                : ''}
                              {ics_data.map((data, i) => (
                                <option key={i} value={data.id}>
                                  {data.name}
                                </option>
                              ))}

                            </select> */}

                            <select
                              {...register("name", {
                                required: true,
                                onChange: (e) => {
                                  handleSelectProfiles(e);
                                },
                              })}
                            >
                              {
                                donor && (
                                  <option value={donor.legal_name}>
                                    {donor.legal_name}
                                  </option>
                                )
                              }
                              {
                                profiles && profiles?.map((profile, i) => (
                                  <option key={i} value={profile.legal_name}>
                                    {profile.legal_name}
                                  </option>
                                ))
                              }
                            </select>


                            {errors.donationType?.type === "required" && (
                              <small className="text-danger error">
                                This is required
                              </small>
                            )}
                            <button
                              className="donation-link"
                              onClick={handelOther}
                              type="button"
                            >
                              To enter new name click here
                            </button>
                          </div>

                        </div>
                      }

                      <div className="col-md-6">
                        <div className="form-part">
                          <label htmlFor="">PAN (Optional)</label>
                          <Tooltip title="For 80G reciept" placement="bottom-start">
                            <input
                              type="text"
                              name=""
                              id=""
                              // onKeyUp={fetchPanFromIcs}
                              maxLength={10}
                              style={{ textTransform: "uppercase" }}
                              placeholder="PAN"
                              {...register("pan", {
                                pattern: {
                                  value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                                  message: "Please enter valid PAN",
                                },
                              })}
                            />
                          </Tooltip>
                          {errors.pan && (
                            <small className="text-danger error">
                              {errors.pan.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-part">
                          <label htmlFor="">Address with landmark (Optional)</label>
                          <Tooltip title="For Prasadam please provide this" placement="bottom-start">
                            <textarea
                              type="text"
                              {...register("address", {
                                //required: pinWatch?.trim()?.length > 0,
                                minLength: {
                                  value: 10,
                                  message: "address must be minimum 10 characters",
                                },
                              })
                              }
                              placeholder="Address with PIN Code"
                            ></textarea>
                          </Tooltip>
                          {errors.address?.type === "required" && (
                            <small className="text-danger error">
                              This is required
                            </small>
                          )}
                          {errors.address && (
                            <small className="error">
                              {errors.address.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-part">
                          <label htmlFor="">PIN Code (Optional)</label>
                          <input
                            type="text"
                            minLength={6}
                            maxLength={6}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register("pin", {
                              // required: addressWatch?.trim()?.length > 0,
                              pattern: {
                                value: /^\d+$/,
                                message: "PIN code must be number.",
                              },
                              onChange: (e) => {
                                setValue("state", "");
                                setValue("city", "");
                                if (e.target.value.length === 6) {
                                  fetchStateCity(e.target.value);
                                }
                              },
                            })}
                            placeholder="PIN Code"
                          />
                          {errors.pin?.type === "required" && (
                            <small className="text-danger error">
                              This is required
                            </small>
                          )}
                          {errors.pin && (
                            <small className="text-danger error">
                              {errors.pin.message}
                            </small>
                          )}
                        </div>
                      </div>
                      {isGoseva && (
                        <div className="col-xl-6">
                          <div className="donationAddOns mb-3">
                            <div className="row">
                              <div className="col-xl-5 col-sm-6">
                                <p>
                                  Perform Goseva by feeding the cows.{" "}
                                  <span className="sub">(Optional)</span>
                                </p>
                              </div>
                              <div className="col-xl-7 col-sm-6 ps-sm-0 mt-sm-0 mt-2">
                                <div className="donationAddOns-board">
                                  <div className="amount">
                                    <img
                                      src="/images/donation/cows-min.png"
                                      alt=""
                                    />
                                    <span>₹120.00</span>
                                  </div>
                                  <div className="count-wrap">
                                    <div className="count">
                                      <button
                                        type="button"
                                        className={
                                          goseva <= 0 ? "disabled" : ""
                                        }
                                        onClick={() => {
                                          setGoseva(goseva - 1)
                                          setValue('goseva', goseva - 1)
                                        }}
                                      >
                                        <span>-</span>
                                      </button>
                                      <span className="showCount">
                                        {goseva}
                                      </span>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setGoseva(goseva + 1)
                                          setValue('goseva', goseva + 1)
                                        }}
                                      >
                                        <span>+</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <span className="sub">
                                  Feed a Cow @ Rs.120 for a day. Select no. of
                                  Cows to feed
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {isSadhuBhojanSeva && (
                        <div className="col-xl-6">
                          <div className="donationAddOns mb-3">
                            <div className="row">
                              <div className="col-xl-5 col-sm-6">
                                <p>
                                  Perform Sadhu Bhojan Seva.{" "}
                                  <span className="sub">(Optional)</span>
                                </p>
                              </div>
                              <div className="col-xl-7 col-sm-6 ps-sm-0 mt-sm-0 mt-2">
                                <div className="donationAddOns-board">
                                  <div className="amount">
                                    <img
                                      src="/images/donation/sadhu-min.png"
                                      alt=""
                                    />
                                    <input
                                      {...register("sadhuBhojanSeva")}
                                      type="text"
                                      placeholder="Enter Amount"
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <span className="sub">
                                  Sponsor Bhojan Seva for Brahmanas. Contribute
                                  any amount.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* {isBhagvadGita && (
                        <div className="col-xl-6">
                          <div className="donationAddOns mb-3">
                            <div className="row">
                              <div className="col-xl-5 col-sm-6">
                                <p>
                                  Sponsor Printing of Bhagavad Gita for free
                                  distribution{" "}
                                  <span className="sub"> (Optional)</span>
                                </p>
                              </div>
                              <div className="col-xl-7 col-sm-6 ps-sm-0 mt-sm-0 mt-2">
                                <div className="donationAddOns-board">
                                  <div className="amount">
                                    <img
                                      src="/images/donation/bhagavad-gita-min.png"
                                      alt=""
                                    />
                                    <input
                                      {...register("bhagavadGita")}
                                      type="text"
                                      placeholder="Enter Amount"
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <span className="sub">
                                  Sponsor Printing of Bhagavad Gita for free
                                  distribution in Villages, Schools, Orphanages,
                                  Jails, etc. (₹ 160 per book)
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}
                      {isBhagvadGita && (
                        <div className="col-xl-6">
                          <div className="donationAddOns mb-3">
                            <div className="row">
                              <div className="col-xl-5 col-sm-6">
                                <p>
                                  Sponsor Printing of Bhagavad Gita for free
                                  distribution{" "}
                                  <span className="sub"> (Optional)</span>
                                </p>
                              </div>
                              <div className="col-xl-7 col-sm-6 ps-sm-0 mt-sm-0 mt-2">
                                <div className="donationAddOns-board">
                                  <div className="amount">
                                    <img
                                      src="/images/donation/bhagavad-gita-min.png"
                                      alt=""
                                    />
                                    <span>₹200.00</span>
                                  </div>
                                  <div className="count-wrap">
                                    <div className="count">
                                      <button
                                        type="button"
                                        className={
                                          bhagavadGitaPrice <= 0 ? "disabled" : ""
                                        }
                                        onClick={() => {
                                          setBhagavadGitaPrice(bhagavadGitaPrice - 1)
                                          setValue('bhagavadGita', bhagavadGitaPrice - 1)
                                        }}
                                      >
                                        <span>-</span>
                                      </button>
                                      <span className="showCount">
                                        {bhagavadGitaPrice}
                                      </span>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setBhagavadGitaPrice(bhagavadGitaPrice + 1)
                                          setValue('bhagavadGita', bhagavadGitaPrice + 1)
                                        }}
                                      >
                                        <span>+</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <span className="sub">
                                  Sponsor Printing of Bhagavad Gita for free
                                  distribution in Villages, Schools, Orphanages,
                                  Jails, etc. (₹ 200 per book)
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* New Code For Perform a Puja Start */}
                      <div className="col-12">
                        <div className="type_checkbox mt-3">
                          <input
                            type="checkbox"
                            id="memoryOfSomeone"
                            {...register("memoryOfSomeone")}
                          />
                          <label htmlFor="memoryOfSomeone">
                            This Donation in the memory/honor of someone or performed on
                            a specific occasion
                          </label>
                        </div>
                        {showMemoryBox && (
                          <div className="row">

                            <div className="col-lg-2 col-md-6">
                              <div className="form-part">
                                <label htmlFor="mrandms">Title</label>
                                <select {...register("mrandms")}>
                                  <option value="">
                                    Select Title
                                  </option>
                                  <option value="mrtitle">Mr</option>
                                  <option value="mstitle">
                                    Ms
                                  </option>
                                  <option value="latere">
                                    Late
                                  </option>

                                </select>

                                {errors.occasion?.message && (
                                  <small className="text-danger error">
                                    {errors.occasion?.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-part">
                                <label htmlFor="inNameOf">Name </label>
                                <input
                                  type="text"
                                  placeholder="In Name Of"
                                  {...register("inNameOf", { required: true })}
                                />
                                {errors.inNameOf?.type === "required" && (
                                  <small className="text-danger error">
                                    This is required
                                  </small>
                                )}
                                {errors.inNameOf?.message && (
                                  <small className="text-danger error">
                                    {errors.inNameOf?.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-part">
                                <label htmlFor="relationwithDonor">
                                  Relation
                                </label>
                                <input
                                  type="text"
                                  placeholder="Relation"
                                  {...register("relationwithDonor")}
                                />

                                {errors.relationwithDonor?.message && (
                                  <small className="text-danger error">
                                    {errors.relationwithDonor?.message}
                                  </small>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                              <div className="form-part">
                                <label htmlFor="">Relative Mobile Number</label>
                                <input
                                  type="tel"
                                  placeholder="Phone Number"
                                  // onKeyUp={getIcsData}
                                  maxLength={10}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  // disabled=
                                  {...register("relativephone", {

                                    pattern: {
                                      value: /^[6-9]\d*$/,
                                      message: "Please provide valid phone number",
                                    },
                                    minLength: {
                                      value: 10,
                                      message: "Phone number must be minimum 10 digit",
                                    },
                                  })}
                                />

                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-part">
                                <label htmlFor="occasion">Occasion</label>
                                <select {...register("occasion", { required: true })}>
                                  <option value="">
                                    Select Occasion
                                  </option>
                                  <option value="Birthday">Birthday</option>
                                  <option value="MarriageAnniversary">
                                    Marriage Anniversary
                                  </option>
                                  <option value="DeathAnniversary">
                                    Death Anniversary
                                  </option>
                                  <option value="OtherOccasion">
                                    Any Other Occasion
                                  </option>
                                </select>
                                {errors.occasion?.type === "required" && (
                                  <small className="text-danger error">
                                    This is required
                                  </small>
                                )}
                                {errors.occasion?.message && (
                                  <small className="text-danger error">
                                    {errors.occasion?.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="form-part">
                                <label htmlFor="inDate">Occasion Date</label>
                                <div className="input-date">
                                  <input
                                    type="date"

                                    placeholder="Date"
                                    {...register("inDate", { required: true })}
                                  // min={getCurrentDate()}

                                  ></input>
                                  {errors.inDate?.type === "required" && (
                                    <small className="text-danger error">
                                      This is required
                                    </small>
                                  )}
                                  {errors.inNameOf?.message && (
                                    <small className="text-danger error">
                                      {errors.inNameOf?.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* <div className="form-part mt-2 memory_field">
                            <textarea
                              type="text"
                              {...register("memoryText")}
                            ></textarea>
                          </div> */}
                            <label htmlFor="">Message/Prayer</label>
                            <textarea
                              type="text"
                              maxLength={250}
                              {...register("memoryText")}
                            ></textarea>
                            <small>* Minimum 250 characters allowed</small>
                          </div>
                        )}
                      </div>
                      {/* New Code For Perform a Puja End */}
                      <div className="col-12 mt-4">
                        <button
                          type="submit"
                          className="box-hover custom-btn-cls donation_btn ms-0 mt-md-0 mt-3"
                          disabled={loading ? true : false}
                        >
                          {loading ? (
                            <div className="d-flex align-items-center">
                              <div
                                className="spinner-border text-light"
                                role="status"
                              ></div>
                              <div className="ms-2">DONATING...</div>
                            </div>
                          ) : (
                            <>
                              DONATE NOW {totalAmount > 0 && `${totalAmount.toLocaleString('en-IN', { maximumFractionDigits: 0, style: 'currency', currency: 'INR' })}/-`}
                            </>
                          )}
                        </button>
                        <div className="form-part mt-4 me-lg-2 d-md-none ">
                          <div className="notes-wrap mt-0">
                            <p className="text-center">
                              <span> Please Note:</span> Complete Address with
                              PIN-Code and PAN is mandatory for an 80G Receipt.
                            </p>
                          </div>
                        </div>
                        {showOtp && (
                          <>
                            <div className="form-part d-inline-block my-2">
                              <input
                                type="text"
                                placeholder="Enter OTP"
                                maxLength={6}
                                minLength={6}
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              {!showresendlink && showTimer && (
                                <span
                                  style={{
                                    color: "#9F1D21",
                                    display: "inline-block",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {getTime(otpExp)}s
                                </span>
                              )}
                            </div>
                            <>
                              {showresendlink && (
                                <button
                                  className="custom-btn-cls box-hover"
                                  onClick={resendOtp}
                                >
                                  RESEND
                                </button>
                              )}
                            </>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
          {campaignEightyG && (
            <div className="donate-note my-5">
              <p>
                Note:  AVAIL 80G BENEFITS ON THE DONATIONS MADE TO ISKCON
                BHIWANDI (Preaching center - ISKCON JUHU) AS PER THE INCOME TAX ACT 1961 AND RULES MADE THERE UNDER.
              </p>
              <p>
                "Tax Exemption order ref no. AAATI0017PF20219 dated
                24/09/2021 valid upto 31/03/2026"
              </p>
            </div>
          )}

          {anyAmount && (
            <div className="donate-note my-5">
              <p>
                Note:  AVAIL 80G BENEFITS ON THE DONATIONS MADE TO ISKCON
                BHIWANDI (Preaching center - ISKCON JUHU) AS PER THE INCOME TAX ACT 1961 AND RULES MADE THERE UNDER.
              </p>
              <p>
                "Tax Exemption order ref no. AAATI0017PF20219 dated
                24/09/2021 valid upto 31/03/2026"
              </p>
            </div>
          )}

        </div>

      </section>
      <GoToTop top={top} />
    </>
  );
};

export default CampaignDonationForm;
