
import PageHeader from "../components/common/PageHeader"
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { AiOutlineCalendar } from "react-icons/ai"
import { MdClose } from "react-icons/md"
import { useCallback, useState, useRef } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import notification from "../helpers/notification";
import ApiService from '../api/ApiService'
import ReCAPTCHA from 'react-google-recaptcha'
import { useContext } from "react";
import AppContext from '../context/AppContext'
import { useEffect } from "react";
import moment from 'moment'
import { useNavigate } from "react-router-dom";
import axios from "axios";

const JoinFamily = () => {

    const { user } = useContext(AppContext)
    const navigate = useNavigate()

    const [top, setTop] = useState(0)
    const { register, formState: { errors }, handleSubmit, reset, control } = useForm();
    const { fields, append, remove } = useFieldArray({ control, name: "relatives" });
    const recaptchaRef = useRef()
    const [joinFamily, setJoinFamily] = useState(null)
    const [SpouseNameIcs, setSpouseNameIcs] = useState([])
    const [fetchIcsid, setFetchIcsid] = useState([])
    const [ics_data, setICSdata] = useState([])


    // if(!user){
    //     navigate("/login");
    // }

 
    const RelativeCreate = async (relative) => {
        //console.log('kkkkkk')
        const user_ics_id = localStorage.getItem('ics_id');

        const responseee = await ApiService.getIcsData(relative.relativePhone)


        //console.log(responseee)


        let rel_exists = false;

        if (responseee) {
            if (responseee && responseee.data && responseee.data.length > 0) {
                const chk = responseee.data.filter(dat => dat.name === relative.relativeName).length;
                //console.log(chk)
                if (chk > 0) {
                    rel_exists = true;
                }
            }

           // console.log(rel_exists)

            if (rel_exists == true) {

                const depend_Ics_Id = responseee.data[0].id

                let joinFamilyrelationship = {

                    individual1ICSId: depend_Ics_Id,
                    individual2ICSId: user_ics_id,
                    relationName: relative.relativeRelation,
                    relationCategory: 'FAMILY', //or 'ACQUAINTANCE',
                    oper: 'add'

                }

                const responseeee = await ApiService.joinFamilyrelationshipIcs(joinFamilyrelationship)

                //console.log(responseeee.icsid);
                // if (responseeee) {
                //     //notification('success', 'Relative '+responseeee.data.message)
                // } else {
                //     // notification('error', 'Relative '+responseeee.data.message)
                // }



            } else {

                try {



                    let createIndividual = {
                        lname: relative.relativeName,
                        iname: '',
                        gender: '',
                        mobileNo: relative.relativePhone,
                        email: '',
                        panNo: '',
                        dob: moment(relative.relativeImpDate).format('DD-MM-YYYY'),
                        ma: '',
                       // address: relative.relativeAddress ? relative.relativeAddress : user[0].address.line1,
                        address: user[0].address.line1,
                        address2: '',
                        address3: '',
                       // city: relative.relativePin ? '' : user[0].address.pincode,
                        city: user[0].address.city,
                        //state: relative.relativePin ? '' : user[0].address.pincode,
                        state: user[0].address.state,
                        country: 'India',
                        //pincode: relative.relativePin ? relative.relativePin : user[0].address.pincode,
                        pincode: user[0].address.pincode,
                        category: 'Relative',
                        type: relative.relativeImpType,
                        strictdq: 'no',
                    }

                    const response = await ApiService.createIndividualQuick(createIndividual)
                   // console.log(response.icsid)

                    if (response.icsid) {

                        try {


                            let joinFamilyrelationship = {

                                individual1ICSId: response.icsid,
                                individual2ICSId: user_ics_id,
                                relationName: relative.relativeRelation,
                                relationCategory: 'FAMILY', //or 'ACQUAINTANCE',
                                oper: 'add'

                            }

                            const responsee = await ApiService.joinFamilyrelationshipIcs(joinFamilyrelationship)

                            if (responsee) {
                                // notification('success', 'Relative '+responsee.data.message)
                            } else {
                                //notification('error', 'Relative '+responsee.data.message)
                            }

                            //console.log(responsee)

                        } catch (error) {
                            console.error(error);
                        }
                    }






                } catch (error) {
                    console.error(error);
                }
            }
        }
    }


    const SpouseCreate = async (data) => {





        const user_ics_id = localStorage.getItem('ics_id');

        const responseee = await ApiService.getIcsData(data.Spousenumber)

        let rel_exists = false;

        if (responseee) {
            if (responseee && responseee.data && responseee.data.length > 0) {
                const chk = responseee.data.filter(dat => dat.name === data.spouseName).length;
                //console.log(chk)
                if (chk > 0) {
                    rel_exists = true;
                }
            }

            if (rel_exists == true) {
                const depend_Ics_Id = responseee.data[0].id

                let joinFamilyrelationship = {

                    individual1ICSId: depend_Ics_Id,
                    individual2ICSId: user_ics_id,
                    relationName: 'Wife',
                    relationCategory: 'FAMILY', //or 'ACQUAINTANCE',
                    oper: 'add'

                }

                const responseeee = await ApiService.joinFamilyrelationshipIcs(joinFamilyrelationship)

                if (responseeee) {
                    // notification('success', 'Spouse '+responseeee.data.message)
                } else {
                    // notification('error', 'Spouse '+responseeee.data.message)
                }


            } else {

                try {

                    let createIndividual = {
                        lname: data.spouseName,
                        iname: '',
                        gender: '',
                        mobileNo: data.Spousenumber,
                        email: '',
                        panNo: '',
                        dateFormat: 'dd-MM-yyyy',
                        dob: moment(data.spouseDob).format('DD-MM-YYYY'),
                        ma: moment(data.marriageAnniversary).format('DD-MM-YYYY'),
                        address: user[0].address.line1 + '' + user[0].address.line2 + '' + user[0].address.line3,
                        address2: '',
                        address3: '',
                        city: user[0].address.city,
                        state: user[0].address.state,
                        country: user[0].address.country,
                        pincode: user[0].address.pincode,
                        category: 'Spouse',
                        type: 'Join Family',
                        strictdq: 'no',
                    }

                    const response = await ApiService.createIndividualQuick(createIndividual)




                    if (response.icsid) {



                        try {



                            let joinFamilyrelationship = {

                                individual1ICSId: response.icsid,
                                individual2ICSId: user_ics_id,
                                relationName: 'Wife',
                                relationCategory: 'FAMILY', //or 'ACQUAINTANCE',
                                oper: 'add'

                            }

                            const responsee = await ApiService.joinFamilyrelationshipIcs(joinFamilyrelationship)
                           // console.log('C'.responsee)

                            if (responsee) {
                                // notification('success', 'Spouse '+responsee.data.message)
                            } else {
                                //notification('error', 'Spouse '+responsee.data.message)
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    }






                } catch (error) {
                    console.error(error);
                }
            }
        }
    }


    const joinFamilyFetchIcs = async () => {


        try {
            const user_ics_id = localStorage.getItem('ics_id');
            //console.log(user_ics_id)

            let fetchjoinfamilyfromics = {

                individual1ICSId: '',
                individual2ICSId: user_ics_id,

            }

            const response = await ApiService.fetchJoinfamilyIcs(fetchjoinfamilyfromics)

            //console.log(response.data)
            let count = 0;
            let fetch = 0;
            let countt = response.records;

            // Check if response.data.rows exists and is an array
            if (Array.isArray(response.rows)) {
                for (let i = 0; i < countt; i++) {
                    // Check if response.data.rows[i] exists and has a 'cell' property
                    if (response.rows[i] && response.rows[i].cell) {
                        // Check if the value of 'cell[1]' is 'Spouse'
                        if (response.rows[i].cell[2] === 'Wife') {

                            let fetchjoinfamilyfromics = {

                                individual1ICSId: response.rows[i].cell[0],
                                individual2ICSId: '233703',

                            }

                            const responseer = await ApiService.fetchJoinfamilyIcs(fetchjoinfamilyfromics)
                            //console.log('C'.responseer)

                            if (responseer && Array.isArray(responseer.rows) && responseer.rows.length > 0) {
                                setSpouseNameIcs(responseer.rows[0].cell[1]);
                                //console.log(responseer.rows[0].cell[1]);
                            }


                        }
                    }
                }
            }

        } catch (error) {
            console.error(error);
        }
    }


//console.log(user)

    const joinFamilySubmit = async (data) => {
        notification('success', 'we are updating your details')
        try {
            const user_ics_id = localStorage.getItem('ics_id');


            let profileUpdateData = {
                icsid: user.icsid,
                name: user[0].name,
                number : user.phone,
                email : user[0].email.emailAddress,
                panNo : user[0].pan,
                dob : moment(data.dob).format('yyyy-MM-DD'),
                ma : moment(data.marriageAnniversary).format('yyyy-MM-DD'),
                addressLine1 : user[0].address.line1,
                addressLine2 : user[0].address.line2,
                addressLine3 : user[0].address.line3,
                city : user[0].address.city,
                state : user[0].address.state,
                country : 'India',
                pincode : user[0].address.pincode,
                followupCategory : '',
                followupDescription : 'followupDescription',
                mode: 'STP',       
        }


          const respon = await ApiService.icsUpdate(profileUpdateData)


            let fetchjoinfamilyfromics = {

                individual1ICSId: '',
                individual2ICSId: user_ics_id,

            }

            const response = await ApiService.fetchJoinfamilyIcs(fetchjoinfamilyfromics)

           // console.log(response.records)

            let count = 0;

            let countt = 0; // Initialize countt with a default value
            if (response.records) {
                countt = response.records;
            }

            //console.log(response.rows)
            if (Array.isArray(response.rows)) {
                for (let i = 0; i < countt; i++) {
                    // Check if response.data.rows[i] exists and has a 'cell' property
                    if (response.rows[i] && response.rows[i].cell) {
                        // Check if the value of 'cell[1]' is 'Spouse'
                        if (response.rows[i].cell[1] === 'Wife') {
                            count++;
                            setFetchIcsid(response.rows[i].cell[0])
                        }
                    }
                }
            }


            let fetchjoinfamilyfromicss = {

                individual1ICSId: fetchIcsid,
                individual2ICSId: user_ics_id,

            }

            const responsee = await ApiService.fetchJoinfamilyIcs(fetchjoinfamilyfromicss)

           // console.log('E'.responsee)

            if (responsee && Array.isArray(responsee.rows) && responsee.rows.length > 0) {
                setSpouseNameIcs(responsee.rows[0].cell[1]);
            }


            
         if(data.spouseName){
            SpouseCreate(data)
         }

           


            // data.relatives.map((relative) => (

            //     RelativeCreate(relative)
            // ))

            if (Array.isArray(data.relatives)) {
                data.relatives.map((relative) => (
                  RelativeCreate(relative)
                ));
              } else {
                console.error("data.relatives is not an array.");
              }
              

            const token = await recaptchaRef.current.executeAsync()
            data.token = token;
            const res = await ApiService.joinFamily(data)


            if (res.success) {
                // navigate('/join-family-thank-you')

            }


            recaptchaRef.current.reset()
            if (user) {
                fetchJoinFamily(user.phone)
            }
        } catch (err) {
            console.log(err);
           // notification('error', err.response.data)
        }
        navigate('/join-family-thank-you')
    }

    const formRef = useCallback(node => {
        setTop(node?.offsetTop)
    })


    const fetchJoinFamily = async (phone) => {
        try {

            const res = await ApiService.fetchJoinFamily(phone);
            // console.log(res)
            setJoinFamily(res)

        } catch (err) {
            console.log(err)
        }
    }

    // alert(SpouseNameIcs)



    const fetchFromIcs = async (id) => {
        const response = await ApiService.getIcsData(id)
       
      
        setICSdata(response.data?.find(dat => dat.id === user?.icsid));
       //console.log(response.data.find(dat => dat.id === '234779')[0].dob);
      
      }

     // console.log(ics_data?.dob); 
     // console.log(ics_data?.marriageAnniversary); 
      
     // console.log(moment(ics_data?.dob).format('DD-MM-YYYY')); 
     // console.log(moment(ics_data?.marriageAnniversary).format('DD-MM-YYYY')); 




    // useEffect(() => {
    //     joinFamilyFetchIcs();
       
    //     if (user) {
    //         fetchFromIcs(user?.phone);
    //         fetchJoinFamily(user?.phone)
    //         let details = {
    //             name: user?.name,
    //             address: "",
    //             pin: "",
    //             email: "",
    //             number: user?.phone, 
    //             dob: ics_data?.dob && moment(ics_data?.dob).format('YYYY-MM-DD'),
    //             marriageAnniversary: ics_data?.marriageAnniversary && moment(ics_data?.marriageAnniversary).format('YYYY-MM-DD'),
    //         }
    //         reset(details)
    //     }
    // }, [user])

    
    useEffect(() => {
        if( user && !joinFamily) {
            fetchJoinFamily(user?.phone)
            let details = {
                name : user?.name,
                address : user?.address,
                pin : user?.pin,
                email : user?.email,
                number : user?.phone,
            }
            reset(details)
        }
    },[user])

    useEffect(() => {
        if(joinFamily) {
            let details = {
                
                dob: joinFamily?.dob && moment(joinFamily?.dob).format('YYYY-MM-DD'),
                marriageAnniversary: joinFamily?.marriageAnniversary && moment(joinFamily?.marriageAnniversary).format('YYYY-MM-DD'),
              
               
            }

            reset(details)
        }
    },[joinFamily, user])

    //const check_spouse = ''
    const check_spouse = user?.relative?.filter(dat => dat.relation === 'Wife')?.length || 0;

    // console.log(user.relative.relation)
    // {
    //     user.relative.filter(dat => dat.relation === 'Wife').map((relative, i) => (
    //         console.log(relative.relation)
    //     ))
    // }
    return (
        <>
            <MetaData title="Join Family - ISKCON of Bhiwandi" />
            <PageHeader title="JOIN THE FAMILY OF LORD KRISHNA" imgSrc="/images/page-header/24.jpg" />
            <section className="join-family-sec" ref={formRef}>
                <div className="container">
                    <div className="row d-flex justify-content-center mb-5">
                        <div className="col-md-10">
                            <div className="heading">
                                <h2 className="head-1">Join the Family of Lord Krishna !</h2>
                                <p className="b-line">Celebrate your memorable days like Birthday, Marriage Anniversary, Child's Birthday and many more occasions with us.
                                    We will offer collective prayers to the Lord on such special occasions for you and your loved ones.

                                    Please fill in the details to serve you better
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="join-family-form">

                        <form onSubmit={handleSubmit(joinFamilySubmit)}>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="form-part">
                                        <label htmlFor="name">Your Name</label>
                                        <input type="text" placeholder="Name" maxLength={50} {...register("name", { maxLength: { value: 50, message: "Name can't exceeds more than 50 characters" } })}></input>
                                        {errors.name?.type === 'required' && <small className="text-danger"> First name is required </small>}
                                        {
                                            errors.name?.message && <small className="text-danger error">{errors.name?.message}</small>
                                        }
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 col-md-6">
                                    <div className="form-part">
                                        <label htmlFor="address">Address</label>
                                        <input type="text" placeholder="Address" {...register("address")}></input>
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-4 col-md-6">
                                    <div className="form-part">
                                        <label htmlFor="pin">PIN </label>
                                        <input type="text"  placeholder="PIN" minLength={6} maxLength={6} {...register("pin")} onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                                
                                            }}
                                            {...register("pin", { pattern: {
                                                    value: /^\d+$/,
                                                    message: 'PIN must be number.'
                                                }, minLength: {
                                                    value: 6,
                                                    message: 'PIN must be minimum 10 digit'
                                                }
                                            })}
                                        >
                                        </input>
                                        {errors.pin && <span className="error">{errors.pin.message}</span>}
                                    </div>
                                </div> */}
                                <div className="col-lg-4 col-md-6">
                                    <div className="form-part">
                                        <label htmlFor="dob">Your Date of Birth</label>
                                        <div className="input-date">
                                            <input type="date" max={moment(Date.now()).format('YYYY-MM-DD')}  placeholder="Date Of Birth" {...register("dob")}>
                                            </input>
                                            <AiOutlineCalendar />
                                            {errors.dob?.type === 'required' && <small className="text-danger"> Date of Birth is required </small>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="form-part">
                                        <label htmlFor="number">Your Contact Number</label>
                                        <input type="tel" minLength={10} maxLength={10} placeholder="Phone Number" disabled={user ? true : false} 
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}  {...register("number", {
                                                required: false, pattern: {
                                                    value: /^[6-9]\d*$/,
                                                    message: "Please provide valid phone number",
                                                }, minLength: {
                                                    value: 10,
                                                    message: 'Phone number must be minimum 10 digit'
                                                }
                                            })}

                                        ></input>
                                        {errors.number?.type === 'required' && <small className="text-danger"> Number is required </small>}
                                        {errors.number && <span className="error">{errors.number.message}</span>}
                                    </div>
                                </div>


                                {check_spouse > 0 ? '' :
                                    <>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-part">
                                                <label htmlFor="spouseName">Spouse Name </label>
                                                <input type="text" placeholder="Spouse Name" {...register("spouseName")}></input>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-part">
                                                <label htmlFor="spouseDob">Spouse Date of Birth</label>
                                                <div className="input-date">
                                                    <input type="date" max={moment(Date.now()).format('DD-MM-YYYY')} placeholder="date" {...register("spouseDob")}>
                                                    </input>
                                                    <AiOutlineCalendar />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-part">
                                                <label htmlFor="Spousenumber">Spouse Number</label>
                                                <input type="text" minLength={10} maxLength={10} placeholder="Phone Number"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}  {...register("Spousenumber", {
                                                        required: false, pattern: {
                                                            value: /^\d+$/,
                                                            message: 'Mobile number must be number.'
                                                        }, minLength: {
                                                            value: 10,
                                                            message: 'Phone number must be minimum 10 digit'
                                                        }
                                                    })}

                                                ></input>
                                                {errors.Spousenumber?.type === 'required' && <small className="text-danger"> Number is required </small>}
                                                {errors.Spousenumber && <span className="error">{errors.Spousenumber.message}</span>}
                                            </div>

                                        </div>
                                    </>
                                }

                                <div className="col-lg-4 col-md-6">
                                    <div className="form-part">
                                        <label htmlFor="marriageAnniversary">Marriage Anniversary</label>
                                        <div className="input-date">
                                            <input type="date" max={moment(Date.now()).format('YYYY-MM-DD')} placeholder="date" {...register('marriageAnniversary')}>
                                            </input>
                                            <AiOutlineCalendar />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    {/* <div className="col-lg-4 col-md-6">
                                    <div className="form-part">
                                        <label htmlFor="nameOfChild">Name of Child</label>
                                        <input type="text" placeholder="Child Name" {...register('nameOfChild')}></input>
                                    </div>
                                </div> */}
                                    {/* <div className="col-lg-4 col-md-6">
                                    <div className="form-part">
                                        <label htmlFor="dobOfChild">Child's Date of Birth</label>
                                        <div className="input-date">
                                            <input type="date" max={moment(Date.now()).format('DD-MM-YYYY')} placeholder="date" {...register('dobOfChild')}>
                                            </input>
                                            <AiOutlineCalendar />
                                        </div>
                                    </div>
                                </div> */}
                                    {/* <div className="col-lg-4 col-md-6">
                                    <div className="form-part">
                                        <label htmlFor="childNumber">Child Number</label>
                                        <input type="text" minLength={10} maxLength={10} placeholder="Phone Number"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}  {...register("childNumber", {
                                                required: false, pattern: {
                                                    value: /^\d+$/,
                                                    message: 'Mobile number must be number.'
                                                }, minLength: {
                                                    value: 10,
                                                    message: 'Phone number must be minimum 10 digit'
                                                }
                                            })}

                                        ></input>
                                        {errors.childNumber?.type === 'required' && <small className="text-danger"> Number is required </small>}
                                        {errors.childNumber && <span className="error">{errors.childNumber.message}</span>}
                                    </div>
                                </div> */}
                                </div>
                                {
                                    fields.map((item, index) => {
                                        return (
                                            <div className="row" key={item.id}>
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="form-part">
                                                        <label htmlFor="relativeName">Name of the Relative</label>
                                                        <input type="text" placeholder="Name of the relative" {...register(`relatives.${index}.relativeName`)}></input>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6">
                                                    <div className="form-part">
                                                        <label htmlFor="relativeRelation">Relation with the relative</label>
                                                        <select className="w-100" placeholder="Relation with the relative" {...register(`relatives.${index}.relativeRelation`)}>
                                                            <option value="" selected>Relation with the relative</option>
                                                            <option value="Father">Father</option>
                                                            <option value="Mother">Mother</option>
                                                            <option value="Son">Son</option>
                                                            <option value="Daughter">Daughter</option>
                                                            <option value="Elder Brother">Elder Brother</option>
                                                            <option value="Younger Brother">Younger Brother</option>
                                                            <option value="Elder Sister">Elder Sister</option>
                                                            <option value="Younger Sister">Younger Sister</option>
                                                            <option value="Grand Father">Grand Father</option>
                                                            <option value="Grand Mother">Grand Mother</option>
                                                            <option value="Eldest Brother">Eldest Brother</option>
                                                            <option value="Youngest Brother">Youngest Brother</option>
                                                            <option value="Eldest Sister">Eldest Sister</option>
                                                            <option value="Youngest Sister">Youngest Sister</option>
                                                            <option value="Uncle (paternal – younger than father)">Uncle (paternal – younger than father)</option>
                                                            <option value="Uncle (paternal)">Uncle (paternal)</option>
                                                            <option value="Aunt (paternal – Wife of Chaachaa)">Aunt (paternal – Wife of Chaachaa)</option>
                                                            <option value="Aunt (Paternal)">Aunt (Paternal)</option>
                                                            <option value="Brother of Mother">Brother of Mother</option>
                                                            <option value="Younger brother of Mother">Younger brother of Mother</option>
                                                            <option value="Older Brother of Mother">Older Brother of Mother</option>
                                                            <option value="Wife of Older Brother of Mother">Wife of Older Brother of Mother</option>
                                                            <option value="Wife of Younger brother of Mother">Wife of Younger brother of Mother</option>
                                                            <option value="Grand Father (Father of Mother)">Grand Father (Father of Mother)</option>
                                                            <option value="Grand Mother (Mother of Mother)">Grand Mother (Mother of Mother)</option>
                                                            <option value="Son in Law (Husband of Daughter)">Son in Law (Husband of Daughter)</option>
                                                            <option value="Brother in Law (Husband of Older Sister)">Brother in Law (Husband of Older Sister)</option>
                                                            <option value="Brother in Law (Husband of Younger sister)">Brother in Law (Husband of Younger sister)</option>
                                                            <option value="Sister in Law (Wife of Older Brother)">Sister in Law (Wife of Older Brother)</option>
                                                            <option value="Sister in Law (Wife of Younger Brother)">Sister in Law (Wife of Younger Brother)</option>
                                                            <option value="Father Of Wife">Father Of Wife</option>
                                                            <option value="Mother of Wife">Mother of Wife</option>
                                                            <option value="Father of Son’s Wife">Father of Son’s Wife</option>
                                                            <option value="Mother of Son’s Wife">Mother of Son’s Wife</option>
                                                            <option value="Father of Daughter’s Husband">Father of Daughter’s Husband</option>
                                                            <option value="Mother of Daughter’s Husband">Mother of Daughter’s Husband</option>
                                                            <option value="Husband of Buwa">Husband of Buwa</option>
                                                            <option value="Daughter of Buwa">Daughter of Buwa</option>
                                                            <option value="Son of Buwa">Son of Buwa</option>
                                                            <option value="Sister’s Son">Sister’s Son</option>
                                                            <option value="Brother’s Son">Brother’s Son</option>
                                                            <option value="Sister’s Daughter">Sister’s Daughter</option>
                                                            <option value="Brother’s Daughter">Brother’s Daughter</option>
                                                            <option value="Step Mother">Step Mother</option>
                                                            <option value="Step Sister">Step Sister</option>
                                                            <option value="Step Brother">Step Brother</option>
                                                            <option value="Husband’s Other">Husband’s Other</option>
                                                            <option value="Step son">Step son</option>
                                                            <option value="Step Daughter">Step Daughter</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-part">
                                                        <label htmlFor="relativeImpDate">Birth/Marriage/Death Anniversary</label>
                                                        {/* <input type="text" placeholder="Your Answer" {...register(`relatives.${index}.relativeImpDate`)}>
                                                        </input> */}
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="input-date">
                                                                    <input type="date" max={moment(Date.now()).format('DD-MM-YYYY')} placeholder="Select Date" {...register(`relatives.${index}.relativeImpDate`)}>
                                                                    </input>
                                                                    <AiOutlineCalendar />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mt-3 mt-md-0">
                                                                <select className="w-100" {...register(`relatives.${index}.relativeImpType`)}>
                                                                    <option value="birthday">Birthday</option>
                                                                    <option value="marriage-anniversary">Marriage Anniversary</option>
                                                                    <option value="death-anniversary">Death Anniversary</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6">
                                                    <div className="form-part">
                                                        <label htmlFor="relativeAddress">Address</label>
                                                        <input type="text" placeholder="Address" maxLength={250} {...register(`relatives.${index}.relativeAddress`)}></input>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6">
                                                    <div className="form-part">
                                                        <label htmlFor="relativePin">PIN</label>
                                                        <input type="text" placeholder="PIN" minLength={6} maxLength={6} {...register(`relatives.${index}.relativePin`)} onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }

                                                        }}
                                                            {...register(`relatives.${index}.relativePin`, {
                                                                pattern: {
                                                                    value: /^\d+$/,
                                                                    message: 'PIN must be number.'
                                                                }, minLength: {
                                                                    value: 6,
                                                                    message: 'PIN must be minimum 10 digit'
                                                                }
                                                            })}></input>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6">
                                                    <div className="form-part">
                                                        <label htmlFor="relativePhone">Relative Phone Number</label>
                                                        <input type="text" maxLength={10} minLength={10} placeholder="Relative Phone Number" {...register(`relatives.${index}.relativePhone`)}

                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}  {...register(`relatives.${index}.relativePhone`, {
                                                                required: false, pattern: {
                                                                    value: /^\d+$/,
                                                                    message: 'Mobile number must be number.'
                                                                }, minLength: {
                                                                    value: 10,
                                                                    message: 'Phone number must be minimum 10 digit'
                                                                }
                                                            })}



                                                        ></input>
                                                        {errors.relativePhone?.type === 'required' && <small className="text-danger"> Number is required </small>}
                                                        {errors.relativePhone && <span className="error">{errors.relativePhone.message}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 mb-4 mb-lg-0">
                                                    <button type="button" onClick={() => remove(index)} className="form-close-btn"><MdClose /></button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div className="col-lg-12 col-md-6">
                                    <div className="form-part">
                                        <label htmlFor="name">Would you like to add a relative?</label>
                                        <div className="d-flex align-items-centre">
                                            <button type="button" className="custom-btn-cls add-relative-btn"
                                                onClick={() => {
                                                    append({ relativeName: "", relativeRelation: "", relativeImpDate: "", relativeAddress: "", relativePhone: "", relativePin: "" });
                                                }}>
                                                Add Relative
                                            </button>
                                            {/* {
                                                fields?.length > 1 &&
                                                <button type="button" className="ms-3 custom-btn-cls add-relative-btn d-none d-sm-block"  
                                                    onClick={() => remove(fields.length - 1)}>
                                                    Remove Relative
                                                </button>
                                            } */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                        ref={recaptchaRef}
                                        size="invisible"
                                    />
                                </div>
                                <div className="col-lg-12 d-flex justify-content-center">
                                    <button className="custom-btn-cls ms-0">
                                        Submit
                                    </button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <GoToTop top={top} />
        </>
    );
}

export default JoinFamily;